import axios from "axios";
import { getSessionId } from "../../../../../utils/tokenHandlers";

export const getBusinessDetailsFromCIN = (id: string) => {
  const sessionId = getSessionId();
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}business/fetch/stitch-profile`,
      {
        params: {
          id: id,
        },
      },
      {
        params: {
          o_ses_id: sessionId,
        },
      }
    )
    .then((resp) => resp)
    .catch((err) => err);
};
