import React, { useEffect, useState } from "react";
//import NavbarVTX from "../../../../../components/common/NavbarComponent";
import { Row, Col, Form, Spinner } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";
import { MdOutlineModeEditOutline } from "react-icons/md";
import Input from "../../../../components/common/Input";
import Dropdown from "../../../../components/common/dropdowns/Dropdown";
import IconInput from "../../../../components/common/IconInput";
import RadioButton from "../../../../components/common/RadioButton";
import CountryDropdown from "../../../../components/common/CountryDropdown";
import Button from "../../../../components/common/Button";
import { GuestCreateAppType } from "../types/GuestCreateAppType";
import SectionHeading from "../../../../components/common/SectionHeading";
import HowItWorks from "../../../auth/components/HowItWorks";
import NavbarVTX from "../../../../components/common/Navbar";
import { useLocation, useNavigate } from "react-router-dom";
import ConstitutionType from "../../../../components/common/dropdowns/ConstitutionType";
import TaxIssuedBy from "../../../../components/common/dropdowns/TaxIssuedBy";
import { useDispatch, useSelector } from "react-redux";
import { createGuestApplication } from "../../ppApplication/redux/action/guestCreateAppAction";
import { AppDispatch, RootState } from "../../../../redux/store";
import { getCinFromPanAction } from "../../../opsUser/ppApplication/ppIndia/redux/action/getCinFromPanAction";
import { updateAppAction } from "../../../opsUser/ppApplication/ppIndia/redux/action/updateAppAction";
import { getAddressFromCinAction } from "../../../opsUser/ppApplication/ppIndia/redux/action/getAddressFromCinAction";
import { parseAddress } from "../../../../utils/functions/parseAddress";
import { createAddressAction } from "../../../opsUser/ppApplication/ppIndia/redux/action/createAddressAction";
import { convertDateFormat } from "../../../../utils/functions/convertDateFormat";
import { getBusinessNameFromPan } from "../../../apis/thirdParty/getBusinessNameFromPan";
import { getEnrichApiToken } from "../../../apis/thirdParty/getEnrichApiToken";
import { getGstDetails } from "../../../apis/thirdParty/getGstDetails";
import BusinessName from "../../../../components/common/dropdowns/BusinessName";
import { DisplayBox } from "../../../../components/containers/DisplayBox";
import AlertPopup from "../../../../components/common/Alert/AlertPopup";
import { getBizNameGSTINMaster } from "../../../apis/thirdParty/getBizNameGSTINMaster";
import { parseGstAddress } from "../../../../utils/functions/parseGstAddress";
import { createGstAddressAction } from "../../../opsUser/ppApplication/ppIndia/redux/action/createGstAddressAction";
import PhoneInputWithDropdown from "../../../../components/common/PhoneInput";
import { ALPHABETS_REGEX, GSTIN_REGEX } from "../../../constants/regexPatterns";
import ParticipantType from "../../../../components/common/ParticipantType";
import { getSessionId } from "../../../../utils/tokenHandlers";
import { getBusinessDetailsFromCINAction } from "../../../opsUser/ppApplication/ppIndia/redux/action/getBusinessDetailsFromCINAction";

const GuestCreateAppPage: React.FC = () => {
  const location = useLocation();
  const formData = location.state;
  const dispatch = useDispatch<AppDispatch>();
  const [businessNames, setBusinessNames] = useState<string[]>([]);
  const [panAPIError, setPanAPIError] = useState(false);
  const [constTypeAPI, setConstTypeAPI] = useState("");

  const state = useSelector(
    (state: RootState) => state.guestPPApp.guestCreateApp
  );

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm<GuestCreateAppType>({
    shouldUnregister: true,
  });

  const country = watch("business_registration_country_id");
  // const gstinValue = watch("business_gst");
  const [spinner, setSpinner] = useState<boolean>(false);
  const [gstinValue, setGSTINValue] = useState("");
  const [disableCTBDropdown, setDisableCTBDropdown] = useState(false);
  const [showError, setShowError] = useState<string>("");
  const isCountryIndia =
    Number(watch("business_registration_country_id")) === 448;
  const isCountryNotIndia =
    Number(watch("business_registration_country_id")) !== 448;

  const constitutionType = watch("business_constitution_type");
  const particiapantRole = watch("business_participant_type");
  const taxIssuedBy = watch("business_tax_type");

  const participationRoleOptions = [
    { label: "Seller", value: "Seller" },
    { label: "Buyer", value: "Buyer" },
    { label: "Financier", value: "Financier" },
  ];

  useEffect(() => {
    if (constTypeAPI) {
      //  // console.log(constTypeAPI);
      if (constTypeAPI === "Public Limited Company") {
        setValue("business_constitution_type", "PLC");
        setDisableCTBDropdown(true);
      } else if (constTypeAPI === "Partnership") {
        setValue("business_constitution_type", "partnership");
        setDisableCTBDropdown(true);
      } else if (constTypeAPI === "LLC") {
        setValue("business_constitution_type", "LLC");
        setDisableCTBDropdown(true);
      } else if (constTypeAPI === "LLP") {
        setValue("business_constitution_type", "LLP");
        setDisableCTBDropdown(true);
      } else if (constTypeAPI === "Proprietorship") {
        setValue("business_constitution_type", "proprietorship");
        setDisableCTBDropdown(true);
      } else {
        setValue("business_constitution_type", "");
        setDisableCTBDropdown(false);
      }
    }
  }, [constTypeAPI, setValue]);

  useEffect(() => {
    if (formData) {
      setValue("customer_contact_name", formData.guest_user_name);
      setValue("customer_contact_mobile", formData.guest_user_mobile);
      setValue(
        "customer_contact_mobile_country_code",
        formData.customer_contact_mobile_country_code
      );

      setValue("customer_contact_email", formData.guest_email);
      setTimeout(() => {
        setValue(
          "customer_contact_mobile_country_code",
          formData.customer_contact_mobile_country_code
        );
      }, 500);
    }
  }, [formData, setValue]);

  useEffect(() => {
    if (gstinValue && gstinValue.length == 15) {
      setBusinessNames([]);
      setPanAPIError(false);
      getBusinessNameFromPan(gstinValue.substring(2, 12)).then(
        (response: any) => {
          if (response.data.error) {
            // const panAPIErr = response.data.error.data.message;
            setPanAPIError(true);
          } else {
            setPanAPIError(false);
            //   // console.log(response.data, "---gst data");
            setBusinessNames((prevNames) => [
              ...prevNames,
              response.data.result.result.name,
            ]);
          }
        }
      );

      // getEnrichApiToken().then((response) => {
      //   // console.log(response);
      //   const token = response.data.data.token;
      //   const orgId = response.data.data.associatedOrgs[0].organisation.id;

      //   getGstDetails(gstinValue, orgId, token).then((response) => {
      //     const gstData = JSON.parse(response.data.data);
      //     setBusinessNames((prevNames) => [
      //       ...prevNames,
      //       gstData.lgnm,
      //       gstData.tradeNam,
      //     ]);
      //   });
      // });

      getBizNameGSTINMaster(gstinValue).then((response) => {
        if (response.data.result.result) {
          const gstMasterData = response.data.result.result[0];
          if (gstMasterData) {
            //  // console.log("gst master resp--", gstMasterData);
            setConstTypeAPI(gstMasterData.ctb);

            setBusinessNames((prevNames) => [
              ...prevNames,
              gstMasterData.lgnm,
              gstMasterData.tradeName,
            ]);
            const gstAddress = parseGstAddress(gstMasterData);
            dispatch(createGstAddressAction(gstAddress)).then((response) => {
              // // console.log("gst addr -->", response);
            });
          } else {
            setPanAPIError(true);
          }
        } else {
          alert("Something went wrong, Please contact Admin");
        }
      });
    }
  }, [gstinValue]);

  const countryChange = () => {
    if (country === undefined) {
      return null;
    } else if (Number(country) === 448) {
      return (
        <>
          <Row>
            <Col md={10}>
              <Input
                label="TAN"
                name="business_tanno"
                register={register}
                // required
                placeholder="Enter TAN"
                // requiredMessage="TAN is required"
                // error={errors.business_tanno?.message}
              />
            </Col>
          </Row>
          <Row>
            <Col md={10}>
              <Input
                label="GSTIN"
                name="business_gst"
                register={register}
                required
                placeholder="Enter GSTIN"
                requiredMessage="GSTIN is required"
                error={errors.business_gst?.message}
                regexPattern={GSTIN_REGEX}
                onChange={(e) => setGSTINValue(e.target.value)}
              />
            </Col>
          </Row>
          <Row>
            <Col md={10}>
              {/* <Input
                label="Business Name"
                name="business_name"
                register={register}
                required
                defaultValue={"hello"}
                requiredMessage="Business Name is required"
                error={errors.business_name?.message}
              /> */}
              <BusinessName
                label="Business Name"
                name="business_name"
                register={register}
                options={businessNames}
                required
                requiredMessage="Business Name is required"
                error={errors.business_name?.message}
              />
            </Col>
          </Row>
          <Row>
            <Col md={10}>
              <ConstitutionType
                label="Constitution Type"
                name="business_constitution_type"
                // options={[
                //   { label: "Public Limited Company", value: "PLC" },
                //   { label: "Private Limited Company", value: "LLP" },
                //   { label: "Limited Liability Company", value: "LLC" },
                //   { label: "Partnership", value: "partnership" },
                //   { label: "Proprietorship", value: "proprietorship" },
                //   { label: "Others", value: "others" },
                // ]}
                register={register}
                disabled={disableCTBDropdown}
                required
                requiredMessage="Constitution Type is required"
                error={errors.business_constitution_type?.message}
              />
              {constitutionType === "others" && (
                <Input
                  label={"If Others"}
                  name={"business_constitution_type_other"}
                  register={register}
                  placeholder="Enter other Constitution Type"
                  required
                  requiredMessage="Other Constitution is required"
                />
              )}
            </Col>
          </Row>
        </>
      );
    } else {
      return (
        <>
          <Row>
            <Col md={10}>
              <Input
                label="Business Name"
                name="business_name"
                required
                register={register}
                placeholder="Enter business name"
                error={errors.business_name?.message}
              />
            </Col>
          </Row>
          <Row>
            <Col md={10}>
              <ConstitutionType
                label="Constitution Type"
                name="business_constitution_type"
                // options={[
                //   { label: "Public Limited Company", value: "PLC" },
                //   { label: "Private Limited Company", value: "LLP" },
                //   { label: "Limited Liability Company", value: "LLC" },
                //   { label: "Partnership", value: "partnership" },
                //   { label: "Proprietorship", value: "proprietorship" },
                //   { label: "Others", value: "others" },
                // ]}
                register={register}
                required
                requiredMessage="Constitution Type is required"
                error={errors.business_constitution_type?.message}
              />
              {constitutionType === "others" && (
                <Input
                  label={"If Others"}
                  name={"business_constitution_type_other"}
                  register={register}
                  placeholder="Enter Other Constitution Type"
                  required
                />
              )}
              {/* <ConstitutionType
                label="Constitution Type"
                name="business_constitution_type"
                register={register}
                required
                requiredMessage="Constitution Type is required"
                error={errors.business_constitution_type?.message}
              /> */}
            </Col>
          </Row>
          <Row>
            <Col md={10}>
              <Input
                label="TAX ID (TIN)"
                name="business_tax_identification_number"
                required
                register={register}
                placeholder="Enter tax id"
                requiredMessage="TAX ID is required"
                error={errors.business_tax_identification_number?.message}
              />
            </Col>
          </Row>
          <Row>
            <Col md={10}>
              <TaxIssuedBy
                label="TIN Issued by"
                name="business_tax_type"
                register={register}
                required
                requiredMessage="TAX ID Issued By is required"
                error={errors.business_tax_type?.message}
              />
              {taxIssuedBy === "Others" && (
                <Input
                  label={"If Others"}
                  name={"business_tax_type_other"}
                  register={register}
                  required
                  placeholder="Enter other Tax Type"
                  requiredMessage="This field is required"
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col md={10}>
              <Input
                label="TAX Account No (TAN)"
                name="business_tanno"
                register={register}
                // required
                placeholder="Enter tax account no"
                requiredMessage="Tax Account Number is required"
                // error={errors.business_tanno?.message}
              />
            </Col>
          </Row>
          <Row>
            <Col md={10}>
              <Input
                label="TAN Issued By"
                name="business_tanno_issuing_authority"
                register={register}
                // required
                placeholder="Enter tan issued by"
                requiredMessage="TAN Issued By is required"
                // error={errors.business_tanno_issuing_authority?.message}
              />
            </Col>
          </Row>
        </>
      );
    }
  };
  const phoneIsd = watch("customer_contact_mobile_country_code");
  const phoneNo = watch("customer_contact_mobile");

  const onSubmitPersonalDetails: SubmitHandler<GuestCreateAppType> = async (
    formData
  ) => {
    if (phoneIsd === "+91" && phoneNo.toString().length !== 10) {
      alert("Please enter 10 digit number");
    } else {
      setSpinner(true);
      const data = {
        ...formData,
        employee_id: false,
        is_counterparty: false,
        stage: "New / Draft",
        employee_declaration_accepted: false,
        is_guest_user: true,
        employee_name: false,
        submitted_date: false,
        business_is_ftr_ftnr: false,
        business_registration_country_id: Number(
          formData.business_registration_country_id
        ),
        constitution_type_flag: true,
        // customer_contact_mobile_country_code: `${localStorage.getItem(
        //   "guestISDCode"
        // )}`,
      };

      if (Number(country) === 448) {
        data.business_pan = gstinValue.substring(2, 12);
        data.business_tanno_issuing_authority = "Income Tax Department";
        // data.business_incorporation_country_id = Number(
        //   formData.business_registration_country_id
        // );
      } else if (Number(country) !== 448) {
        data.is_foreign = true;
        data.business_tax_jurisdiction_country_id = Number(
          formData.business_registration_country_id
        );
        data.business_incorporation_country_id = Number(
          formData.business_registration_country_id
        );
      }
      // // console.warn("PAN-->", gstinValue.substring(2, 12));
      //  // console.warn("formdata-->", data);

      dispatch(createGuestApplication(data)).then((response) => {
        let appId = response.payload.data.result;
        // // console.log("appId-->", response);

        if (response.payload.data.error) {
          // // console.warn("resp--", response.payload.data.error.code == 200);
          // // console.warn("err--", response.payload.data.error.data.message);
          setShowError(response.payload.data.error.data.message);
          setSpinner(false);
          return;
        } else {
          if (isCountryIndia) {
            if (particiapantRole == "Financier") {
              //  // console.warn(constitutionType, "const type---");
              dispatch(
                getCinFromPanAction({
                  pan: gstinValue.substring(2, 12),
                  customerId: response.payload.result,
                })
              ).then((response) => {
                if (response.payload.result) {
                  // // console.log(
                  //   "PLACE of REG",
                  //   response.payload.result[0].ROC_Code,
                  //   response.payload.result[0].Date_of_Incorporation
                  // );
                  dispatch(
                    updateAppAction({
                      customerId: appId,
                      updateCustomerData: {
                        is_cin_captured: true,
                        business_cin_no:
                          response.payload.result.result[0].entityId,
                        api_cin_flag: true,
                      },
                    })
                  ).then(() => {
                    const cinValue = response.payload.result.result[0].entityId;
                    dispatch(getBusinessDetailsFromCINAction(cinValue)).then(
                      (businessDetailsFields) => {
                        // console.log(businessDetailsFields.payload);
                        let fetchedFields =
                          businessDetailsFields.payload?.result
                            ?.statutoryRegistration;
                        const IEC_number = fetchedFields?.iec[0]?.iec;
                        const LEI_number = fetchedFields?.lei[0]?.lei;
                        // console.log(
                        //   fetchedFields?.exchangeInformation?.bseSecurity[0]?.srcWebsites[0]
                        // );
                        const companyWebsite =
                          fetchedFields?.exchangeInformation?.bseSecurity[0]
                            ?.srcWebsites[0];
                        const natureofActivity =
                          fetchedFields?.mca[0]?.activityGroup_;
                        dispatch(
                          updateAppAction({
                            customerId: appId,
                            updateCustomerData: {
                              business_iecno: IEC_number ? IEC_number : "",
                              business_legal_entity_identifier: LEI_number
                                ? LEI_number
                                : "",
                              business_website: companyWebsite
                                ? companyWebsite
                                : "",
                              business_nature_activity: natureofActivity
                                ? natureofActivity
                                : "",
                              api_lei_flag: LEI_number ? true : false,
                              api_nature_activity_flag: natureofActivity
                                ? true
                                : false,
                              api_iecno_flag: IEC_number ? true : false,
                              api_website_flag: companyWebsite ? true : false,
                            },
                          })
                        );
                        // console.log(
                        //   businessDetailsFields.payload?.result?.statutoryRegistration,
                        //   "here"
                        // );
                        // console.log(fetchedFields?.iec[0]?.iec, "IEC");
                        // console.log(fetchedFields?.lei[0]?.lei, "LEI");
                        // console.log(fetchedFields?.tan[0]?.tan, "tan");
                        // return;
                      }
                    );
                  });

                  dispatch(
                    getAddressFromCinAction({
                      cin: response.payload.result.result[0].entityId,
                      customerId: appId,
                    })
                  ).then((addressResponse) => {
                    try {
                      if (addressResponse.payload) {
                        // navigate(`/india/financier/business-details/${appId}`);
                        // return;
                        let address =
                          addressResponse.payload.result.result
                            .Registered_Address;
                        let parseAddressDetails = parseAddress(address);
                        let addressDetailsData = {
                          customer_id: appId,
                          type: "Registered",
                          ...parseAddressDetails,
                          api_line_1_flag: true,
                          api_line_2_flag: true,
                          postal_pincode_api_success: true,
                          api_city_flag: true,
                          api_district_flag: true,
                          api_state_flag: true,
                          api_country_flag: true,
                        };
                        dispatch(createAddressAction(addressDetailsData)).then(
                          (res) => {
                            dispatch(
                              updateAppAction({
                                customerId: appId,
                                updateCustomerData: {
                                  business_registration_place:
                                    addressResponse.payload.result.result
                                      .ROC_Code,
                                  business_registration_date: convertDateFormat(
                                    addressResponse.payload.result.result
                                      .Date_of_Incorporation
                                  ),
                                  api_business_registration_date_flag:
                                    addressResponse.payload.result.result
                                      .Date_of_Incorporation
                                      ? true
                                      : false,
                                  api_business_registration_place_flag:
                                    addressResponse.payload.result.result
                                      .ROC_Code
                                      ? true
                                      : false,
                                },
                              })
                            ).then(() => {
                              if (appId) {
                                navigate(
                                  `/india/financier/business-details/${appId}`
                                );
                              } else {
                                alert("Something went wrong!");
                              }
                            });
                          }
                        );
                      }
                    } catch (err) {
                      navigate(`/india/financier/business-details/${appId}`);
                    }
                  });
                } else {
                  if (appId) {
                    navigate(`/india/financier/business-details/${appId}`);
                  } else {
                    alert("Something went wrong!");
                  }
                }
              });
            } else {
              if (
                constitutionType === "LLC" ||
                constitutionType === "LLP" ||
                constitutionType === "PLC"
              ) {
                dispatch(
                  getCinFromPanAction({
                    pan: gstinValue.substring(2, 12),
                    customerId: response.payload.result,
                  })
                ).then((response) => {
                  if (response.payload.result) {
                    // // console.log(
                    //   "PLACE of REG",
                    //   response.payload.result[0].ROC_Code,
                    //   response.payload.result[0].Date_of_Incorporation
                    // );
                    dispatch(
                      updateAppAction({
                        customerId: appId,
                        updateCustomerData: {
                          is_cin_captured: true,
                          business_cin_no:
                            response.payload.result.result[0].entityId,
                          api_cin_flag: true,
                        },
                      })
                    ).then(() => {
                      const cinValue =
                        response.payload.result.result[0].entityId;
                      dispatch(getBusinessDetailsFromCINAction(cinValue)).then(
                        (businessDetailsFields) => {
                          // console.log(businessDetailsFields.payload);
                          let fetchedFields =
                            businessDetailsFields.payload?.result
                              ?.statutoryRegistration;
                          const IEC_number = fetchedFields?.iec[0]?.iec;
                          const LEI_number = fetchedFields?.lei[0]?.lei;
                          // console.log(
                          //   fetchedFields?.exchangeInformation?.bseSecurity[0]?.srcWebsites[0]
                          // );
                          const companyWebsite =
                            fetchedFields?.exchangeInformation?.bseSecurity[0]
                              ?.srcWebsites[0];
                          const natureofActivity =
                            fetchedFields?.mca[0]?.activityGroup_;
                          dispatch(
                            updateAppAction({
                              customerId: appId,
                              updateCustomerData: {
                                business_iecno: IEC_number ? IEC_number : "",
                                business_legal_entity_identifier: LEI_number
                                  ? LEI_number
                                  : "",
                                business_website: companyWebsite
                                  ? companyWebsite
                                  : "",
                                business_nature_activity: natureofActivity
                                  ? natureofActivity
                                  : "",
                                api_lei_flag: LEI_number ? true : false,
                                api_nature_activity_flag: natureofActivity
                                  ? true
                                  : false,
                                api_iecno_flag: IEC_number ? true : false,
                                api_website_flag: companyWebsite ? true : false,
                              },
                            })
                          );
                          // console.log(
                          //   businessDetailsFields.payload?.result?.statutoryRegistration,
                          //   "here"
                          // );
                          // console.log(fetchedFields?.iec[0]?.iec, "IEC");
                          // console.log(fetchedFields?.lei[0]?.lei, "LEI");
                          // console.log(fetchedFields?.tan[0]?.tan, "tan");
                          // return;
                        }
                      );
                    });

                    dispatch(
                      getAddressFromCinAction({
                        cin: response.payload.result.result[0].entityId,
                        customerId: appId,
                      })
                    ).then((addressResponse) => {
                      try {
                        if (addressResponse.payload) {
                          // // console.log((addressResponse.payload, "----resp"));
                          // navigate(`/india/corporate/business-details/${appId}`);
                          let address =
                            addressResponse.payload.result.result
                              .Registered_Address;
                          let parseAddressDetails = parseAddress(address);
                          let addressDetailsData = {
                            customer_id: appId,
                            type: "Registered",
                            ...parseAddressDetails,
                            api_line_1_flag: true,
                            api_line_2_flag: true,
                            postal_pincode_api_success: true,
                            api_city_flag: true,
                            api_district_flag: true,
                            api_state_flag: true,
                            api_country_flag: true,
                          };
                          dispatch(
                            createAddressAction(addressDetailsData)
                          ).then((res) => {
                            dispatch(
                              updateAppAction({
                                customerId: appId,
                                updateCustomerData: {
                                  business_registration_place:
                                    addressResponse.payload.result.result
                                      .ROC_Code,
                                  business_registration_date: convertDateFormat(
                                    addressResponse.payload.result.result
                                      .Date_of_Incorporation
                                  ),
                                  api_business_registration_date_flag:
                                    addressResponse.payload.result.result
                                      .Date_of_Incorporation
                                      ? true
                                      : false,
                                  api_business_registration_place_flag:
                                    addressResponse.payload.result.result
                                      .ROC_Code
                                      ? true
                                      : false,
                                },
                              })
                            ).then(() => {
                              appId &&
                                navigate(
                                  `/india/corporate/business-details/${appId}`
                                );
                            });
                          });
                        }
                      } catch (err) {
                        appId &&
                          navigate(
                            `/india/corporate/business-details/${appId}`
                          );
                      }
                    });
                  } else {
                    appId &&
                      navigate(`/india/corporate/business-details/${appId}`);
                  }
                });
              } else {
                if (appId) {
                  // console.log(gstinValue);
                  dispatch(getBusinessDetailsFromCINAction(gstinValue)).then(
                    (businessDetailsFields) => {
                      // console.log(businessDetailsFields.payload);
                      let fetchedFields =
                        businessDetailsFields.payload?.result
                          ?.statutoryRegistration;
                      const IEC_number = fetchedFields?.iec[0]?.iec;
                      const LEI_number = fetchedFields?.lei[0]?.lei;
                      // console.log(
                      //   fetchedFields?.exchangeInformation?.bseSecurity[0]?.srcWebsites[0]
                      // );
                      const companyWebsite =
                        fetchedFields?.exchangeInformation?.bseSecurity[0]
                          ?.srcWebsites[0];
                      const natureofActivity =
                        fetchedFields?.mca[0]?.activityGroup_;
                      const dateOfIncorporation =
                        fetchedFields?.mca[0]?.dateOfIncorporation;
                      const placeOfIncorporation =
                        fetchedFields?.lei[0]?.headquartersAddress?.city;

                      dispatch(
                        updateAppAction({
                          customerId: appId,
                          updateCustomerData: {
                            business_iecno: IEC_number ? IEC_number : "",
                            business_legal_entity_identifier: LEI_number
                              ? LEI_number
                              : "",
                            business_website: companyWebsite
                              ? companyWebsite
                              : "",
                            business_nature_activity: natureofActivity
                              ? natureofActivity
                              : "",
                            business_registration_place: placeOfIncorporation
                              ? placeOfIncorporation
                              : "",
                            business_registration_date:
                              convertDateFormat(dateOfIncorporation),
                            api_lei_flag: LEI_number ? true : false,
                            api_nature_activity_flag: natureofActivity
                              ? true
                              : false,
                            api_iecno_flag: IEC_number ? true : false,
                            api_website_flag: companyWebsite ? true : false,
                            api_business_registration_date_flag:
                              dateOfIncorporation ? true : false,
                            api_business_registration_place_flag:
                              placeOfIncorporation ? true : false,
                          },
                        })
                      ).then(() => {
                        let address = fetchedFields?.mca[0]?.registeredAddress;
                        let parseAddressDetails = parseAddress(address);
                        let addressDetailsData = {
                          customer_id: appId,
                          type: "Registered",
                          ...parseAddressDetails,
                          api_line_1_flag: true,
                          api_line_2_flag: true,
                          postal_pincode_api_success: true,
                          api_city_flag: true,
                          api_district_flag: true,
                          api_state_flag: true,
                          api_country_flag: true,
                        };
                        dispatch(createAddressAction(addressDetailsData)).then(
                          () => {
                            navigate(
                              `/india/non-corporate/business-details/${appId}`
                            );
                          }
                        );
                      });

                      // console.log(
                      //   businessDetailsFields.payload?.result?.statutoryRegistration,
                      //   "here"
                      // );
                      // console.log(fetchedFields?.iec[0]?.iec, "IEC");
                      // console.log(fetchedFields?.lei[0]?.lei, "LEI");
                      // console.log(fetchedFields?.tan[0]?.tan, "tan");
                      // return;
                    }
                  );
                } else {
                  alert("Something went wrong!");
                }
              }
            }
          } else {
            if (particiapantRole == "Financier") {
              appId && navigate(`/foreign/financier/business-details/${appId}`);
            } else {
              appId && navigate(`/foreign/business-details/${appId}`);
            }
          }
        }
      });
    }

    // setSpinner(false);
  };

  //   // console.warn("result from main file-->", result);
  //   if (result.payload.data) {
  //     if (result.payload.data) {
  //       // console.warn("here-->", result.payload.data.result);
  //       navigate(
  //         `/guest/india/non-corporate/business-details/${result.payload.data.result}`
  //       );
  //       // // console.warn(result.payload.data.data.result, "broo");
  //     } else if (result.payload.data.error.code === 200) {
  //       setShowSpinner(false);
  //       alert("Error! " + result.payload.data.error.data.message);
  //     }
  //   }
  // } catch (error) {
  //   setShowSpinner(false);
  //   // console.error("An error occurred", error);
  // }

  // useEffect(() => {
  //   if (state.data.data.error.code === 200 && state.loading === false) {
  //     alert(state.data.data.error.data.arguments);
  //   } else {
  //     navigate(
  //       `/guest/india/non-corporate/business-details/${state.data.data.result}`
  //     );
  //   }
  // }, [state.data.data.error.code === 200]);

  return (
    <>
      <NavbarVTX />
      <Row>
        <DisplayBox display={panAPIError}>
          <AlertPopup
            variant="warning"
            message="Error while fetching the Business Name from Karza"
          />
        </DisplayBox>
      </Row>
      <Row>
        <Col
          md={6}
          className="d-flex justify-content-center align-items-center"
        >
          <HowItWorks />
        </Col>
        <Col md={6}>
          <Row>
            <Col md={10}>
              <SectionHeading text="YOUR PERSONAL DETAILS" />
            </Col>
          </Row>

          <Form onSubmit={handleSubmit(onSubmitPersonalDetails)}>
            <Row>
              <Col md={10}>
                <Input
                  label="Name"
                  name="customer_contact_name"
                  register={register}
                  required
                  placeholder="Enter name"
                  requiredMessage="Name is required"
                  error={errors.customer_contact_name?.message}
                  disabled
                  // defaultValue={`${localStorage.getItem("guestName")}`}
                />
              </Col>
            </Row>
            <Row>
              <Col md={10} className="d-flex align-items-center">
                {/* <Input
                  label="Cell Phone"
                  name="customer_contact_mobile"
                  register={register}
                  required
                  placeholder="Enter Cellphone"
                  requiredMessage="Cellphone is required"
                  type="number"
                  error={errors.customer_contact_mobile?.message}
                  disabled
                  defaultValue={`${localStorage.getItem("guestMobile")}`}
                /> */}
                <PhoneInputWithDropdown
                  label={"Cellphone"}
                  name={"customer_contact_mobile"}
                  register={register}
                  // required
                  isdCodeName={"customer_contact_mobile_country_code"}
                  isdCodeRegister={register}
                  disabled
                  // inputDefaultValue={`${localStorage.getItem("guestMobile")}`}
                  // dropdownDefaultValue={"+91"}
                  // defaultValue={`${localStorage.getItem(
                  //   "customer_contact_mobile"
                  // )}`}
                />
              </Col>
            </Row>
            <Row>
              <Col md={10}>
                <Input
                  label="Email"
                  type="email"
                  name="customer_contact_email"
                  register={register}
                  required
                  requiredMessage="Email is required"
                  regexPattern={/\S+@\S+\.\S+/}
                  regexMessage="Invalid email format"
                  error={errors.customer_contact_email?.message}
                  placeholder="Enter email"
                  disabled
                  // defaultValue={`${localStorage.getItem("guestEmail")}`}
                />
              </Col>
              <Col
                md={2}
                style={{
                  display: "flex",
                  alignItems: "start",
                  // marginTop: "5px",
                  marginLeft: "-50px",
                }}
              >
                <IconInput
                  icon={<MdOutlineModeEditOutline />}
                  onClick={() => {
                    navigate("/", {
                      state: {
                        guest_user_name: formData.guest_user_name,
                        guest_user_mobile: formData.guest_user_mobile,
                        customer_contact_mobile_country_code:
                          formData.customer_contact_mobile_country_code,
                        guest_email: formData.guest_email,
                      },
                    });
                  }}
                  style={{ marginLeft: "15px" }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={10}>
                <Input
                  label="Designation"
                  type="designation"
                  name="designation"
                  register={register}
                  required
                  placeholder="Enter your Designation"
                  requiredMessage="Designation is required"
                  error={errors.designation?.message}
                  regexPattern={ALPHABETS_REGEX}
                />
              </Col>
            </Row>
            <Row>
              <Col md={10}>
                <SectionHeading text="BUSINESS DETAILS" />
              </Col>
            </Row>
            <Row>
              <Col md={10}>
                {/* <RadioButton
                  label="Participation Role"
                  name="business_participant_type"
                  options={participationRoleOptions}
                  register={register}
                  required
                  requiredMessage="Participation role is required"
                  error={errors.business_participant_type?.message}
                /> */}
                <ParticipantType
                  label="Participation Role"
                  name="business_participant_type"
                  register={register}
                  required
                  requiredMessage="Participation role is required"
                  error={errors.business_participant_type?.message}
                />
              </Col>
            </Row>
            <Row>
              <Col md={10} className="mt-2">
                <CountryDropdown
                  label="Business Registration Country"
                  name="business_registration_country_id"
                  register={register}
                  required
                  requiredMessage="Please select country"
                  error={errors.business_registration_country_id?.message}
                />
              </Col>
            </Row>
            {country && countryChange()}
            {showError && <p style={{ color: "red" }}>{showError}</p>}
            <Row>
              <Col md={10} className="mt-3 text-end">
                {/* <Button label="Reset" type="reset" variant="outline-primary" /> */}
                {spinner ? (
                  <Spinner variant="primary" animation="border"></Spinner>
                ) : (
                  <Button label="Create" type="submit" />
                )}
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default GuestCreateAppPage;
