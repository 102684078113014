import React, { Dispatch, SetStateAction, useEffect } from "react";
import { useState } from "react";
import { Row, Col, Form, Table, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Input from "../../../../../components/common/Input";
import {
  MdOutlineModeEditOutline,
  MdOutlineDeleteOutline,
  MdOutlineRemoveRedEye,
} from "react-icons/md";
import Button from "../../../../../components/common/Button";
import Modal from "react-bootstrap/Modal";
import CorrBankCurrencies from "../../../../../components/common/dropdowns/CorrBankCurrencies";
import { CorrBankType } from "../../types/GuestCorrBankDetails";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../redux/store";
import { getAllCurrencies } from "../../../../apis/currency/getAllCurrencies";
import { getAppAction } from "../../../../opsUser/ppApplication/ppIndia/redux/action/getAppAction";
import { getSessionId } from "../../../../../utils/tokenHandlers";
import { useParams } from "react-router-dom";
import { createCorrBankAction } from "../../../../opsUser/ppApplication/ppIndia/redux/action/createCorrBankAction";
import { getAllCorrBankAction } from "../../../../opsUser/ppApplication/ppIndia/redux/action/getAllCorrBankAction";
import IsDefaultBank from "../../../../../components/common/IsDefaultBank";
import { getCorrBanksOfCurrencyAction } from "../../../../opsUser/ppApplication/ppIndia/redux/action/getCorrBanksOfCurrency";
import { updateCorrBankAction } from "../../../../opsUser/ppApplication/ppIndia/redux/action/updateCorrBankAction";
import { deleteCorrBankAction } from "../../../../opsUser/ppApplication/ppIndia/redux/action/deleteCorrBankAction";
import {
  ALPHABETS_REGEX,
  ALPHANUMERIC_REGEX,
  SWIFTCODE_REGEX,
} from "../../../../constants/regexPatterns";
import SectionHeading from "../../../../../components/common/SectionHeading";
import RadioButton from "../../../../../components/common/RadioButton";
import { DisplayBox } from "../../../../../components/containers/DisplayBox";

interface CorrBankProps {
  setCorrBanks?: Dispatch<SetStateAction<never[]>>;
  corrBanks?: any[];
}

interface CorrBankCurrenciesData {
  id: number;
  sr_no: number;
  currency_name: string;
  currency_code: string;
  master_status: string;
  transaction_status: string;
}

const CorrBankAccountDetails: React.FC<CorrBankProps> = ({
  setCorrBanks,
  // corrBanks,
}) => {
  const token = getSessionId();
  let { appId } = useParams();
  let customerId = Number(appId);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    setError,
    formState: { errors },
  } = useForm<CorrBankType>();

  const selectedCurrency = watch("currency_id");

  const dispatch = useDispatch<AppDispatch>();
  const customerDetails = useSelector(
    (state: RootState) => state.opsPpIndApp.getApp
  );

  const localBankIdSelector = useSelector(
    (state: RootState) => state.opsPpIndApp.createLocalBank.data
  );

  const localBankIdSelect = useSelector(
    (state: RootState) => state.opsPpIndApp.getLocalBank.data
  );

  const createCorrBankSelector = useSelector(
    (state: RootState) => state.opsPpIndApp.createCorrBank
  );

  const corrBanks = useSelector(
    (state: RootState) => state.opsPpIndApp.getAllCorrBank.data
  );

  const deleteCorrBank = useSelector(
    (state: RootState) => state.opsPpIndApp.deleteCorrBank
  );

  const updateCorrBanks = useSelector(
    (state: RootState) => state.opsPpIndApp.updateCorrBank
  );

  const corrBanksOfCurrencyCount = useSelector(
    (state: RootState) => state.opsPpIndApp.getCorrBanksOfCurrency.data
  );

  const [corrBankCurrencyValues, setCorrBankCurrencyValues] = useState<
    CorrBankCurrenciesData[]
  >([]);

  const selectedCurrencyId = watch("currency_id");
  const isDefaultBankChecked = watch("is_default_bank");

  useEffect(() => {
    if (selectedCurrencyId) {
      dispatch(
        getCorrBanksOfCurrencyAction({
          customerId,
          currencyId: selectedCurrencyId,
        })
      );
    }
  }, [selectedCurrencyId]);

  useEffect(() => {
    if (isDefaultBankChecked === true) {
      setDefaultBankWarningModal(true);
      //  // console.log(isDefaultBankChecked);
    }
    // // console.log(isDefaultBankChecked);
  }, [isDefaultBankChecked === true]);

  const [getCorrCurrencies, setGetCorrCurrencies] = useState(false);

  useEffect(() => {
    if (getCorrCurrencies === true) {
      if (customerDetails.data && customerDetails.data.currency_ids) {
        //  // console.log("one");
        const corrBankCurrencies = corrBankCurrencyValues.filter((currency) =>
          customerDetails.data.currency_ids.includes(currency.id)
        );
        setCorrBankCurrencyValues(corrBankCurrencies);
        //  // console.log("All Corr Currencies-ss-> ", corrBankCurrencies);
      }
    }
  }, [customerDetails, getCorrCurrencies === true]);

  useEffect(() => {
    getAllCurrencies(token).then((response) => {
      //  // console.log("All Corr Currencies1--> ", response);
      setCorrBankCurrencyValues(response.result.result);
      dispatch(getAppAction(customerId));
      setGetCorrCurrencies(true);
    });
  }, []);

  useEffect(() => {
    // // console.warn(localBankIdSelector.result);
    // // console.warn(`${localStorage.getItem("AddClicked")}`);
    const addClicked = localStorage.getItem("AddClicked");
    if (addClicked === "Yes") {
      const bankId = -1;
      dispatch(getAllCorrBankAction(bankId)).then((resp: any) => {
        //  // console.warn("resp--", resp);
        localStorage.removeItem("AddClicked");
      });
    } else {
      const bankId = localBankIdSelector?.result;
      dispatch(getAllCorrBankAction(bankId)).then((resp: any) => {
        //  // console.warn("resp--", resp);
      });
    }
    // // console.warn(localBankIdSelector, "---resp");
    // if (localBankIdSelector === null) {
    //   const bankId = -1;
    //   dispatch(getAllCorrBankAction(bankId)).then((resp: any) => {
    //     // console.warn("resp--", resp);
    //   });
    // } else if (localBankIdSelector) {
    //   const bankId = localBankIdSelector.result;
    //   dispatch(getAllCorrBankAction(bankId)).then((resp: any) => {
    //     // console.warn("resp--", resp);
    //   });
    // }
  }, [
    createCorrBankSelector,
    deleteCorrBank,
    dispatch,
    localBankIdSelector,
    updateCorrBanks,
  ]);

  const handleDeleteCorrBank = (corrBankId: any) => {
    dispatch(deleteCorrBankAction(corrBankId));
    setSelectedRowIndex(null);
  };
  const [showModal, setShowModal] = useState(false);
  const [modalHeading, setModalHeading] = useState(
    "ADD CORRESPONDENT BANK DETAILS"
  );
  const [viewClicked, setViewClicked] = useState(false);

  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);

  const [addCorrBank, setAddCorrBank] = useState<boolean>(false);
  const [editCorrBankId, setEditCorrBankId] = useState<number>(0);
  const [deleteCorrBankId, setDeleteCorrBankId] = useState<number>(0);
  const [showSpinner, setShowSpinner] = useState(false);

  const [defaultBankWarningModal, setDefaultBankWarningModal] =
    useState<boolean>(true);

  const openModal = () => {
    setShowModal(true);
    reset({ bank_name: "" });
  };

  // const editCorrBank = (index: number) => {
  //   setShowModal(true);
  //   reset({
  //     currency_id: corrBanks?.[index].currency_id,
  //     bank_name: corrBanks?.[index].bank_name,
  //     bank_account_number: corrBanks?.[index].bank_account_number,
  //     re_enter_bank_account_number:
  //       corrBanks?.[index].re_enter_bank_account_number,
  //     iban: corrBanks?.[index].iban,
  //     swift_code: corrBanks?.[index].swift_code,
  //     sort_code: corrBanks?.[index].sort_code,
  //   });
  // };
  const handleSwiftCodeChange = (e: any) => {
    const value = e.target.value.toUpperCase();
    setValue("swift_code", value);
  };

  const editCorrBank = (corrBankId: number) => {
    setViewClicked(true);
    setModalHeading("VIEW CORRESPONDENT BANK DETAILS");
    setShowModal(true);
    setEditCorrBankId(corrBankId);
    //  // console.log(corrBankId);
    //  // console.log("CORR BANKS", corrBanks);
    const corrBank = corrBanks.filter(
      (corrBank: any) => corrBank.id === corrBankId
    );
    //  // console.log("Selected To EDIT Corr", corrBank);
    const isDefault = corrBank[0].is_default_bank;
    reset({
      currency_id: corrBank[0].currency_id.id,
      bank_name: corrBank[0].bank_name,
      bank_account_number: corrBank[0].bank_account_number,
      re_enter_bank_account_number: corrBank[0].re_enter_bank_account_number,
      sort_code: corrBank[0].sort_code,
      iban: corrBank[0].iban,
      swift_code: corrBank[0].swift_code,
      is_default_bank: isDefault === true ? "true" : "false",
    });
  };

  const changeDefaultBank = () => {
    const updateCorrBank = {
      is_default_bank: false,
    };
    dispatch(
      updateCorrBankAction({
        corrBankId: corrBanksOfCurrencyCount.result[0].id,
        updateCorrBankData: updateCorrBank,
      })
    );
    setDefaultBankWarningModal(false);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const accountNumber = watch("bank_account_number");
  const confirmAccountNumber = watch("re_enter_bank_account_number");

  const handleCorrBankSubmit = (corrBankDetails: any) => {
    if (accountNumber !== confirmAccountNumber) {
      setError("re_enter_bank_account_number", {
        type: "manual",
        message: "Account Number do not match",
      });
      return;
    }
    // if (!addCorrBank) {
    //   setCorrBanks?.((prevCorrBanks: any): any => {
    //     // const corrBankDetails = corrBanks?.filter(
    //     //   (corrBank, index) => corrBankIndex != index
    //     // );
    //     prevCorrBanks[editCorrBankIndex].currency_id =
    //       corrBankDetailsData.currency_id;
    //     prevCorrBanks[editCorrBankIndex].bank_name =
    //       corrBankDetailsData.bank_name;
    //     prevCorrBanks[editCorrBankIndex].bank_account_number =
    //       corrBankDetailsData.bank_account_number;
    //     prevCorrBanks[editCorrBankIndex].re_enter_bank_account_number =
    //       corrBankDetailsData.re_enter_bank_account_number;
    //     prevCorrBanks[editCorrBankIndex].iban = corrBankDetailsData.iban;
    //     prevCorrBanks[editCorrBankIndex].swift_code =
    //       corrBankDetailsData.swift_code;
    //     prevCorrBanks[editCorrBankIndex].sort_code =
    //       corrBankDetailsData.sort_code;
    //     return prevCorrBanks;
    //   });
    // } else {
    //   // console.log("COrr Selected Currency", selectedCurrency);
    //   setCorrBanks?.((prevCorrBanks): any => {
    //     const corrBankDetails = {
    //       ...corrBankDetailsData,
    //       currencyCode: selectedCurrency,
    //     };
    //     // console.log([...prevCorrBanks, corrBankDetails]);
    //     return [...prevCorrBanks, corrBankDetails];
    //   });
    //   // console.log(corrBanks);
    // }

    if (addCorrBank) {
      setShowSpinner(true);
      try {
        const createCorrBankDetails = {
          ...corrBankDetails,
          currency_id: Number(corrBankDetails.currency_id),
          customer_settlement_bank_id: localBankIdSelector.result,
          is_default_bank:
            corrBankDetails.is_default_bank === "true" ? true : false,
        };
        // // console.log(createCorrBankDetails, "--corr");
        dispatch(createCorrBankAction(createCorrBankDetails)).then(
          (response) => {
            if (response.payload.error) {
              //  // console.log("hey", response.payload.error.data.message);
              alert(response.payload.error.data.message);
              setDefaultBankWarningModal(false);
              setShowModal(false);
              reset();
              setShowSpinner(false);
            } else {
              setShowModal(false);
              reset();
              setShowSpinner(false);
            }
          }
        );
      } catch (Err) {
        // // console.log(Err);
        alert(
          "Something went wrong! Please make sure the Local Bank Details are Saved else Contact the Admin"
        );
        setShowSpinner(false);
        reset();
        setShowModal(false);
      }
    } else {
      const createCorrBankDetails = {
        ...corrBankDetails,
        currency_id: Number(corrBankDetails.currency_id),
        customer_settlement_bank_id: localBankIdSelector.result,
      };
      dispatch(
        updateCorrBankAction({
          corrBankId: editCorrBankId,
          updateCorrBankData: createCorrBankDetails,
        })
      );
      setShowSpinner(false);
    }

    // reset();
    // setShowModal(false);
  };

  return (
    <>
      <Row>
        <Col md={20} className="text-end">
          <Button
            label=" + Add Correspondent Bank"
            variant="outline-primary"
            onClick={() => {
              setModalHeading("ADD CORRESPONDENT BANK DETAILS");
              openModal();
              setViewClicked(false);
              setAddCorrBank(true);
            }}
          ></Button>
        </Col>
      </Row>
      <Table striped bordered size="xxs" style={{ padding: "10%" }}>
        <thead>
          <tr>
            <th style={{ width: "20%" }}>BANK & BRANCH NAME</th>
            <th>ACCOUNT NO</th>
            <th>CURRENCIES</th>
            <th>IS DEFAULT</th>

            <th style={{ width: "10%" }}>ACTION</th>
          </tr>
        </thead>
        <tbody>
          {corrBanks &&
            corrBanks.map((corrBank: any) => (
              <tr>
                <td>{corrBank.bank_name}</td>
                <td>{corrBank.bank_account_number}</td>
                {/* //<td>{corrBank.swift_code}</td> */}
                <td>{corrBank.currency_id.currency_code}</td>
                <td>{corrBank.is_default_bank === true ? "Yes" : "No"}</td>

                <td style={{ color: "#214382" }}>
                  <MdOutlineRemoveRedEye
                    onClick={() => editCorrBank(corrBank.id)}
                    style={{ width: "33px", height: "24px" }}
                  ></MdOutlineRemoveRedEye>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      <Modal size="lg" show={showModal} onHide={closeModal} backdrop="static">
        <Modal.Header closeButton>
          <SectionHeading text={modalHeading} />
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={handleSubmit(handleCorrBankSubmit)}
            style={{ width: "119%" }}
          >
            <Row>
              {/* <Col md={15} className="bank-account-col">
          <SectionHeading text="CORRESPONDENT BANK DETAILS" />
        </Col> */}
            </Row>

            <Row style={{ marginTop: "30px" }}>
              <Col sm={5} className="d-flex align-items-center">
                {/* {corrBankCurrencyValues.length > 0 && (
                  <CorrBankCurrencies
                    label="Currency"
                    name="currency_id"
                    register={register}
                    required
                    requiredMessage="currency is required"
                    error={errors.currency_id?.message}
                    corrBankCurrencies={corrBankCurrencyValues}
                  />
                )} */}
                <CorrBankCurrencies
                  label="Currency"
                  name="currency_id"
                  register={register}
                  required={!viewClicked}
                  requiredMessage="Currency is required"
                  error={errors.currency_id?.message}
                  corrBankCurrencies={corrBankCurrencyValues}
                  disabled={viewClicked}
                />
              </Col>
              <Col sm={5} className="d-flex align-items-center">
                <Input
                  label="Sort code"
                  name="sort_code"
                  register={register}
                  regexPattern={ALPHANUMERIC_REGEX}
                  placeholder="Enter sort code"
                  // required
                  // requiredMessage="sort code is required"
                  error={errors.sort_code?.message}
                  disabled={viewClicked}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={5} className="d-flex align-items-center">
                <Input
                  label="Bank name"
                  name="bank_name"
                  register={register}
                  regexPattern={ALPHABETS_REGEX}
                  placeholder="Enter bank name"
                  required={!viewClicked}
                  requiredMessage="Bank name is required"
                  error={errors.bank_name?.message}
                  disabled={viewClicked}
                />
              </Col>
              <Col sm={5} className="d-flex align-items-center">
                <Input
                  label="IBAN"
                  name="iban"
                  register={register}
                  regexPattern={ALPHANUMERIC_REGEX}
                  placeholder="Enter IBAN"
                  // required
                  // requiredMessage="iban is required"
                  error={errors.iban?.message}
                  disabled={viewClicked}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={5} className="d-flex align-items-center">
                <Input
                  label="Bank account number"
                  name="bank_account_number"
                  register={register}
                  placeholder="Enter bank account no"
                  // required
                  // requiredMessage="bank account number is required"
                  error={errors.bank_account_number?.message}
                  disabled={viewClicked}
                />
              </Col>
              <Col sm={5} className="d-flex align-items-center">
                <Input
                  label="Swift code"
                  name="swift_code"
                  // regexPattern={SWIFTCODE_REGEX}
                  register={register}
                  placeholder="Enter swift code"
                  required={!viewClicked}
                  requiredMessage="Swift Code is required"
                  error={errors.swift_code?.message}
                  disabled={viewClicked}
                  onChange={handleSwiftCodeChange}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={5} className="d-flex align-items-center">
                <Input
                  label="Re-enter Bank account number"
                  name="re_enter_bank_account_number"
                  type="password"
                  register={register}
                  placeholder="Enter re-enter bank account no"
                  // required
                  // requiredMessage="re enter bank account number is required"
                  error={errors.re_enter_bank_account_number?.message}
                  disabled={viewClicked}
                />
              </Col>
              <Col sm={5} className="d-flex align-items-center">
                <RadioButton
                  label="Is Default Bank"
                  name="is_default_bank"
                  register={register}
                  required={!viewClicked}
                  requiredMessage="Please Select the above "
                  error={errors.is_default_bank?.message}
                  options={[
                    { label: "Yes", value: true },
                    { label: "No", value: false },
                  ]}
                  disabled={viewClicked}
                />
              </Col>
            </Row>

            <Row className="mt-3">
              <Col md={5} className="text-end"></Col>
              <Col md={7} className="text-center">
                <DisplayBox display={!viewClicked}>
                  <Button
                    label="Cancel"
                    variant="outline-primary"
                    onClick={closeModal}
                  />
                  {showSpinner ? (
                    <Spinner animation="border" variant="primary" />
                  ) : (
                    <Button label="Save Details" type="submit" />
                  )}
                </DisplayBox>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={selectedRowIndex !== null}
        onHide={() => setSelectedRowIndex(null)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete CORRESPONDENT bank?
        </Modal.Body>
        <Modal.Footer>
          <Button label="Cancel" onClick={() => setSelectedRowIndex(null)} />
          <Button
            label="Delete"
            onClick={() => handleDeleteCorrBank(deleteCorrBankId)}
          />
        </Modal.Footer>
      </Modal>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        // show={
        //   defaultBankWarningModal &&
        //   corrBanksOfCurrencyCount.count &&
        //   isDefaultBankChecked
        // }
        onHide={() => {
          setDefaultBankWarningModal(false);
          reset({ is_default_bank: false });
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Another Bank is Already Set as Default</Modal.Title>
        </Modal.Header>
        <Modal.Body>Bank with same currency is already created !</Modal.Body>
        {/* <Modal.Footer>
          <Button
            label="No"
            onClick={() => {
              setDefaultBankWarningModal(false);
              reset({ is_default_bank: false });
            }}
          />
          <Button label="Yes" onClick={() => changeDefaultBank()} />
        </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default CorrBankAccountDetails;
