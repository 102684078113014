import { useEffect, useState } from "react";
import {
  Col,
  Row,
  Container,
  Modal,
  Card,
  Form,
  Spinner,
  Table,
} from "react-bootstrap";
import Button from "../../../../../components/common/Button";
import Input from "../../../../../components/common/Input";
import { useNavigate, useParams } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { CreateAddressType } from "./types/CreateAddressType";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../redux/store";
import Dropdown from "../../../../../components/common/dropdowns/Dropdown";
import { addressTypes } from "../../../../constants/constants";
import CountryDropdown from "../../../../../components/common/CountryDropdown";
import { getAddressAction } from "../redux/action/getAddressAction";
import { updateAddressAction } from "../redux/action/updateAddressAction";
import { getAppAction } from "../redux/action/getAppAction";
import { getGstAddressAction } from "../redux/action/getGstAddressAction";
import { responsivePropType } from "react-bootstrap/esm/createUtilityClasses";
import NavbarVTX from "../../../../../components/common/Navbar";
import { getAllAddressAction } from "../redux/action/getAllAddressAction";
import TextArea from "../../../../../components/common/Textarea";
import {
  ADDRESS_REGEX,
  ALPHABETS_REGEX,
  ALPHANUMERIC_REGEX,
  EMAIL_REGEX,
  GSTIN_REGEX,
  MULTIPLE_EMAILS_REGEX,
  NON_INDIAN_REGEX,
  PHONE_REGEX,
  PINCODE_REGEX,
} from "../../../../constants/regexPatterns";
import IsdCode from "../../../../../components/common/dropdowns/IsdCode";
import PhoneInputWithDropdown from "../../../../../components/common/PhoneInput";
import { searchAddressByPincodeAction } from "../redux/action/searchAddressByPincodeAction";
import SectionHeading from "../../../../../components/common/SectionHeading";
const OpsEditAddressPage: React.FC = () => {
  let { appId, address_Id, addressType } = useParams();
  let addressId = Number(address_Id);
  let customerId = Number(appId);

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm<CreateAddressType>({});

  type PostOffice = {
    Name: string;
    BranchType: string;
    District: string;
    State: string;
    Pincode: string;
    Block: string;
    Country: string;
  };

  const dispatch = useDispatch<AppDispatch>();

  const updateAddressSelector = useSelector(
    (state: RootState) => state.opsPpIndApp.updateAddress.data
  );

  const getAppSelector = useSelector(
    (state: RootState) => state.opsPpIndApp.getApp.data
  );

  const isIndia = getAppSelector.business_registration_country_id === 448;

  const getGstAddressSelector = useSelector(
    (state: RootState) => state.opsPpIndApp.getGstAddress.data
  );

  useEffect(() => {
    dispatch(getAppAction(customerId));
  }, [dispatch, customerId]);

  const getAddressSelector = useSelector(
    (state: RootState) => state.opsPpIndApp.getAddress
  );
  const [selectedCardIndex, setSelectedCardIndex] = useState<number | null>(
    null
  );

  const [countryId, setCountryId] = useState<number>(0);

  const getAllAddressSelector = useSelector(
    (state: RootState) => state.opsPpIndApp.getAllAddress
  );
  const isCountryIndia = Number(watch("country_id")) === 448;
  const isdCode = watch("phone_country_code");
  const phone = watch("phone");
  const [disableBillingGSTIN, setDisableBillingGSTIN] = useState(false);
  const [disableCopyGSTIN, setDisableCopyGSTIN] = useState(false);

  const [showPincodeSearchModal, setPincodeSearchShowModal] = useState(false);
  const handlePincodeSearchModalOpen = () => setPincodeSearchShowModal(true);
  const handlePincodeSearchModalClose = () => setPincodeSearchShowModal(false);

  const [showPincodeSearch, setShowPincodeSearch] = useState(false);
  const [pincodeValue, setPincodeValue] = useState("");
  const [addressByPincode, setAddressByPincode] = useState<PostOffice[]>([]);
  const [selectedAddress, setSelectedAddress] = useState<PostOffice | null>(
    null
  );
  const [searchAddrSpinner, setSearchAddrSpinner] = useState(false);
  const pincode = watch("pincode");

  const [commCityFetched, setCommCityFetched] = useState(false);
  const [commDistrictFetched, setCommDistrictFetched] = useState(false);
  const [commStateFetched, setCommStateFetched] = useState(false);
  const [commCountryFetched, setCommCountryFetched] = useState(false);

  //reg address
  const [regAddLine1Fetched, setRegAddLine1Fetched] = useState(false);
  const [regAddLine2Fetched, setRegAddLine2Fetched] = useState(false);
  const [regPincodeFetched, setRegPincodeFetched] = useState(false);
  const [regCityFetched, setRegCityFetched] = useState(false);
  const [regDistrictFetched, setRegDistrictFetched] = useState(false);
  const [regStateFetched, setRegStateFetched] = useState(false);
  const [regCountryFetched, setRegCountryFetched] = useState(false);

  const handleSearchPincode = async () => {
    setSearchAddrSpinner(true);
    dispatch(searchAddressByPincodeAction(pincodeValue)).then((resp) => {
      const postOffices: PostOffice[] =
        resp.payload?.result?.[0]?.PostOffice || [];
      if (resp.payload?.result?.[0]?.Status === "Success") {
        setAddressByPincode(postOffices);
        handlePincodeSearchModalOpen();
        setSearchAddrSpinner(false);
      } else {
        alert("Could not fetch the address");
        setSearchAddrSpinner(false);
      }
    });
  };

  const handleClosePincodeSearchModal = () => {
    handlePincodeSearchModalClose(); // Close the modal
    setSearchAddrSpinner(false); // Stop the spinner
  };

  const handleAddressDetailsSubmit: SubmitHandler<CreateAddressType> = (
    addressDetails
  ) => {
    const addressDetailsData = {
      ...addressDetails,
      country_id: Number(addressDetails.country_id),
      api_com_city_flag: commCityFetched,
      api_com_district_flag: commDistrictFetched,
      api_com_state_flag: commStateFetched,
      api_com_country_flag: commCountryFetched,
      api_line_1_flag: regAddLine1Fetched,
      api_line_2_flag: regAddLine2Fetched,
      postal_pincode_api_success: regPincodeFetched,
      api_city_flag: regCityFetched,
      api_district_flag: regDistrictFetched,
      api_state_flag: regStateFetched,
      api_country_flag: regCountryFetched,
    };
    if (isdCode === "+91" && phone?.length !== 10) {
      alert("Please enter 10 digit phone number");
    } else {
      setShowSpinner(true);
      // console.warn(addressDetailsData, "addr data");
      dispatch(
        updateAddressAction({ addressId, addressDetails: addressDetailsData })
      ).then((response) => {
        // console.warn("UPDATE ADDRES RESPO", response);

        if (getAppSelector.business_registration_country_id == 448) {
          if (getAppSelector.business_participant_type == "Financier") {
            navigate(`/india/financier/business-details/${customerId}`);
          } else {
            if (
              getAppSelector.business_constitution_type == "LLC" ||
              getAppSelector.business_constitution_type == "LLP" ||
              getAppSelector.business_constitution_type == "PLC"
            ) {
              navigate(`/india/corporate/business-details/${customerId}`);
            } else {
              navigate(`/india/non-corporate/business-details/${customerId}`);
            }
          }
        } else {
          if (getAppSelector.business_participant_type == "Financier") {
            navigate(`/foreign/financier/business-details/${customerId}`);
          } else {
            navigate(`/foreign/business-details/${customerId}`);
          }
        }
        setShowSpinner(false);

        // navigate(
        //   `/ops/business-details/${getAddressSelector.data.result.customer_id}`
        // );
      });
    }
  };

  const selectedAddressTypeCol =
    addressType == "Registered"
      ? "add_address_types_registered"
      : "add_address_types_communication";

  const selectedAddressType = watch(selectedAddressTypeCol);

  const isApiAddress =
    selectedAddressType == "Copy Register Address" ||
    selectedAddressType == "Copy GSTIN address";

  const regAddress = getAllAddressSelector.data.filter(
    (address: any) => address.type === "Registered"
  );

  const regAddressId = regAddress?.[0]?.id;

  useEffect(() => {
    dispatch(getAddressAction(regAddressId)).then((resp) => {
      // console.log(resp, "dhhurrrrr");
      if (resp.payload) {
        setCountryId(resp.payload.result.country_id);
      }
    });
  }, [regAddressId]);

  // useEffect(() => {
  //   // console.log("API ADDRESS", isApiAddress);
  //   // console.log(selectedAddressType);
  //   if (selectedAddressType === "Copy Register Address") {
  //     setValue("line_1", regAddress[0].line_1);
  //     setValue("line_2", regAddress[0].line_2);
  //     setValue("city", regAddress[0].city);
  //     setValue("district", regAddress[0].district);
  //     setValue("state", regAddress[0].state);
  //     setValue("country_id", regAddress[0].country_id);
  //     setValue("pincode", regAddress[0].pincode);
  //   } else if (selectedAddressType == "Copy GSTIN address") {
  //     setValue("line_1", getGstAddressSelector.result[0].bno);
  //     setValue("line_2", getGstAddressSelector.result[0].bnm);
  //     setValue("city", getGstAddressSelector.result[0].st);
  //     setValue("district", getGstAddressSelector.result[0].loc);
  //     setValue("state", getGstAddressSelector.result[0].stcd);
  //     setValue("country_id", 448);
  //     setValue("pincode", getGstAddressSelector.result[0].pncd);
  //   }
  //   // else if (selectedAddressType == "Add Address Manually") {
  //   //   // console.log("RESETssssss");
  //   //   setValue("line_1", "");
  //   //   setValue("line_2", "");
  //   //   setValue("city", "");
  //   //   setValue("district", "");
  //   //   setValue("state", "");
  //   //   setValue("pincode", "");
  //   // }
  // }, [selectedAddressType]);

  const handleAddAddressManually = async () => {
    setValue("line_1", "");
    setValue("line_2", "");
    setValue("city", "");
    setValue("district", "");
    setValue("state", "");
    setValue("pincode", "");
    setValue("email", "");
    setValue("business_billing_preference_email", "");
    setValue("business_communication_preference_email", "");
    setValue("business_billing_gstn", "");
    setValue("phone_country_code", "");
    setValue("phone", "");
    setValue("country_id", null);
    setDisableBillingGSTIN(false);
    setDisableCopyGSTIN(false);
    // setValue("country_id", `${``}`);
    setShowPincodeSearch(true);
    setPincodeValue("");
  };

  const handleCopyGSTINAddress = async () => {
    setValue("line_1", getGstAddressSelector.result[0].bno);
    setValue("line_2", getGstAddressSelector.result[0].bnm);
    setValue("city", getGstAddressSelector.result[0].st);
    setValue("district", getGstAddressSelector.result[0].loc);
    setValue("state", getGstAddressSelector.result[0].stcd);
    setValue("country_id", 448);
    setValue("pincode", getGstAddressSelector.result[0].pncd);
    setValue("email", "");
    setValue("business_billing_preference_email", "");
    setValue("business_communication_preference_email", "");
    setValue("business_billing_gstn", "");
    setValue("phone_country_code", "");
    setValue("phone", "");
    setDisableBillingGSTIN(false);
    setDisableCopyGSTIN(false);
    setShowPincodeSearch(false);
    setPincodeValue(getGstAddressSelector.result[0].pncd);
  };

  const handleCopyRegisteredAddress = async () => {
    setValue("line_1", regAddress[0].line_1);
    setValue("line_2", regAddress[0].line_2);
    setValue("city", regAddress[0].city);
    setValue("district", regAddress[0].district);
    setValue("state", regAddress[0].state);
    setValue("country_id", countryId);
    setValue("pincode", regAddress[0].pincode);
    setValue("phone_country_code", regAddress[0].phone_country_code);
    setValue("phone", regAddress[0].phone);
    setValue("email", regAddress[0].email);
    setDisableBillingGSTIN(false);
    setDisableCopyGSTIN(false);
    setValue(
      "business_billing_preference_email",
      regAddress[0].business_billing_preference_email
    );
    setValue(
      "business_communication_preference_email",
      regAddress[0].business_communication_preference_email
    );
    setShowPincodeSearch(false);
    setValue("business_billing_gstn", regAddress[0].business_billing_gstn);
    setPincodeValue(regAddress[0].pincode);
  };

  useEffect(() => {
    dispatch(getAddressAction(addressId)).then((resp) => {
      // console.log(resp);
    });
  }, []);

  useEffect(() => {
    // console.log("GET ADDRESS SELECTOR", getAddressSelector);
    if (getAddressSelector.data?.result) {
      reset({
        type: getAddressSelector.data.result.type,
        line_1: getAddressSelector.data.result.line_1,
        line_2: getAddressSelector.data.result.line_2,
        city: getAddressSelector.data.result.city,
        district: getAddressSelector.data.result.district,
        state: getAddressSelector.data.result.state,
        pincode: getAddressSelector.data.result.pincode,
        country_id: getAddressSelector.data.result.country_id,
        phone: getAddressSelector.data.result.phone,
        phone_country_code: getAddressSelector.data.result.phone_country_code,
        email: getAddressSelector.data.result.email,
        business_billing_gstn:
          getAddressSelector.data.result.business_billing_gstn,
        business_billing_preference_email:
          getAddressSelector.data.result.business_billing_preference_email,
        business_communication_preference_email:
          getAddressSelector.data.result
            .business_communication_preference_email,
      });
      setValue("country_id", getAddressSelector.data.result.country_id);
      setPincodeValue(getAddressSelector.data.result.pincode);

      getAddressSelector.data.result.api_com_city_flag == true
        ? setCommCityFetched(true)
        : setCommCityFetched(false);
      getAddressSelector.data.result.api_com_district_flag == true
        ? setCommDistrictFetched(true)
        : setCommDistrictFetched(false);
      getAddressSelector.data.result.api_com_state_flag == true
        ? setCommStateFetched(true)
        : setCommStateFetched(false);
      getAddressSelector.data.result.api_com_country_flag == true
        ? setCommCountryFetched(true)
        : setCommCountryFetched(false);
      getAddressSelector.data.result.api_line_1_flag == true
        ? setRegAddLine1Fetched(true)
        : setRegAddLine1Fetched(false);
      getAddressSelector.data.result.api_line_2_flag == true
        ? setRegAddLine2Fetched(true)
        : setRegAddLine2Fetched(false);
      getAddressSelector.data.result.postal_pincode_api_success == true
        ? setRegPincodeFetched(true)
        : setRegPincodeFetched(false);
      getAddressSelector.data.result.api_city_flag == true
        ? setRegCityFetched(true)
        : setRegCityFetched(false);
      getAddressSelector.data.result.api_district_flag == true
        ? setRegDistrictFetched(true)
        : setRegDistrictFetched(false);
      getAddressSelector.data.result.api_state_flag == true
        ? setRegStateFetched(true)
        : setRegStateFetched(false);
      getAddressSelector.data.result.api_country_flag == true
        ? setRegCountryFetched(true)
        : setRegCountryFetched(false);
    }
  }, [getAddressSelector]);

  useEffect(() => {
    setTimeout(() => {
      dispatch(getAddressAction(addressId));
    }, 500);
  }, []);

  useEffect(() => {
    if (getAppSelector.business_gst) {
      dispatch(getGstAddressAction(getAppSelector.business_gst)).then(
        (response) => {
          // console.log("RESPONSE", response);
        }
      );
    }
  }, [dispatch, getAppSelector]);

  useEffect(() => {
    reset({ type: addressType });
    dispatch(getAllAddressAction(customerId));
  }, []);

  const copyGSTIN = async () => {
    // console.log(getAppSelector.business_gst);
    const gstinValue = getAppSelector.business_gst;
    setValue("business_billing_gstn", gstinValue);
    setDisableCopyGSTIN(true);
    setDisableBillingGSTIN(true);
  };

  const [showSpinner, setShowSpinner] = useState(false);

  return (
    <>
      <Container>
        {/* Modal for adding address */}
        <NavbarVTX />
        <Row>
          <Col
            md={20}
            className="text-center mt-4"
            style={{ color: "#214382" }}
          >
            <h5>EDIT ADDRESS</h5>
          </Col>
        </Row>
        <Row>
          <>
            {getGstAddressSelector.count === 1 && isIndia && (
              <Col md={4}>
                <Card>
                  <Card.Body>
                    <Card.Text>
                      <Form.Check
                        label="Copy GSTIN Address"
                        type="radio"
                        value={"Copy GSTIN address"}
                        {...register(selectedAddressTypeCol, {
                          required: "Please select an option",
                        })}
                        onClick={() => {
                          handleCopyGSTINAddress();
                        }}
                      />
                      {getGstAddressSelector.result &&
                        `${getGstAddressSelector.result[0].bno}, ${getGstAddressSelector.result[0].bnm}, ${getGstAddressSelector.result[0].st}, ${getGstAddressSelector.result[0].loc}, ${getGstAddressSelector.result[0].stcd}, ${getGstAddressSelector.result[0].pncd}`}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            )}
            <Col md={4}>
              {getAllAddressSelector.data.length > 0 && (
                <Card>
                  <Card.Body>
                    <Card.Text>
                      <Form.Check
                        label="Copy Registered Address"
                        type="radio"
                        value={"Copy Register Address"}
                        {...register(selectedAddressTypeCol, {
                          required: "Please select an option",
                        })}
                        onClick={() => {
                          handleCopyRegisteredAddress();
                        }}
                        defaultChecked={addressType === "RegiMastered"}
                      />
                      {`${regAddress[0].line_1} ${regAddress[0].line_2} ${regAddress[0].city} ${regAddress[0].district} ${regAddress[0].state} ${regAddress[0].pincode} ${regAddress[0].country_id.name}`}
                    </Card.Text>
                  </Card.Body>
                </Card>
              )}
            </Col>
          </>

          <Col md={4}>
            <Card>
              <Card.Body>
                <Form.Check
                  type="radio"
                  value={"Add Address Manually"}
                  {...register(selectedAddressTypeCol, {
                    required: "Please select an option",
                  })}
                  onClick={() => {
                    handleAddAddressManually();
                  }}
                  defaultChecked={addressType === "Communication"}
                />
                <Card.Text>Add new address</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Form
          style={{ width: "119%" }}
          onSubmit={handleSubmit(handleAddressDetailsSubmit)}
        >
          <Row style={{ marginTop: "30px" }}>
            <Col sm={5} className="d-flex align-items-center">
              <Dropdown
                label="Address Type"
                name="type"
                options={addressTypes}
                register={register}
                required
                disabled
                //defaultValue={state.data.business_unit_location}
                //error={errors.business_unit_location?.message}
              />
            </Col>
            <Col sm={5}>
              {getAppSelector.is_counterparty === true && (
                <p style={{ fontSize: "smaller", color: "red" }}>
                  {getAppSelector.business_participant_type === "Seller"
                    ? "Following fields are not applicable for Counter Party Applications"
                    : "For Counter Party Buyer, please fill only communication preferences"}
                </p>
              )}
              <Row>
                <label
                  style={{
                    color: "#214382",
                    marginBottom: "10px",
                  }}
                >
                  Multiple email addresses seperated by <strong>;</strong>
                </label>
              </Row>
              <TextArea
                label="Send Notifications On Email"
                name="business_communication_preference_email"
                register={register}
                required={
                  getAppSelector.is_counterparty !== true ||
                  (getAppSelector.is_counterparty &&
                    getAppSelector.business_participant_type === "Buyer")
                }
                requiredMessage="Email is required"
                error={errors.business_communication_preference_email?.message}
                disabled={
                  getAppSelector.is_counterparty === true &&
                  getAppSelector.business_participant_type === "Seller"
                }
                placeholder="Enter Communication Preference Emails seperated by ;"
                regexPattern={MULTIPLE_EMAILS_REGEX}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: "10px" }}>
            <Col sm={5} className="d-flex align-items-center">
              <Input
                label="Address line 1"
                name="line_1"
                register={register}
                required
                regexPattern={ADDRESS_REGEX}
                requiredMessage="Address is required"
                error={errors.line_1?.message}
                colored={regAddLine1Fetched}
                onChange={() => {
                  setRegAddLine1Fetched(false);
                }}
              />
            </Col>
            <Col sm={5}>
              <TextArea
                label="Send VTX Fees Invoice On Email"
                name="business_billing_preference_email"
                register={register}
                required={getAppSelector.is_counterparty !== true}
                requiredMessage="Email is required"
                error={errors.business_billing_preference_email?.message}
                disabled={
                  getAppSelector.is_counterparty === true ? true : false
                }
                placeholder="Enter Billing Preference Emails seperated by ;"
                regexPattern={MULTIPLE_EMAILS_REGEX}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={5} className="d-flex align-items-center">
              <Input
                label="Address line 2"
                name="line_2"
                register={register}
                required
                regexPattern={ADDRESS_REGEX}
                requiredMessage="Address is required"
                error={errors.line_2?.message}
                colored={regAddLine2Fetched}
                onChange={() => {
                  setRegAddLine2Fetched(false);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={5} className="d-flex align-items-center">
              {/* <Input
                  label="Address line 3"
                  name="line_3"
                  register={register}
                  required
                  requiredMessage="Address is required"
                  //error={errors.line_3?.message}
                /> */}
            </Col>
          </Row>
          <Row>
            <Col sm={5} className="d-flex align-items-center">
              <Input
                label="Pin/ Zip Code"
                name="pincode"
                register={register}
                required
                regexPattern={isCountryIndia ? PINCODE_REGEX : NON_INDIAN_REGEX}
                requiredMessage="pincode is required"
                error={errors.pincode?.message}
                onChange={(e) => {
                  setPincodeValue(e.target.value);
                  setRegPincodeFetched(false);
                }}
                link={{
                  text: "Address Lookup",
                  onClick: () => handleSearchPincode(),
                }}
                colored={regPincodeFetched}
              />
            </Col>
          </Row>
          {/* {showPincodeSearch && (
            <Row>
              <Col md={5} className="text-end">
                <>
                  {searchAddrSpinner ? (
                    <Spinner animation="border" variant="primary"></Spinner>
                  ) : (
                    <Button
                      label={"Search Address by Pincode"}
                      variant="outline-primary"
                      onClick={() => {
                        // openPincodeSearchModal()
                        handleSearchPincode();
                      }}
                    ></Button>
                  )}
                </>
              </Col>
            </Row>
          )} */}
          <Row>
            <Col sm={5} className="d-flex align-items-center">
              <Input
                label="City"
                name="city"
                register={register}
                required
                regexPattern={ADDRESS_REGEX}
                requiredMessage="city is required"
                error={errors.city?.message}
                colored={commCityFetched || regCityFetched}
                onChange={() => {
                  setRegCityFetched(false);
                  setCommCityFetched(false);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={5} className="d-flex align-items-center">
              <Input
                label="District/ City Area"
                name="district"
                register={register}
                required
                regexPattern={ADDRESS_REGEX}
                requiredMessage="district is required"
                error={errors.district?.message}
                colored={commDistrictFetched || regDistrictFetched}
                onChange={() => {
                  setCommDistrictFetched(false);
                  setRegDistrictFetched(false);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={5} className="d-flex align-items-center">
              <Input
                label="State/ County"
                name="state"
                register={register}
                required
                regexPattern={ADDRESS_REGEX}
                requiredMessage="state is required"
                error={errors.state?.message}
                colored={commStateFetched || regStateFetched}
                onChange={() => {
                  setCommStateFetched(false);
                  setRegStateFetched(false);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={5} className="d-flex align-items-center">
              <CountryDropdown
                label="Country"
                name="country_id"
                register={register}
                required
                colored={commCountryFetched || regCountryFetched}
                handleChange={() => {
                  setRegCountryFetched(false);
                  setCommCountryFetched(false);
                }}
                // defaultValue={`${getAddressSelector.data.country_id}`}
                //error={errors.type?.message}
              />
            </Col>
          </Row>

          <Row>
            <Col sm={5} className="d-flex align-items-center">
              <Input
                label="Email"
                name="email"
                register={register}
                required
                regexPattern={EMAIL_REGEX}
                requiredMessage="email is required"
                error={errors.email?.message}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={5} className="d-flex align-items-center">
              <PhoneInputWithDropdown
                label="Contact No"
                name="phone"
                register={register}
                isdCodeName={"phone_country_code"}
                isdCodeRegister={register}
                placeholder="Enter your Cellphone"
                error={errors.phone?.message}
                required
                regexPattern={PHONE_REGEX}
                // inputDefaultValue={`${localStorage.getItem("guestMobile")}`}
              />
              {/* <Input
                label="Phone/Mobile No"
                name="phone"
                register={register}
                required
                regexPattern={PHONE_REGEX}
                requiredMessage="phone is required"
                error={errors.phone?.message}
              /> */}
            </Col>
          </Row>
          <Row>
            {getAppSelector.is_counterparty === true && (
              <p style={{ fontSize: "smaller", color: "red" }}>
                Billing GSTN are not applicable for Counter Party Applications
              </p>
            )}
            {isIndia && (
              <Col sm={5} className="d-flex align-items-center">
                <Input
                  label="Billing GSTIN"
                  name="business_billing_gstn"
                  register={register}
                  regexPattern={GSTIN_REGEX}
                  regexMessage="Invalid Format"
                  // required={isIndia && getAppSelector.is_counterparty !== true}
                  // requiredMessage="Billing GSTN is required"
                  // error={errors.business_billing_gstn?.message}
                  disabled={
                    getAppSelector.is_counterparty === true ||
                    disableBillingGSTIN
                  }
                  // defaultValue={`${copyGSTINValue}`}
                />
                {/* <Col sm={2}>
        <Button label="Copy GSTN" variant="outline-primary"></Button>
      </Col> */}
              </Col>
            )}
            {isIndia && (
              <Col sm={2}>
                <Button
                  label="Copy GSTIN"
                  type="button"
                  variant="outline-primary"
                  onClick={copyGSTIN}
                  disabled={
                    getAppSelector.is_counterparty === true ||
                    disableBillingGSTIN
                  }
                ></Button>
              </Col>
            )}
          </Row>
          {/* <Button label="Add Address" onClick={handleAddAddress} /> */}
          <Row>
            <Col md={20} className="text-start">
              {showSpinner ? (
                <Spinner animation="border" variant="primary" />
              ) : (
                <Button label="Save Details" type="submit"></Button>
              )}
            </Col>
          </Row>
        </Form>
      </Container>

      <Modal
        show={showPincodeSearchModal}
        onHide={handleClosePincodeSearchModal}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <SectionHeading text="SELECT ADDRESS " />
            {/* {pincodeValue} */}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {addressByPincode.length > 0 ? (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Select</th>
                  <th>Address</th>
                  {/* <th>Branch Type</th> */}
                  <th>District</th>
                  <th>State</th>
                  <th>Pincode</th>
                </tr>
              </thead>
              <tbody>
                {addressByPincode.map((postOffice, index) => (
                  <tr key={index}>
                    <td>
                      <input
                        type="radio"
                        name="selectedAddress"
                        value={postOffice.Name}
                        onChange={() => setSelectedAddress(postOffice)}
                      />
                    </td>
                    <td>
                      {postOffice.Name},{" "}
                      {postOffice.Block ? postOffice.Block : ""},{" "}
                      {postOffice.Name}
                    </td>
                    {/* <td>{postOffice.BranchType}</td> */}
                    <td>{postOffice.District}</td>
                    <td>{postOffice.State}</td>
                    <td>{postOffice.Pincode}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>No addresses found</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handlePincodeSearchModalClose}>
      Close
    </Button> */}
          {/* <Button variant="primary" disabled={!selectedAddress} onClick={() => console.log("Selected Address:", selectedAddress)}>
      Confirm Address
    </Button> */}
          <Button
            label={"Confirm Address"}
            onClick={() => {
              // console.log("Selected Address:", selectedAddress?.Name);
              if (selectedAddress) {
                setValue("city", selectedAddress.Name);
                setValue("district", selectedAddress.District);
                setValue("state", selectedAddress.State);
                setValue(
                  "country_id",
                  selectedAddress.Country == "India" ? 448 : null
                );
                if (addressType == "Communication") {
                  const flagSettersComm = [
                    {
                      setter: setCommCityFetched,
                      value: selectedAddress.Name,
                    },
                    {
                      setter: setCommDistrictFetched,
                      value: selectedAddress.District,
                    },
                    {
                      setter: setCommStateFetched,
                      value: selectedAddress.State,
                    },
                    {
                      setter: setCommCountryFetched,
                      value: true,
                    },
                  ];
                  flagSettersComm.forEach(({ setter, value }) => {
                    if (value) setter(true);
                  });
                }
                if (addressType == "Registered") {
                  setRegAddLine1Fetched(false);
                  setRegAddLine2Fetched(false);
                  setRegPincodeFetched(false);
                }

                handlePincodeSearchModalClose();
              }
            }}
          ></Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default OpsEditAddressPage;
