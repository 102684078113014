import axios from "axios";

export const getAddress = (addressId: number, sessionId: string | null) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api/read/one/tradexwbench.customer_address/${addressId}`,
      {
        params: {
          filter: `[]`,
          query:
            "{id,customer_id,type,line_1,line_2,city,district,state,pincode,country_id,phone_country_code,phone,email,business_billing_gstn, business_billing_preference_email, business_communication_preference_email, phone_country_code, api_com_city_flag, api_com_district_flag,api_com_state_flag,  api_com_country_flag, api_line_1_flag,api_line_2_flag, postal_pincode_api_success, api_city_flag, api_district_flag, api_state_flag, api_country_flag}",
        },
      },
      {
        params: {
          o_ses_id: `${sessionId}`,
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => err);
};
