import { Accordion, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import React from "react";
import VerBusinessDetails from "../../../components/common/VerBusinessDetails";

const VerCorrBank: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const state = useSelector(
    (state: RootState) => state.verification.verCorrBankDetails
  );
  if (!state.data || !state.data.data) {
    return <Row>Loading...</Row>;
  }

  const corrBankStore: Array<{
    id: number;
    bank_name: string;
    bank_account_number: string;
    sort_code: string;
    iban: string;
    swift_code: string;
    currency_id: { currency_code: string };
    customer_settlement_bank_id: { bank_name: string };
  }> = state.data.data.result.result;

  return (
    <>
      <Accordion>
        {corrBankStore.map((corrBank, index) => (
          <Accordion.Item key={index} eventKey={`C${index}`}>
            <Accordion.Header>Correspondent Bank {index + 1}</Accordion.Header>
            <Accordion.Body>
              <Row md={12}>
                <Col md={12}>
                  <VerBusinessDetails
                    label="Bank Name"
                    defaultValue={corrBank.bank_name}
                  />
                </Col>
              </Row>
              <Row md={12}>
                <Col md={6}>
                  <VerBusinessDetails
                    label="Currency"
                    defaultValue={corrBank.currency_id.currency_code}
                  />
                </Col>
                <Col md={6}>
                  <VerBusinessDetails
                    label="Sort Code"
                    defaultValue={corrBank.sort_code}
                  />
                </Col>
              </Row>
              <Row md={12}>
                <Col md={6}>
                  <VerBusinessDetails
                    label="IBAN"
                    defaultValue={corrBank.iban}
                  />
                </Col>
                <Col md={6}>
                  <VerBusinessDetails
                    label="Swift Code"
                    defaultValue={corrBank.swift_code}
                  />
                </Col>
              </Row>
              <Row md={12}>
                <Col md={12}>
                  <VerBusinessDetails
                    label="Bank Account Number"
                    defaultValue={corrBank.bank_account_number}
                  />
                </Col>
              </Row>
              <Row md={12}>
                <Col md={12}>
                  <VerBusinessDetails
                    label="LOCAL BANK Name"
                    defaultValue={
                      corrBank.customer_settlement_bank_id.bank_name
                    }
                  />
                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </>
  );
};

export default VerCorrBank;
