import { ChangeEvent, useEffect, useRef, useState } from "react";
import {
  MdOutlineRemoveRedEye,
  MdFileDownload,
  MdCloudUpload,
  MdOutlineCloudDownload,
  MdUpload,
  MdDelete,
} from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../redux/store";
import NavbarVTX from "../../../../../components/common/Navbar";
import Button from "../../../../../components/common/Button";
import SectionHeading from "../../../../../components/common/SectionHeading";
import { useParams } from "react-router-dom";
import { getAppAction } from "../redux/action/getAppAction";
import Header from "../../../../../components/common/Header";
import {
  Col,
  Container,
  Dropdown,
  DropdownButton,
  Modal,
  Row,
  Spinner,
  Image,
} from "react-bootstrap";
import { getAuthSignatoriesAction } from "../redux/action/getAuthSignatoriesAction";
import { sendESignAction } from "../redux/action/sendESignAction";
import { getAllKeyPersonAction } from "../redux/action/getAllKeyPersonAction";
import handleFileWithSizeLimit from "../../../../constants/fileSizeLimit";
import { getSessionId } from "../../../../../utils/tokenHandlers";
import { uploadAttachment } from "../apis/uploadAttachment";
import { updateSignedApplication } from "../apis/updateSignedApplication";
import axios from "axios";

const signatories = ["Alice Johnson", "Bob Smith", "Charlie Lee"];

const DocumentSigning: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  let { appId } = useParams();
  let customerId = Number(appId);

  const getAppSelector = useSelector(
    (state: RootState) => state.opsPpIndApp.getApp
  );

  const [showModal, setShowModal] = useState(false);
  const handleModalOpen = () => setShowModal(true);
  const handleModalClose = () => setShowModal(false);

  const [showPromptModal, setShowPromptModal] = useState(false);
  const handlePromptModalOpen = () => setShowPromptModal(true);
  const handlePromptModalClose = () => setShowPromptModal(false);

  const [spinner, setSpinner] = useState(false);

  const [kybStatus, setKybStatus] = useState("");

  const [signatories, setSignatories] = useState<
    { id: number; name: string; email: string }[]
  >([]);

  const [keyPersons, setKeyPersons] = useState<
    { id: number; name: string; email: string }[]
  >([]);

  const fileInputRef1 = useRef<HTMLInputElement | null>(null);
  const fileInputRef2 = useRef<HTMLInputElement | null>(null);
  const [image, setImage] = useState<string | Blob>("");
  const [docName, setDocName] = useState<string | null>(null);

  // Store selected signatories' IDs
  const [selectedSignatories, setSelectedSignatories] = useState<number[]>([]);

  useEffect(() => {
    // setValue("business_registered_with", "MCA");
    dispatch(getAppAction(customerId)).then((resp) => {
      // console.log(resp.payload);
      setKybStatus(resp.payload?.result?.signed_application_form_status);
    });
  }, [dispatch, customerId]);

  useEffect(() => {
    dispatch(getAllKeyPersonAction(customerId)).then((resp) => {
      const data = resp.payload?.result?.result || [];
      // Transform data to { id, name } format
      const formattedKeyPerons = data.map((item: any) => ({
        id: item.id,
        name: `${item.first_name} ${item.last_name}`,
        email: item.email,
      }));
      setKeyPersons(formattedKeyPerons);
      // console.log(formattedKeyPerons);
    });
  }, [dispatch, customerId]);

  useEffect(() => {
    dispatch(getAuthSignatoriesAction(customerId)).then((resp) => {
      const data = resp.payload?.result?.result || [];
      // Transform data to { id, name } format
      const formattedSignatories = data.map((item: any) => ({
        id: item.id,
        name: `${item.first_name} ${item.last_name}`,
        email: item.email,
      }));
      setSignatories(formattedSignatories);
    });
  }, [dispatch, customerId]);

  // Store signatories list
  // Handle selection change
  const handleSelectChange = (signatoryId: number) => {
    setSelectedSignatories(
      (prevSelected) =>
        prevSelected.includes(signatoryId)
          ? prevSelected.filter((id) => id !== signatoryId) // Remove if already selected
          : [...prevSelected, signatoryId] // Add if not selected
    );
  };

  const [safUploadSpinner, setSafUploadSpinner] = useState(false);

  const handleAPI = async () => {
    const files2 = fileInputRef2.current?.files;
    if (!files2 || files2.length === 0) {
      alert("Please select a file before uploading.");
      return;
    }
    setSafUploadSpinner(true);
    if (docName && image) {
      const sessionId = getSessionId();
      const response = await uploadAttachment(docName, image, sessionId);
      if (response.data.message === "Success") {
        const docId = response.data.data.attachement;
        if (docId) {
          const signedAppUpload = await updateSignedApplication(
            customerId,
            docId
          );
          if (signedAppUpload.data.result == true) {
            dispatch(getAppAction(customerId));
            setSafUploadSpinner(false);
            alert("Signed Form Uploaded");
          } else {
            setSafUploadSpinner(false);
            alert("Something went wrong, Please contact Admin");
          }
        }
        if (fileInputRef2.current) {
          fileInputRef2.current.value = "";
          // setButtonText("Upload");
        }
      }
    } else {
      setSafUploadSpinner(false);
      alert("Something went wrong!");
    }
  };

  const [downloadPdfSpinner, setDownloadPdfSpinner] = useState(false);

  const handleDownloadPdf = async () => {
    const sessionid = getSessionId();
    const appNumber = customerId;
    try {
      setDownloadPdfSpinner(true);
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}application/print/${customerId}?o_ses_id=${sessionid}`,
        {
          responseType: "blob",
        }
      );
      const fileName = `${appNumber}_ApplicationForm.pdf`;
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setDownloadPdfSpinner(false);
    } catch (error) {
      setDownloadPdfSpinner(false);
      alert("Something went wrong!");
      // console.error("An error occured", error);
    }
  };

  const [imageData, setImageData] = useState("");
  const [fileType, setFileType] = useState("");
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [showModalSpinner, setShowModalSpinner] = useState(false);
  const sessionId = getSessionId();
  const handleDocumentViewSA = async () => {
    setImageData("");
    dispatch(getAppAction(customerId));
    const docId = getAppSelector.data.signed_application_form_attachment_ids[0];
    // console.log(docId);
    // const sessionid = getSessionId();
    const apiUrl = `${process.env.REACT_APP_BASE_URL}web/content/${docId}/?o_ses_id=${sessionId}`;
    try {
      if (docId) {
        handleShow();
      } else {
        alert("Signed Application is not yet Uploaded!");
      }
      setShowModalSpinner(true);
      const response = await axios.get<ArrayBuffer>(apiUrl, {
        responseType: "arraybuffer",
      });

      const dataAsArray = new Uint8Array(response.data);

      if (
        dataAsArray[0] === 0x25 &&
        dataAsArray[1] === 0x50 &&
        dataAsArray[2] === 0x44 &&
        dataAsArray[3] === 0x46
      ) {
        // const pdfBase64 = `data:application/pdf;base64,${btoa(
        //   String.fromCharCode.apply(null, Array.from(dataAsArray))
        // )}`;
        setImageData(apiUrl);
        // console.warn("its a pdf");
        setFileType("pdf");
      } else {
        setShowModalSpinner(true);
        const imageBase64 = `data:image/png;base64,${btoa(
          new Uint8Array(response.data).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ""
          )
        )}`;
        setImageData(imageBase64);
        // console.warn("its a img");
        setFileType("img");
      }

      // setShowSpinner(false);
      setShowModalSpinner(false);
    } catch (error) {
      // console.error("Error fetching file:", error);
      setShowModalSpinner(false);
    }
    // console.log("API URL:", apiUrl);
  };

  const handleImage = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = e.target.files;

    if (selectedFiles && selectedFiles.length > 0) {
      const selectedFile = selectedFiles[0];

      // Check if the file type is allowed
      const allowedTypes = ["pdf", "jpeg", "png", "jpg"];
      const fileType = selectedFile.name.split(".").pop()?.toLowerCase();

      if (!fileType || !allowedTypes.includes(fileType)) {
        // Display an alert or take appropriate action
        alert(
          "Unsupported file type. Please select a file with one of the following types: pdf, jpeg, png, jpg"
        );
        return;
      }

      setImage(selectedFile);
      // localStorage.setItem("fileName", selectedFile.name);
      setDocName(selectedFile.name);
      // setUploadDisabled(false);
      // setButtonText("Upload");
    }
  };

  const handleConfirmSelection = () => {
    if (selectedSignatories.length > 0) {
      console.log("Selected Signatories:", selectedSignatories);
      handlePromptModalOpen();
      handleModalClose();
    } else {
      alert("Please select Signatory Name");
    }

    // handleModalClose();
  };

  const handleSendESign = () => {
    setSelectedSignatories([]);
    handleModalOpen();
  };

  const handleSendConfirm = () => {
    setSpinner(true);
    dispatch(
      sendESignAction({ appId: customerId, signatories: selectedSignatories })
    ).then((resp) => {
      console.log(resp.payload);
      if (resp.payload?.result?.code == 200) {
        // handleModalOpen();
        alert("Request Sent! Please check email");
        setSpinner(false);
        handlePromptModalClose();
      } else {
        alert("Something went wrong!");
        setSpinner(false);
        handlePromptModalClose();
      }
    });
  };

  return (
    <>
      <NavbarVTX />

      <Header />
      <Container>
        <SectionHeading
          text={
            "Sign Master Agreement, Application Form, KYB, and Keyperson KYC Documents"
          }
        />
        {/* <hr style={{ margin: "10px 0" }} /> */}
        <div className="p-6 rounded-2xl w-full">
          <table className="w-full table-fixed border-collapse m-4">
            <thead>
              <tr className="bg-gray-100" style={{ color: "#214382" }}>
                <th className="p-3 text-left">Document Name</th>
                <th className="p-3 text-center">Upload Signed Copy</th>
                <th className="p-3 text-center">Send for E-Sign</th>
                <th className="p-3 text-center">Action</th>
                <th className="p-3 text-center">Status</th>
              </tr>
            </thead>
            <tbody>
              <tr className="border-t" style={{ color: "#214382" }}>
                <td className="p-3">Master Agreement</td>
                <td className="p-3 text-center">
                  {" "}
                  <MdUpload
                    title="Upload Master Agreement"
                    // onClick={handleDownloadPdf}
                    style={{
                      width: "70px",
                      height: "40px",
                      cursor: "pointer",
                      color: "#214382",
                    }}
                  ></MdUpload>
                  <br></br>
                  {/* <label className="p-2 bg-blue-200 rounded cursor-pointer text-center"> */}
                  {/* <MdCloudUpload className="w-5 h-5 text-blue-500" /> */}
                  <input type="file" className="hidden text-center mt-2" />
                  {/* </label> */}
                </td>
                <td className="text-center"></td>
                <td className="p-3 text-center">
                  {/* <Button label={"Send"}></Button> */}
                  <MdOutlineRemoveRedEye
                    title="View Master Agreement"
                    // onClick={handleDownloadPdf}
                    style={{
                      width: "50px",
                      height: "30px",
                      cursor: "pointer",
                      color: "#214382",
                    }}
                  ></MdOutlineRemoveRedEye>
                  <MdDelete
                    title="Delete Master Agreement"
                    // onClick={handleDownloadPdf}
                    style={{
                      width: "50px",
                      height: "30px",
                      cursor: "pointer",
                      color: "red",
                    }}
                  ></MdDelete>
                </td>
                <td className="text-center" style={{ color: "#214382" }}>
                  <label>Not Uplaoded</label>
                </td>
              </tr>
              {/* <hr style={{ margin: "10px 0" }} /> */}
              <tr className="border-t" style={{ color: "#214382" }}>
                <td className="p-3">Application Form & KYB</td>
                <td className="p-3 text-center">
                  {safUploadSpinner ? (
                    <Spinner animation="border" variant="primary"></Spinner>
                  ) : (
                    <MdUpload
                      style={{
                        width: "70px",
                        height: "40px",
                        cursor: "pointer",
                        color: "#214382",
                      }}
                      className="w-5 h-5 text-blue-500"
                      title="Upload Hard Copy Application Form"
                      onClick={handleAPI}
                    />
                  )}

                  <br></br>

                  <input
                    type="file"
                    className="hidden text-center mt-2"
                    ref={fileInputRef2}
                    accept="pdf, jpeg, png, jpg"
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handleFileWithSizeLimit(e, undefined, handleImage)
                    }
                  />
                </td>
                <td className="text-center">
                  {" "}
                  <Button
                    label={"Send for e-Sign"}
                    onClick={() => {
                      handleSendESign();
                    }}
                  ></Button>
                </td>
                <td className="p-3 text-center">
                  {downloadPdfSpinner ? (
                    <Spinner animation="border" variant="primary"></Spinner>
                  ) : (
                    <MdFileDownload
                      title="Download Application Form"
                      // onClick={handleDownloadPdf}
                      style={{
                        width: "40px",
                        height: "30px",
                        cursor: "pointer",
                        color: "#214382",
                      }}
                      onClick={handleDownloadPdf}
                    ></MdFileDownload>
                  )}

                  {showModalSpinner ? (
                    <Spinner animation="border" variant="primary"></Spinner>
                  ) : (
                    <MdOutlineRemoveRedEye
                      title="View Application Form"
                      // onClick={handleDownloadPdf}
                      style={{
                        width: "40px",
                        height: "30px",
                        cursor: "pointer",
                        color: "#214382",
                      }}
                      onClick={handleDocumentViewSA}
                    ></MdOutlineRemoveRedEye>
                  )}

                  <MdDelete
                    title="Delete Application Form"
                    // onClick={handleDownloadPdf}
                    style={{
                      width: "40px",
                      height: "30px",
                      cursor: "pointer",
                      color: "red",
                    }}
                  ></MdDelete>
                </td>
                <td className="text-center" style={{ color: "#214382" }}>
                  <label>{kybStatus}</label>
                </td>
              </tr>
              {/* <hr style={{ margin: "10px 0" }} /> */}
              {keyPersons.map((keyPerson) => (
                <tr key={keyPerson.id} style={{ color: "#214382" }}>
                  <td>{keyPerson.name}: KYC Doc</td>
                  {/* <td>{keyPerson.email}</td> */}
                  <td className="p-3"></td>
                  <td className="p-3 ">
                    <Button label="Send for e-Sign" />
                  </td>
                  <td className="p-3 text-center">
                    <MdOutlineRemoveRedEye
                      title="View Application Form"
                      // onClick={handleDownloadPdf}
                      style={{
                        width: "50px",
                        height: "30px",
                        cursor: "pointer",
                        color: "#214382",
                      }}
                    ></MdOutlineRemoveRedEye>
                    <MdDelete
                      title="Delete Application Form"
                      // onClick={handleDownloadPdf}
                      style={{
                        width: "50px",
                        height: "30px",
                        cursor: "pointer",
                        color: "red",
                      }}
                    ></MdDelete>
                  </td>
                  <td className="p-3">{kybStatus}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Container>
      <Modal
        show={showModal}
        onHide={() => {
          handleModalClose();
          setSelectedSignatories([]);
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <SectionHeading text="Select Authorised Signatories"></SectionHeading>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table">
            <thead>
              <tr>
                <th>Action</th>
                <th>Signatory Name</th>
                <th>Signatory Email</th>
              </tr>
            </thead>
            <tbody>
              {signatories.map((signatory) => (
                <tr key={signatory.id}>
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedSignatories.includes(signatory.id)}
                      onChange={() => handleSelectChange(signatory.id)}
                    />
                  </td>
                  <td>{signatory.name}</td>
                  <td>{signatory.email}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer>
          <Button
            label="Close"
            onClick={() => {
              handleModalClose();
              setSelectedSignatories([]);
            }}
            variant="outline-primary"
          ></Button>
          <Button
            label="Confirm Selection"
            onClick={handleConfirmSelection}
          ></Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showPromptModal}
        onHide={() => {
          handlePromptModalClose();
          // setSelectedSignatories([]);
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        // size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <SectionHeading text="Are you sure you want to send for eSign ?"></SectionHeading>
          </Modal.Title>
        </Modal.Header>

        <Modal.Footer>
          <Button
            label="Close"
            onClick={() => {
              handlePromptModalClose();
              // console.log(selectedSignatories);
            }}
            variant="outline-primary"
          ></Button>
          {spinner ? (
            <Spinner animation="border" variant="primary" />
          ) : (
            <Button label="Send" onClick={handleSendConfirm}></Button>
          )}
        </Modal.Footer>
      </Modal>

      <Modal show={show} onHide={handleClose} keyboard={false} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Document View</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col className="text-center">
              {showModalSpinner ? (
                <Spinner variant="primary" animation="border" />
              ) : //
              fileType === "pdf" ? (
                <iframe
                  src={imageData}
                  title="Document viewer"
                  style={{ width: "400px", height: "300px" }}
                />
              ) : (
                <Image
                  src={imageData}
                  style={{ width: "600px", height: "400px" }}
                  alt="Document not yet Uploaded"
                />
              )}
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DocumentSigning;
