import React, { useState, useEffect } from "react";
import NavbarVTX from "../../../../../components/common/Navbar";
import Header from "../../../../../components/common/Header";
import Input from "../../../../../components/common/Input";
import { Container, Row, Col, Form, Spinner } from "react-bootstrap";
import { useForm, SubmitHandler } from "react-hook-form";
import CountryDropdown from "../../../../../components/common/CountryDropdown";
import DatePicker from "../../../../../components/common/DatePicker";
import Button from "../../../../../components/common/Button";
import CheckBoxes from "../../../../../components/common/CheckBoxes";
// import "./AddKeyIndividual.css";
import { useNavigate, useParams } from "react-router-dom";
import IndividualDesignation from "../../../../../components/common/dropdowns/IndividualDesignation";
import Gender from "../../../../../components/common/dropdowns/Gender";
import { KeyPersonDetailsType } from "./types/KeyPersonDetailsType";
import KeyPersonType from "../../../../../components/common/KeyPersonType";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../redux/store";
import { createKeyPersonAction } from "../redux/action/createKeyPersonAction";
import { getKeyPersonAction } from "../redux/action/getKeyPersonAction";
import { updateKeyPersonAction } from "../redux/action/updateKeyPersonAction";
import { DisplayBox } from "../../../../../components/containers/DisplayBox";
import {
  ADDRESS_REGEX,
  ALPHABETS_REGEX,
  ALPHANUMERIC_REGEX,
  CKYC_REGEX,
  EMAIL_REGEX,
  NON_INDIAN_REGEX,
  OWNERSHIP_REGEX,
  PAN_REGEX,
  PARTNERSHIP_REGEX,
  PHONE_REGEX,
  PINCODE_REGEX,
} from "../../../../constants/regexPatterns";
import SectionHeading from "../../../../../components/common/SectionHeading";
import IsdCode from "../../../../../components/common/dropdowns/IsdCode";
import PhoneInputWithDropdown from "../../../../../components/common/PhoneInput";
import SingleCheckBox from "../../../../../components/common/SingleCheckbox";
import { getAllKeyPersonAction } from "../redux/action/getAllKeyPersonAction";
import AlertPopup from "../../../../../components/common/Alert/AlertPopup";
import { Table, Modal } from "react-bootstrap";
import { searchAddressByPincodeAction } from "../redux/action/searchAddressByPincodeAction";

const OpsEditKeyPersonDetailsPage: React.FC = () => {
  let { appId, keyPersonId } = useParams();
  let customerId = Number(appId);
  const dispatch = useDispatch<AppDispatch>();

  const keyPersonSelector: KeyPersonDetailsType = useSelector(
    (state: RootState) => state.opsPpIndApp.getKeyPerson.data
  );
  const keyPersonsSelector: KeyPersonDetailsType[] = useSelector(
    (state: RootState) => state.opsPpIndApp.getAllKeyPerson.data
  );
  const [emails, setEmails] = useState<string[]>([]);

  const [designationFetched, setDesignationFetched] = useState(false);
  const [designationOthersFetched, setDesignationOthersFetched] =
    useState(false);
  const [firstNameFetched, setFirstNameFetched] = useState(false);
  const [lastNameFetched, setLastNameFetched] = useState(false);
  const [dobFetched, setDOBFetched] = useState(false);
  const [genderFetched, setGenderFetched] = useState(false);
  const [motherNameFetched, setMotherNameFetched] = useState(false);
  const [nationalityFetched, setNationalityFetched] = useState(false);
  const [panFetched, setPanFetched] = useState(false);
  const [dinFetched, setDinFetched] = useState(false);
  const [districtFetched, setDistrictFetched] = useState(false);
  const [stateFetched, setStateFetched] = useState(false);
  const [countryFetched, setCountryFetched] = useState(false);
  const [cityFetched, setCityFetched] = useState(false);
  const [emailFetched, setEmailFetched] = useState(false);

  useEffect(() => {
    dispatch(getAllKeyPersonAction(customerId));
  }, [customerId, dispatch]);

  useEffect(() => {
    // Extract email addresses from keyPersonsSelector and store them in the state
    const extractedEmails = keyPersonsSelector
      .map((person) => person.email)
      .filter((email) => email); // Filtering out null or undefined emails
    setEmails(extractedEmails);
  }, [keyPersonsSelector]);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    reset,
  } = useForm<KeyPersonDetailsType>();
  type PostOffice = {
    Name: string;
    BranchType: string;
    District: string;
    State: string;
    Pincode: string;
    Block: string;
    Country: string;
  };
  const navigate = useNavigate();

  const [selectedKeyPersonType, setSelectedKeyPersonType] = useState<number[]>(
    []
  );
  const isNationalityIndia = Number(watch("nationality_country_id")) === 448;
  const [showSpinner, setShowSpinner] = useState(false);
  const [isMinorDOB, setIsMinorDOB] = useState(false);
  const dob = watch("date_of_birth");
  const [dobOnchange, setDOB] = useState("");
  const [showEmailError, setShowEmailError] = useState(false);
  const isBeneficialOwner = selectedKeyPersonType.includes(573);
  const isEntityAdmin = !selectedKeyPersonType.includes(575);
  const entityAdmin = selectedKeyPersonType.includes(575);
  const isMinor = watch("is_minor");
  const isPartner = watch("designation") == "partner";
  const passportExp = watch("passport_expiry_date");
  const designation = watch("designation");
  const isdCode = watch("phone_country_code");
  const phone = watch("phone");
  const email = watch("email");
  const handleAddClick = () => {
    navigate("/guest/Keyindividuallist"); // Replace with the actual path
  };
  const [showPincodeSearchModal, setPincodeSearchShowModal] = useState(false);
  const handlePincodeSearchModalOpen = () => setPincodeSearchShowModal(true);
  const handlePincodeSearchModalClose = () => setPincodeSearchShowModal(false);

  const [showPincodeSearch, setShowPincodeSearch] = useState(false);
  const [pincodeValue, setPincodeValue] = useState("");
  const [addressByPincode, setAddressByPincode] = useState<PostOffice[]>([]);
  const [selectedAddress, setSelectedAddress] = useState<PostOffice | null>(
    null
  );
  const [searchAddrSpinner, setSearchAddrSpinner] = useState(false);
  // const pincode = watch("pincode");
  const handlePincodeSearch = async () => {
    setSearchAddrSpinner(true);
    dispatch(searchAddressByPincodeAction(pincodeValue)).then((resp) => {
      const postOffices: PostOffice[] =
        resp.payload?.result?.[0]?.PostOffice || [];
      if (resp.payload?.result?.[0]?.Status === "Success") {
        setAddressByPincode(postOffices);
        handlePincodeSearchModalOpen();
        setSearchAddrSpinner(false);
      } else {
        alert("Could not fetch the address");
        setSearchAddrSpinner(false);
      }
    });
  };

  const handleClosePincodeSearchModal = () => {
    handlePincodeSearchModalClose(); // Close the modal
    setSearchAddrSpinner(false); // Stop the spinner
  };
  const handleDateChange = (selectedDate: string) => {
    setDOB(selectedDate);
    // console.log(selectedDate);
    const currentDate = new Date();
    const birthDate = new Date(selectedDate);
    const ageDiff = currentDate.getFullYear() - birthDate.getFullYear();
    const isUnder18 =
      ageDiff < 18 ||
      (ageDiff === 18 && currentDate.getMonth() < birthDate.getMonth());

    // console.log("Is under 18:", isUnder18);
    if (isUnder18 === true) {
      setIsMinorDOB(true);
      setValue("is_minor", true);
    } else {
      setIsMinorDOB(false);
      setValue("is_minor", false);
    }
    // Add any additional logic you want to perform on date change
  };

  useEffect(() => {
    if (keyPersonSelector.first_name) {
      reset({
        designation: keyPersonSelector.designation,
        if_others: keyPersonSelector.if_others,
        first_name: keyPersonSelector.first_name,
        last_name: keyPersonSelector.last_name,
        middle_name: keyPersonSelector.middle_name,
        mother_name: keyPersonSelector.mother_name,
        gender: keyPersonSelector.gender,
        date_of_birth: keyPersonSelector.date_of_birth,
        is_politically_exposed: keyPersonSelector.is_politically_exposed,
        is_minor: keyPersonSelector.is_minor,
        guardian_name: keyPersonSelector.guardian_name,
        guardian_relation: keyPersonSelector.guardian_relation,
        nationality_country_id: keyPersonSelector.nationality_country_id,
        tax_id: keyPersonSelector.tax_id,
        passport_expiry_date: keyPersonSelector.passport_expiry_date,
        passport_number: keyPersonSelector.passport_number,
        employee_id: keyPersonSelector.employee_id,
        din: keyPersonSelector.din,
        ckycno: keyPersonSelector.ckycno,
        ownership_percentage: keyPersonSelector.ownership_percentage,
        partnership_percentage: keyPersonSelector.partnership_percentage,
        key_person_address_line_1: keyPersonSelector.key_person_address_line_1,
        key_person_address_line_2: keyPersonSelector.key_person_address_line_2,
        key_person_address_city: keyPersonSelector.key_person_address_city,
        key_person_address_district:
          keyPersonSelector.key_person_address_district,
        key_person_address_pincode:
          keyPersonSelector.key_person_address_pincode,
        key_person_address_state: keyPersonSelector.key_person_address_state,
        key_person_address_country_id:
          keyPersonSelector.key_person_address_country_id,
        phone: keyPersonSelector.phone,
        email: keyPersonSelector.email,
        key_person_type_ids: keyPersonSelector.key_person_type_ids,
        tax_residency_country_id:
          keyPersonSelector.key_person_address_country_id,
        phone_country_code: keyPersonSelector.phone_country_code,
      });
      setSelectedKeyPersonType(keyPersonSelector.key_person_type_ids);
      setPincodeValue(keyPersonSelector.key_person_address_pincode);

      keyPersonSelector.api_designation_flag == true
        ? setDesignationFetched(true)
        : setDesignationFetched(false);
      keyPersonSelector.api_first_name_flag == true
        ? setFirstNameFetched(true)
        : setFirstNameFetched(false);
      keyPersonSelector.api_last_name_flag == true
        ? setLastNameFetched(true)
        : setLastNameFetched(false);
      keyPersonSelector.api_date_of_birth_flag == true
        ? setDOBFetched(true)
        : setDOBFetched(false);
      keyPersonSelector.api_gender_flag == true
        ? setGenderFetched(true)
        : setGenderFetched(false);
      keyPersonSelector.api_mother_name_flag == true
        ? setMotherNameFetched(true)
        : setMotherNameFetched(false);
      keyPersonSelector.nationality_country_id_flag == true
        ? setNationalityFetched(true)
        : setNationalityFetched(false);
      keyPersonSelector.api_tax_id_flag == true
        ? setPanFetched(true)
        : setPanFetched(false);
      keyPersonSelector.api_din_flag == true
        ? setDinFetched(true)
        : setDinFetched(false);
      keyPersonSelector.api_district_flag == true
        ? setDistrictFetched(true)
        : setDistrictFetched(false);
      keyPersonSelector.api_state_flag == true
        ? setStateFetched(true)
        : setStateFetched(false);
      keyPersonSelector.api_country_flag == true
        ? setCountryFetched(true)
        : setCountryFetched(false);
      keyPersonSelector.api_city_flag == true
        ? setCityFetched(true)
        : setCityFetched(false);
      keyPersonSelector.api_email_flag == true
        ? setEmailFetched(true)
        : setEmailFetched(false);
      keyPersonSelector.api_if_others_flag == true
        ? setDesignationOthersFetched(true)
        : setDesignationOthersFetched(false);
    }
  }, [keyPersonSelector]);

  const isCountryIndia = Number(watch("key_person_address_country_id")) === 448;

  // useEffect(() => {
  //   if (dob) {
  //     const currentDate = new Date();
  //     const birthDate = new Date(dob);
  //     const ageDiff = currentDate.getFullYear() - birthDate.getFullYear();
  //     const isUnder18 =
  //       ageDiff < 18 ||
  //       (ageDiff === 18 && currentDate.getMonth() < birthDate.getMonth());

  //     // console.log("Is under 18:", isUnder18);
  //     if (isUnder18 === true) {
  //       setIsMinorDOB(true);
  //       setValue("is_minor", true);
  //     } else if (isUnder18 === false) {
  //       setIsMinorDOB(false);
  //     }
  //   }
  // }, [dob]);

  useEffect(() => {
    dispatch(getKeyPersonAction(Number(keyPersonId)));
  }, []); // Empty dependency array means it runs once after the initial render

  useEffect(() => {
    setTimeout(() => {
      dispatch(getKeyPersonAction(Number(keyPersonId)));
    }, 500);
  }, []);

  const handleKeyPersonDetailsSubmit: SubmitHandler<KeyPersonDetailsType> = (
    keyPersonDetails
  ) => {
    // if (entityAdmin) {
    //   if (emails.includes(email)) {
    //     // console.error("Error: Email already exists");
    //     setShowEmailError(true);
    //     setTimeout(() => {
    //       setShowEmailError(false);
    //     }, 2000);
    //   }
    //   return;
    // }
    setShowSpinner(true);
    keyPersonDetails.customer_id = customerId;
    keyPersonDetails.key_person_type_ids = selectedKeyPersonType;
    keyPersonDetails.key_person_address_country_id = Number(
      keyPersonDetails.key_person_address_country_id
    );
    keyPersonDetails.nationality_country_id = Number(
      keyPersonDetails.nationality_country_id
    );
    if (!passportExp) {
      keyPersonDetails.passport_expiry_date = false;
    } else {
      keyPersonDetails.passport_expiry_date = passportExp;
    }

    keyPersonDetails.api_designation_flag = designationFetched;
    keyPersonDetails.api_first_name_flag = firstNameFetched;
    keyPersonDetails.api_last_name_flag = lastNameFetched;
    keyPersonDetails.api_date_of_birth_flag = dobFetched;
    keyPersonDetails.api_gender_flag = genderFetched;
    keyPersonDetails.api_mother_name_flag = motherNameFetched;
    keyPersonDetails.nationality_country_id_flag = nationalityFetched;
    keyPersonDetails.api_tax_id_flag = panFetched;
    keyPersonDetails.api_din_flag = dinFetched;
    keyPersonDetails.api_district_flag = districtFetched;
    keyPersonDetails.api_state_flag = stateFetched;
    keyPersonDetails.api_country_flag = countryFetched;
    keyPersonDetails.api_city_flag = cityFetched;
    keyPersonDetails.api_email_flag = emailFetched;
    keyPersonDetails.api_if_others_flag = designationOthersFetched;
    // console.log(keyPersonDetails);
    if (isdCode === "+91" && phone?.length !== 10) {
      alert("Please enter 10 digit phone number");
      setShowSpinner(false);
    } else {
      dispatch(
        updateKeyPersonAction({
          keyPersonId: Number(keyPersonId),
          keyPersonDetails,
        })
      ).then((resp) => {
        // console.log("resp edit key person---", resp.payload);
        navigate(`/key-person-details/${customerId}`);
        setShowSpinner(false);
      });
    }
  };

  return (
    <>
      {/* <DisplayBox display={showEmailError}>
        <AlertPopup
          message="An Entity Admin with this Email already exists"
          variant="danger"
        ></AlertPopup>
      </DisplayBox> */}
      <NavbarVTX />
      <Header />
      <Container>
        <Row>
          <Col md={20} className="myCol">
            <SectionHeading text="EDIT KEY PERSON DETAILS" />
          </Col>
        </Row>
        {/*<p>{isMinorDOB === true ? "Yes" : "No"}</p>*/}
        <Form onSubmit={handleSubmit(handleKeyPersonDetailsSubmit)}>
          <Row className="myrow">
            <Col sm={5} style={{ marginLeft: "30%" }}>
              <KeyPersonType
                name="key_person_type_ids"
                label=""
                register={register}
                required
                requiredMessage="Key Person Is required"
                error={errors.key_person_type_ids?.message}
                selectedKeyPersonType={selectedKeyPersonType}
                setSelectedKeyPersonType={setSelectedKeyPersonType}
                setValue={setValue}
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm={4}>
              <IndividualDesignation
                label="Designation"
                name="designation"
                register={register}
                required
                requiredMessage="designation is required"
                error={errors.designation?.message}
                colored={designationFetched}
                handleChange={() => {
                  setDesignationFetched(false);
                }}
              />
            </Col>
            <Col sm={4}>
              <CountryDropdown
                label="Nationality"
                name="nationality_country_id"
                register={register}
                required
                requiredMessage="nationality is required"
                error={errors.nationality_country_id?.message}
                colored={nationalityFetched}
                handleChange={() => {
                  setNationalityFetched(false);
                }}
              />
            </Col>
            <Col sm={4}>
              <Input
                label="Address Line 1"
                name="key_person_address_line_1"
                register={register}
                placeholder="Enter Address line 1"
                required
                // regexPattern={ADDRESS_REGEX}
                requiredMessage="Address line 1 is required"
                error={errors.key_person_address_line_1?.message}
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <DisplayBox display={designation === "others"}>
                <Input
                  label={"If Others,"}
                  name={"if_others"}
                  register={register}
                  required={designation === "others"}
                  placeholder="Designation if others"
                  error={errors.if_others?.message}
                  regexPattern={ALPHABETS_REGEX}
                  colored={designationOthersFetched}
                  onChange={() => {
                    setDesignationOthersFetched(false);
                  }}
                />
              </DisplayBox>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm={4}>
              <Input
                label="First name"
                name="first_name"
                register={register}
                placeholder="Enter first name"
                required
                regexPattern={ALPHABETS_REGEX}
                requiredMessage="first name is required"
                error={errors.first_name?.message}
                colored={firstNameFetched}
                onChange={() => {
                  setFirstNameFetched(false);
                }}
              />
            </Col>
            <Col sm={4}>
              <Input
                label="PAN/TAX Id"
                name="tax_id"
                register={register}
                regexPattern={isNationalityIndia ? PAN_REGEX : undefined}
                regexMessage={isNationalityIndia ? "Invalid PAN" : ""}
                placeholder="Enter Pan/ Tax Id"
                required
                requiredMessage="Tax Id is required"
                error={errors.tax_id?.message}
                maxLength={isNationalityIndia ? 10 : 20}
                colored={panFetched}
                onChange={() => {
                  setPanFetched(false);
                }}
              />
            </Col>
            <Col sm={4}>
              <Input
                label="Address Line 2"
                name="key_person_address_line_2"
                register={register}
                // regexPattern={ADDRESS_REGEX}
                placeholder="Enter Address line 2"
                required
                requiredMessage="Address line 2 is required"
                error={errors.key_person_address_line_2?.message}
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm={4}>
              <Input
                label="Middle name"
                name="middle_name"
                register={register}
                regexPattern={ALPHABETS_REGEX}
                placeholder="Enter middle name"
                // required
                // requiredMessage="middle name is required"
                error={errors.middle_name?.message}
              />
            </Col>
            <Col sm={4}>
              <Input
                label="Passport No"
                name="passport_number"
                register={register}
                regexPattern={ALPHANUMERIC_REGEX}
                placeholder="Enter passport no"
                // required
                // requiredMessage="passport number is required"
                error={errors.passport_number?.message}
              />
            </Col>
            <Col sm={4}>
              <Input
                label="Pincode"
                name="key_person_address_pincode"
                register={register}
                placeholder="Enter pincode"
                required
                regexPattern={isCountryIndia ? PINCODE_REGEX : NON_INDIAN_REGEX}
                regexMessage="Please enter correct pincode"
                requiredMessage="pincode is required"
                error={errors.key_person_address_pincode?.message}
                link={{
                  text: "Address Lookup",
                  onClick: () => handlePincodeSearch(),
                  // className: "text-end",
                }}
                onChange={(e) => {
                  setPincodeValue(e.target.value);
                }}
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm={4}>
              <Input
                label="Last name"
                name="last_name"
                register={register}
                placeholder="Enter last name"
                required
                regexPattern={ALPHABETS_REGEX}
                requiredMessage="last name is required"
                error={errors.last_name?.message}
                colored={lastNameFetched}
                onChange={() => {
                  setLastNameFetched(false);
                }}
              />
            </Col>
            <Col sm={4}>
              <DatePicker
                label="Passport Expiry"
                name="passport_expiry_date"
                register={register}
                // required
                // requiredMessage="passport expiry date is required"
                error={errors.passport_expiry_date?.message}
              />
            </Col>
            <Col sm={4}>
              <Input
                label="District/City"
                name="key_person_address_district"
                register={register}
                placeholder="Enter district/city"
                required
                regexPattern={ALPHABETS_REGEX}
                requiredMessage="district is required"
                error={errors.key_person_address_district?.message}
                colored={districtFetched}
                onChange={() => {
                  setDistrictFetched(false);
                }}
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm={4}>
              {/* <Input
                label="Mother name"
                name="mother_name"
                register={register}
                placeholder="Enter mother name"
                required
                regexPattern={ALPHABETS_REGEX}
                requiredMessage="mother name is required"
                error={errors.mother_name?.message}
              /> */}
              <DatePicker
                label="Date of Birth"
                name="date_of_birth"
                register={register}
                isDateOfBirth={true}
                required
                requiredMessage="date of birth is required"
                error={errors.date_of_birth?.message}
                onChange={handleDateChange}
                skipCurrentYear
                colored={dobFetched}
                handleChange={() => {
                  setDOBFetched(false);
                }}
              />
            </Col>
            <Col sm={4}>
              <Input
                label="Employee Id"
                name="employee_id"
                regexPattern={ALPHANUMERIC_REGEX}
                register={register}
                placeholder="Enter employee id"
                // required
                // requiredMessage="employee id is required"
                error={errors.employee_id?.message}
              />
            </Col>
            <Col sm={4}>
              <Input
                label="State/County"
                name="key_person_address_state"
                register={register}
                placeholder="Enter state/country"
                required
                regexPattern={ALPHABETS_REGEX}
                requiredMessage="state is required"
                error={errors.key_person_address_state?.message}
                colored={stateFetched}
                onChange={() => {
                  setStateFetched(false);
                }}
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm={4}>
              <Gender
                label="Gender"
                name="gender"
                register={register}
                // required
                // requiredMessage="gender is required"
                error={errors.gender?.message}
                colored={genderFetched}
                handleChange={() => {
                  setGenderFetched(false);
                }}
              />
            </Col>
            <Col sm={4}>
              <PhoneInputWithDropdown
                label={entityAdmin ? "Cellphone" : "Contact no/ Landline"}
                name="phone"
                register={register}
                isdCodeName={"phone_country_code"}
                isdCodeRegister={register}
                placeholder="Enter your Contact no"
                error={errors.phone?.message}
                required
                regexPattern={PHONE_REGEX}
                // inputDefaultValue={`${localStorage.getItem("guestMobile")}`}
              />
            </Col>
            <Col sm={4}>
              <CountryDropdown
                label="Country"
                name="key_person_address_country_id"
                register={register}
                required
                requiredMessage="country is required"
                error={errors.key_person_address_country_id?.message}
                colored={countryFetched}
                handleChange={() => {
                  setCountryFetched(false);
                }}
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm={4}>
              {/* <DatePicker
                label="Date of Birth"
                name="date_of_birth"
                register={register}
                isDateOfBirth={true}
                required
                requiredMessage="date of birth is required"
                error={errors.date_of_birth?.message}
                onChange={handleDateChange}
              /> */}
              <Input
                label="Mother/ Father/ Spouse name"
                name="mother_name"
                register={register}
                placeholder="Enter Mother/ Father/ Spouse name"
                required
                regexPattern={ALPHABETS_REGEX}
                requiredMessage="Mother/ Father/ Spouse name is required"
                error={errors.mother_name?.message}
                colored={motherNameFetched}
                onChange={() => {
                  setMotherNameFetched(false);
                }}
              />
            </Col>
            <Col sm={4}>
              <Input
                label="CKYC No"
                name="ckycno"
                register={register}
                placeholder="Enter ckyc"
                regexPattern={CKYC_REGEX}
                // required
                // requiredMessage="ckycno is required"
                error={errors.ckycno?.message}
              />
            </Col>
            <Col sm={4}>
              <Input
                label="City/Town/Village"
                name="key_person_address_city"
                register={register}
                regexPattern={ALPHABETS_REGEX}
                placeholder="Enter city/town/village"
                required
                requiredMessage="city is required"
                error={errors.key_person_address_city?.message}
                colored={cityFetched}
                onChange={() => {
                  setCityFetched(false);
                }}
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm={4}>
              <Input
                label="Ownership %"
                name="ownership_percentage"
                register={register}
                placeholder="Enter ownership %"
                required={isBeneficialOwner}
                regexMessage="Value must be a number less than 100"
                regexPattern={OWNERSHIP_REGEX}
                // requiredMessage="ownership % is required"
                error={errors.ownership_percentage?.message}
              />
            </Col>
            <Col sm={4}>
              <CountryDropdown
                label="Tax Residency"
                name="tax_residency_country_id"
                register={register}
                required
                requiredMessage="tax is required"
                error={errors.tax_residency_country_id?.message}
              />
            </Col>
            <Col sm={4}>
              <Input
                label="Email"
                name="email"
                register={register}
                placeholder="Enter email"
                required
                regexPattern={EMAIL_REGEX}
                requiredMessage="email is required"
                error={errors.email?.message}
                colored={emailFetched}
                onChange={() => {
                  setEmailFetched(false);
                }}
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm={4}>
              <Input
                label="DIN/DPIN"
                name="din"
                regexPattern={ALPHANUMERIC_REGEX}
                register={register}
                placeholder="Enter DIN/DPIN"
                // required
                // requiredMessage="din is required"
                error={errors.din?.message}
                colored={dinFetched}
                onChange={() => {
                  setDinFetched(false);
                }}
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm={4}>
              <DisplayBox display={isBeneficialOwner || isMinorDOB}>
                {/* <CheckBoxes
                  label="Is Minor?"
                  name="is_minor"
                  register={register}
                /> */}
                <SingleCheckBox
                  name={"is_minor"}
                  label={"Is Minor?"}
                  register={register}
                  required={isMinorDOB}
                />
              </DisplayBox>
            </Col>

            <Col sm={4}>
              <DisplayBox display={isMinor}>
                <Input
                  label="Guardian Name"
                  name="guardian_name"
                  register={register}
                  regexPattern={ALPHABETS_REGEX}
                  placeholder="Enter guardian name"
                  required
                  requiredMessage="Guardian name is required"
                  error={errors.guardian_name?.message}
                />
              </DisplayBox>
            </Col>

            <Col sm={4}>
              <DisplayBox display={isMinor}>
                <Input
                  label="Guardian Relationship"
                  name="guardian_relation"
                  register={register}
                  regexPattern={ALPHABETS_REGEX}
                  placeholder="Enter guardian relationship"
                  required
                  requiredMessage="Guardian relation is required"
                  error={errors.guardian_relation?.message}
                />
              </DisplayBox>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col sm={4}>
              <DisplayBox display={isEntityAdmin}>
                {/* <CheckBoxes
                  label="Is Politically exposed person  ?"
                  name="is_politically_exposed"
                  register={register}
                  //required
                  error={errors.is_politically_exposed?.message}
                /> */}
                <SingleCheckBox
                  name={"is_politically_exposed"}
                  label={"Is Politically exposed person ?"}
                  register={register}
                />
              </DisplayBox>
            </Col>
            <Col sm={4}>
              <DisplayBox display={isPartner}>
                <Input
                  label="Partnership(%)"
                  name="partnership_percentage"
                  register={register}
                  regexPattern={PARTNERSHIP_REGEX}
                  regexMessage="Value must be a number less than 100"
                  placeholder="Partnarship(%)"
                  error={errors.partnership_percentage?.message}
                />
              </DisplayBox>
            </Col>
            <Col sm={4}></Col>
          </Row>
          <Row>
            <Col md={20} className="text-end">
              {showSpinner ? (
                <Spinner animation="border" variant="primary" />
              ) : (
                <Button label="Save Details" type="submit"></Button>
              )}
            </Col>
          </Row>
        </Form>
      </Container>

      <Modal
        show={showPincodeSearchModal}
        onHide={handleClosePincodeSearchModal}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <SectionHeading text="SELECT ADDRESS " />
            {/* {pincodeValue} */}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {addressByPincode.length > 0 ? (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Select</th>
                  <th>Address</th>
                  {/* <th>Branch Type</th> */}
                  <th>District</th>
                  <th>State</th>
                  <th>Pincode</th>
                </tr>
              </thead>
              <tbody>
                {addressByPincode.map((postOffice, index) => (
                  <tr key={index}>
                    <td>
                      <input
                        type="radio"
                        name="selectedAddress"
                        value={postOffice.Name}
                        onChange={() => setSelectedAddress(postOffice)}
                      />
                    </td>
                    <td>
                      {postOffice.Name},{" "}
                      {postOffice.Block ? postOffice.Block : ""},{" "}
                      {postOffice.Name}
                    </td>
                    {/* <td>{postOffice.BranchType}</td> */}
                    <td>{postOffice.District}</td>
                    <td>{postOffice.State}</td>
                    <td>{postOffice.Pincode}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>No addresses found</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handlePincodeSearchModalClose}>
      Close
    </Button> */}
          {/* <Button variant="primary" disabled={!selectedAddress} onClick={() => console.log("Selected Address:", selectedAddress)}>
      Confirm Address
    </Button> */}
          <Button
            label={"Confirm Address"}
            onClick={() => {
              // console.log("Selected Address:", selectedAddress?.Name);
              if (selectedAddress) {
                setValue("key_person_address_city", selectedAddress.Name);
                setValue(
                  "key_person_address_district",
                  selectedAddress.District
                );
                setValue("key_person_address_state", selectedAddress.State);
                setValue(
                  "key_person_address_country_id",
                  selectedAddress.Country == "India" ? 448 : 0
                );
                handlePincodeSearchModalClose();
              }
            }}
          ></Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default OpsEditKeyPersonDetailsPage;
