import { createAsyncThunk } from "@reduxjs/toolkit";
import { getBusinessDetailsFromCIN } from "../../apis/getBusinessDetailsFromCIN";

export const getBusinessDetailsFromCINAction = createAsyncThunk(
  "getBusinessDetailsFromCINAction",
  async (id: any) => {
    return getBusinessDetailsFromCIN(id).then((response: any) => {
      return response.data;
    });
  }
);
