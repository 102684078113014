import React, { useEffect, useState } from "react";
import { Col, Row, Form } from "react-bootstrap";
import { getAllCountry } from "../../feature/apis/country/getAllCountry";
import { getSessionId } from "../../utils/tokenHandlers";

interface CountryDropdownProps {
  name: string;
  label: string;
  register: any;
  required?: boolean;
  requiredMessage?: string;
  handleChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  defaultValue?: string;
  error?: string;
  disabled?: boolean;
  colored?: boolean; // New prop added
}

interface Country {
  sr_no: number;
  id: number;
  name: string;
  code: string;
  isd_code: string;
  is_active: boolean;
}

let countryApiCallCount = 0;

const fetchCountriesWithRetry = async (
  token: string,
  retries = 3,
  backoff = 300
): Promise<Country[]> => {
  try {
    countryApiCallCount++;
    const response = await getAllCountry(token);
    if (!response.result || !response.result.result) {
      throw new Error("Invalid response structure");
    }
    return response.result.result;
  } catch (error) {
    if (retries > 0) {
      await new Promise((resolve) => setTimeout(resolve, backoff));
      return fetchCountriesWithRetry(token, retries - 1, backoff * 2);
    } else {
      throw error;
    }
  }
};

const CountryDropdown: React.FC<CountryDropdownProps> = ({
  name,
  label,
  register,
  required,
  requiredMessage = "This field is required",
  handleChange,
  defaultValue,
  error,
  disabled = false,
  colored = false, // Default to false
}) => {
  const token = getSessionId();
  const [countries, setCountries] = useState<Country[]>([]);
  const [loading, setLoading] = useState(true);
  const [fetchError, setFetchError] = useState<string | null>(null);

  useEffect(() => {
    if (token) {
      fetchCountriesWithRetry(token)
        .then((data) => {
          setCountries(data);
        })
        .catch((error) => {
          console.error("Error fetching countries:", error);
          setFetchError("Failed to fetch countries");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [token]);

  return (
    <Form.Group as={Col} className="mb-3">
      <Row className="align-items-center">
        <Form.Label column md={4}>
          <span className="float-end">
            {label}&nbsp;
            <span className="text-danger form-label">
              {required ? "*" : ""}
            </span>
            :
          </span>
        </Form.Label>
        <Col md={8}>
          {loading ? (
            <p>Loading...</p>
          ) : fetchError ? (
            <p className="text-danger">{fetchError}</p>
          ) : (
            <Form.Select
              as="select"
              className={`custom-input form-control ${
                error ? "is-invalid" : ""
              }`}
              {...register(name, {
                required: required ? requiredMessage : false,
                onChange: (event: any) => {
                  if (handleChange) handleChange(event);
                },
              })}
              defaultValue={defaultValue}
              disabled={disabled}
              style={{
                backgroundColor: colored ? "#C2FFC7" : "white",
              }}
            >
              <option value="">Select Option</option>
              {countries.map((country, index) => (
                <option key={index} value={country.id}>
                  {country.name}
                </option>
              ))}
            </Form.Select>
          )}
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col md={4}></Col>
        <Col md={8}>{error && <p className="m-0 err-msg">{error}</p>}</Col>
      </Row>
    </Form.Group>
  );
};

export default CountryDropdown;
