import React from "react";
import NavbarVTX from "../../../components/common/Navbar";
import Header from "../../../components/common/Header";
import Input from "../../../components/common/Input";
import { Container, Row, Col, Form, Spinner } from "react-bootstrap";
import { useForm, SubmitHandler } from "react-hook-form";
import { useState } from "react";
import { useEffect } from "react";
import Dropdown from "../../../components/common/dropdowns/Dropdown";
import CountryDropdown from "../../../components/common/CountryDropdown";
import DatePicker from "../../../components/common/DatePicker";
import Button from "../../../components/common/Button";
import CheckBoxes from "../../../components/common/CheckBoxes";
import { FormData } from "./Types/KeyIndividualType";
import "./KeyIndividual.css";
import { useNavigate, useParams } from "react-router-dom";
import IndividualDesignation from "../../../components/common/dropdowns/IndividualDesignation";
import Gender from "../../../components/common/dropdowns/Gender";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import {
  CPKeyPersonDetailsType,
  KeyPersonDetailsType,
} from "../../opsUser/ppApplication/ppIndia/pages/types/KeyPersonDetailsType";
import SectionHeading from "../../../components/common/SectionHeading";
import { getKeyPersonAction } from "../../opsUser/ppApplication/ppIndia/redux/action/getKeyPersonAction";
import { CPUpdateKeyPersonAction } from "../../opsUser/ppApplication/ppIndia/redux/action/updateCPKeyPersonAction";
import PhoneInputWithDropdown from "../../../components/common/PhoneInput";
import { PHONE_REGEX } from "../../constants/regexPatterns";
import { DisplayBox } from "../../../components/containers/DisplayBox";

const EditKeyIndivdual: React.FC = () => {
  let { appId, keyPersonId } = useParams();
  let customerId = Number(appId);
  const [showSpinner, setShowSpinner] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const keyPersonSelector: CPKeyPersonDetailsType = useSelector(
    (state: RootState) => state.opsPpIndApp.getKeyPerson.data
  );
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<CPKeyPersonDetailsType>();
  const navigate = useNavigate();
  const designation = watch("designation");
  useEffect(() => {
    if (keyPersonSelector.first_name) {
      reset({
        designation: keyPersonSelector.designation,
        if_others: keyPersonSelector.if_others,
        first_name: keyPersonSelector.first_name,
        last_name: keyPersonSelector.last_name,
        middle_name: keyPersonSelector.middle_name,
        mother_name: keyPersonSelector.mother_name,
        gender: keyPersonSelector.gender,
        phone: keyPersonSelector.phone,
        email: keyPersonSelector.email,
        phone_country_code: keyPersonSelector.phone_country_code,
      });
    }
  }, [keyPersonSelector, reset]);

  useEffect(() => {
    dispatch(getKeyPersonAction(Number(keyPersonId)));
  }, [dispatch, keyPersonId]);
  useEffect(() => {
    setTimeout(() => {
      dispatch(getKeyPersonAction(Number(keyPersonId)));
    }, 500);
  }, []);

  const UpdateCPKeyPerson: SubmitHandler<CPKeyPersonDetailsType> = (
    CPKeyPersonDetails
  ) => {
    // console.log(CPKeyPersonDetails);
    setShowSpinner(true);
    CPKeyPersonDetails.customer_id = customerId;
    CPKeyPersonDetails.key_person_type_ids = [910];
    // console.log(CPKeyPersonDetails);
    dispatch(
      CPUpdateKeyPersonAction({
        keyPersonId: Number(keyPersonId),
        CPKeyPersonDetails,
      })
    ).then((response: any) => {
      // // console.log(response, "--update key person");
      if (response.payload.result === true) {
        navigate(`/key-person-details/${customerId}`);
        setShowSpinner(false);
      } else {
        alert("Something went wrong! Please contact admin");
        setShowSpinner(false);
      }
    });
  };

  return (
    <>
      <NavbarVTX />
      <Header />
      <Container>
        <SectionHeading text="EDIT KEY PERSON" />

        <Form>
          <Row>
            <Col sm={6}>
              <Input
                label="Type"
                name="key_person_type_ids"
                register={register}
                defaultValue={"Key Person"}
                disabled
              />
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <IndividualDesignation
                label="Designation"
                name="designation"
                register={register}
                required
                requiredMessage="designation is required"
                error={errors.designation?.message}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <DisplayBox display={designation === "others"}>
                <Input
                  label={"If Others,"}
                  name={"if_others"}
                  register={register}
                  required={designation === "others"}
                  placeholder="Designation if others"
                  error={errors.if_others?.message}
                />
              </DisplayBox>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Input
                label="First name"
                name="first_name"
                register={register}
                placeholder="Enter first name"
                required
                requiredMessage="first name is required"
                error={errors.first_name?.message}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Input
                label="Middle name"
                name="middle_name"
                register={register}
                placeholder="Enter middle name"
                // required
                // requiredMessage="middle name is required"
                // error={errors.middle_name?.message}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Input
                label="Last name"
                name="last_name"
                register={register}
                placeholder="Enter last name"
                required
                requiredMessage="last name is required"
                error={errors.last_name?.message}
              />
            </Col>
          </Row>
          {/* <Row>
            <Col sm={6}>
              <Input
                label="Mother name"
                name="mother_name"
                register={register}
                placeholder="Enter mother name"
                required
                requiredMessage="mother name is required"
                error={errors.mother_name?.message}
              />
            </Col>
          </Row> */}
          <Row>
            <Col sm={6}>
              <Gender
                label="Gender"
                name="gender"
                register={register}
                // required
                // requiredMessage="gender is required"
                // error={errors.gender?.message}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <PhoneInputWithDropdown
                label="Contact No"
                name="phone"
                register={register}
                required
                isdCodeName="phone_country_code"
                isdCodeRegister={register}
                error={errors.phone?.message}
                // regexPattern={PHONE_REGEX}
                placeholder="Enter Mobile "
              />
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Input
                label="Email"
                name="email"
                register={register}
                required
                requiredMessage="Email is required"
                error={errors.email?.message}
              />
            </Col>
          </Row>
          <Row>
            <Col md={20} className="text-end">
              {showSpinner ? (
                <Spinner animation="border" variant="primary" />
              ) : (
                <Button
                  label="Update & Next"
                  type="submit"
                  onClick={handleSubmit(UpdateCPKeyPerson)}
                ></Button>
              )}
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
};

export default EditKeyIndivdual;
