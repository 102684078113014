interface Address {
  line_1: string;
  line_2: string;
  city: string;
  district: string;
  state: string;
  country_id: number;
  pincode: string;
}

// List of Indian states for detection
const INDIAN_STATES = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
  "Delhi",
  "Puducherry",
  "Ladakh",
  "Jammu and Kashmir",
  "Chandigarh",
  "Lakshadweep",
];

export function parseBankAddress(addressString: string): Address {
  // **Fix missing spaces before state names and pincodes**
  addressString = addressString.replace(/([A-Za-z])(\d{6})/, "$1 $2"); // Space before pincode
  addressString = addressString.replace(/([A-Z]{2,})(\d{6})/, "$1 $2"); // Fix merged state & pincode
  addressString = addressString.replace(/([a-z])([A-Z])/, "$1 $2"); // Space before uppercase words

  // **Normalize spaces and remove unnecessary punctuation**
  addressString = addressString
    .replace(/[,.-]/g, " ")
    .replace(/\s+/g, " ")
    .trim();

  // **Extract Pincode** (Find the last 6-digit number in the string)
  let pincodeMatch = addressString.match(/\b\d{6}\b/);
  let pincode = pincodeMatch ? pincodeMatch[0] : "";

  // **Extract State** (Find if any state name exists in the address)
  let detectedState = "";
  for (const state of INDIAN_STATES) {
    if (addressString.toUpperCase().includes(state.toUpperCase())) {
      detectedState = state;
      break;
    }
  }

  // **Extract City/District** (Assume it's before the state or pincode)
  let city = "";
  let district = "";

  let words = addressString.split(" ");
  let stateIndex = detectedState ? words.indexOf(detectedState) : -1;
  let pincodeIndex = pincode ? words.indexOf(pincode) : -1;

  if (stateIndex > 0) {
    city = words[stateIndex - 1];
  } else if (pincodeIndex > 0) {
    city = words[pincodeIndex - 1];
  }

  district = city; // Assuming district is the same as city

  // **Extract Address Lines** (Everything before city/pincode)
  let cityOrPincodeIndex = Math.max(stateIndex, pincodeIndex);
  let addressParts =
    cityOrPincodeIndex > 0 ? words.slice(0, cityOrPincodeIndex) : words;
  let splitIndex = Math.ceil(addressParts.length / 2);

  let line_1 = addressParts.slice(0, splitIndex).join(" ");
  let line_2 = addressParts.slice(splitIndex).join(" ");

  return {
    line_1,
    line_2,
    city,
    district,
    state: detectedState,
    country_id: 448, // India
    pincode,
  };
}
