import { getSessionId } from "../../../../../../utils/tokenHandlers";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { searchIFSC } from "../../apis/searchIFSC";

export const searchIFSCAction = createAsyncThunk(
  "searchIFSCAction",
  async (ifsc: string) => {
    return searchIFSC(ifsc, getSessionId()).then((response: any) => {
      return response.data;
    });
  }
);
