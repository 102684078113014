import axios from "axios";

export const searchBank = (
  ifsc: string,
  bankAccNo: string,
  sessionId: string | null
) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}business/fetch/bank/ifsc`,
      {
        params: {
          ifsc: `${ifsc}`,
          ac_no: `${bankAccNo}`,
        },
      },
      {
        params: {
          o_ses_id: `${sessionId}`,
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => err);
};
