import React, { useEffect, useState } from "react";
import { Col, Row, Form } from "react-bootstrap";
import { getSessionId } from "../../../utils/tokenHandlers";
import { getGender } from "../../../feature/apis/listOfValues/getGender";

interface GenderProps {
  name: string;
  label: string;
  register: any;
  required?: boolean;
  requiredMessage?: string;
  handleChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  defaultValue?: string;
  error?: string;
  disabled?: boolean;
  colored?: boolean;
}

interface GenderData {
  sr_no: number;
  lov_type: string;
  lov_display_value: string;
  lov_value: string;
  is_active: boolean;
}

const Gender: React.FC<GenderProps> = ({
  name,
  label,
  register,
  required,
  requiredMessage = "This field is required",
  handleChange,
  defaultValue,
  disabled = false,
  error,
  colored = false,
}) => {
  const token = getSessionId();

  const [genderValues, setGenderValues] = useState<GenderData[]>([]);

  useEffect(() => {
    getGender(token).then((response) => {
      setGenderValues(response.result.result);
    });
  }, []);

  return (
    <Form.Group as={Col} className="mb-3">
      <Row className="align-items-center">
        <Form.Label column md={4}>
          <span className="float-end">
            {label}&nbsp;
            <span className="text-danger form-label">
              {required ? "*" : ""}
            </span>
            :
          </span>
        </Form.Label>
        <Col md={8}>
          <Form.Select
            as="select"
            className={`custom-input form-control ${error ? "is-invalid" : ""}`}
            // onChange={handleChange}
            {...register(name, {
              required: required ? requiredMessage : false,
              onChange: (event: any) => {
                if (handleChange) handleChange(event);
              },
            })}
            disabled={disabled}
            defaultValue={defaultValue}
            style={{
              backgroundColor: colored ? "#C2FFC7" : "white",
            }}
          >
            <option value="">Select Option</option>
            {genderValues.map((genderValue, index) => (
              <option key={index} value={genderValue.lov_value}>
                {genderValue.lov_display_value}
              </option>
            ))}
          </Form.Select>
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col md={4}></Col>
        <Col md={8}>{error && <p className="m-0 err-msg">{error}</p>}</Col>
      </Row>
    </Form.Group>
  );
};

export default Gender;
