import { useEffect, useState } from "react";
import Input from "../../../../../components/common/Input";
import ConstitutionType from "../../../../../components/common/dropdowns/ConstitutionType";
import { getBusinessNameFromPan } from "../../../../apis/thirdParty/getBusinessNameFromPan";
import BusinessName from "../../../../../components/common/dropdowns/BusinessName";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../../redux/store";
import { getEnrichApiTokenAction } from "../redux/action/getEnrichApiTokenAction";
import { getEnrichApiToken } from "../../../../apis/thirdParty/getEnrichApiToken";
import { getGstDetails } from "../../../../apis/thirdParty/getGstDetails";
import { createGstAddress } from "../apis/createGstAddress";
import { createGstAddressAction } from "../redux/action/createGstAddressAction";
import { parseGstAddress } from "../../../../../utils/functions/parseGstAddress";
import { getBizNameGSTINMaster } from "../../../../apis/thirdParty/getBizNameGSTINMaster";
import { Row } from "react-bootstrap";
import AlertPopup from "../../../../../components/common/Alert/AlertPopup";
import { DisplayBox } from "../../../../../components/containers/DisplayBox";
import Dropdown from "../../../../../components/common/dropdowns/Dropdown";
import {
  ALPHANUMERIC_REGEX,
  GSTIN_REGEX,
} from "../../../../constants/regexPatterns";

const TaxDetailsIndia: React.FC<any> = ({
  register,
  errors,
  watch,
  setValue,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [businessNames, setBusinessNames] = useState<string[]>([]);
  const [panAPIError, setPanAPIError] = useState(false);
  const [constTypeAPI, setConstTypeAPI] = useState("");
  // const gstinValue = watch("business_gst");
  const [gstinValue, setGSTINValue] = useState("");
  const constitutionType = watch("business_constitution_type");
  const [disableCTBDropdown, setDisableCTBDropdown] = useState(false);

  const [constTypeFetched, setConstTypeFetched] = useState(false);
  const [bizNameFetched, setBizNameFetched] = useState(false);

  // useEffect(() => {
  //   if (gstinValue) {
  //     getEnrichApiToken().then((response) => {
  //       // console.log(response);
  //       const token = response.data.data.token;
  //       const orgId = response.data.data.associatedOrgs[0].organisation.id;

  //       getGstDetails(gstinValue, orgId, token).then((response) => {
  //             const gstData = response.data.data;

  //       });
  //     });
  //   }
  // }, [gstinValue]);

  useEffect(() => {
    if (constTypeAPI) {
      // console.log(constTypeAPI);
      if (constTypeAPI === "Public Limited Company") {
        setValue("business_constitution_type", "PLC");
        setDisableCTBDropdown(true);
      } else if (constTypeAPI === "Partnership") {
        setValue("business_constitution_type", "partnership");
        setDisableCTBDropdown(true);
      } else if (constTypeAPI === "LLC") {
        setValue("business_constitution_type", "LLC");
        setDisableCTBDropdown(true);
      } else if (constTypeAPI === "LLP") {
        setValue("business_constitution_type", "LLP");
        setDisableCTBDropdown(true);
      } else if (constTypeAPI === "Proprietorship") {
        setValue("business_constitution_type", "proprietorship");
        setDisableCTBDropdown(true);
      } else {
        setValue("business_constitution_type", "");
        setDisableCTBDropdown(false);
      }
    }
  }, [constTypeAPI, setValue]);

  useEffect(() => {
    if (gstinValue && gstinValue.length === 15) {
      setBusinessNames([]);
      setPanAPIError(false);
      // console.log(constitutionType);
      getBusinessNameFromPan(gstinValue.substring(2, 12)).then(
        (response: any) => {
          if (response.data.error) {
            // const panAPIErr = response.data.error.data.message;
            setPanAPIError(true);
            // alert("Could not get the Business name");
          } else {
            setPanAPIError(false);
            setBusinessNames((prevNames) => [
              ...prevNames,
              response.data.result.result.name,
            ]);
            setBizNameFetched(true);
          }
        }
      );

      // getEnrichApiToken().then((response) => {
      //   // console.log(response);
      //   const token = response.data.data.token;
      //   const orgId = response.data.data.associatedOrgs[0].organisation.id;

      //   getGstDetails(gstinValue, orgId, token).then((response) => {
      //     const gstData = JSON.parse(response.data.data);
      //     const gstAddress = parseGstAddress(gstData);
      //     dispatch(createGstAddressAction(gstAddress)).then((response) => {
      //       // console.log("GST ADDRESS RESP", response);
      //     });
      //     setBusinessNames((prevNames) => [
      //       ...prevNames,
      //       gstData.lgnm,
      //       gstData.tradeNam,
      //     ]);
      //   });
      // });
      getBizNameGSTINMaster(gstinValue).then((response: any) => {
        if (response.data.result.result) {
          const gstMasterData = response.data.result.result[0];
          if (gstMasterData) {
            // console.log("gst master resp--", gstMasterData);
            setConstTypeAPI(gstMasterData.ctb);
            setBusinessNames((prevNames) => [
              ...prevNames,
              gstMasterData.lgnm,
              gstMasterData.tradeName,
            ]);
            setBizNameFetched(true);
            setConstTypeFetched(true);
            const gstAddress = parseGstAddress(gstMasterData);
            dispatch(createGstAddressAction(gstAddress))
              .then((response) => {
                // alert(response);
              })
              .catch((error) => {
                // alert(error);
              });
          } else {
            setPanAPIError(true);
          }
        } else {
          alert("Something went wrong, Please contact Admin");
        }
      });
    }
  }, [gstinValue]);

  return (
    <>
      <Row>
        <DisplayBox display={panAPIError}>
          <AlertPopup
            variant="warning"
            message="Error while fetching the Business Name from Karza"
          />
        </DisplayBox>
      </Row>
      <Input
        label="TAN"
        type="text"
        name="business_tanno"
        register={register}
        // required
        regexPattern={ALPHANUMERIC_REGEX}
        // requiredMessage="TAN is required"
        // error={errors.business_tanno?.message}
        placeholder="Enter TAN"
      />
      <Input
        label="GSTIN"
        type="text"
        name="business_gst"
        register={register}
        required
        requiredMessage="GSTIN is required"
        error={errors.business_gst?.message}
        placeholder="Enter GSTIN"
        regexPattern={GSTIN_REGEX}
        onChange={(e) => setGSTINValue(e.target.value)}
      />

      {/* <Input
        label="Business Name"
        type="text"
        name="business_name"
        register={register}
        required
        requiredMessage="Business Name is required"
        error={errors.business_name?.message}
      /> */}

      <BusinessName
        label="Business Name"
        name="business_name"
        register={register}
        options={businessNames}
        required
        requiredMessage="Business Name is required"
        error={errors.business_name?.message}
        // colored={bizNameFetched}
        // handleChange={() => {
        //   setBizNameFetched(false);
        // }}
        // placeholder="Select Business Name"
      />
      {/* <Input
        label="Business Name"
        name="business_name"
        register={register}
        required
        // defaultValue={"hello"}
        requiredMessage="Business Name is required"
        error={errors.business_name?.message}
      /> */}

      {/* <Input
        label="Constitution Type"
        type="text"
        name="business_constitution_type"
        register={register}
        required
        requiredMessage="Constitution Type is required"
        error={errors.business_constitution_type?.message}
      /> */}

      <ConstitutionType
        label="Constitution Type"
        name="business_constitution_type"
        // options={[
        //   { label: "Public Limited Company", value: "PLC" },
        //   { label: "Private Limited Company", value: "LLP" },
        //   { label: "Limited Liability Company", value: "LLC" },
        //   { label: "Partnership", value: "partnership" },
        //   { label: "Proprietorship", value: "proprietorship" },
        //   { label: "Others", value: "others" },
        // ]}
        register={register}
        disabled={disableCTBDropdown}
        required
        requiredMessage="Constitution Type is required"
        error={errors.business_constitution_type?.message}
      />
      {constitutionType === "others" && (
        <Input
          label={"If Others"}
          name={"business_constitution_type_other"}
          register={register}
          placeholder="Enter other Constitution Type"
          required
          requiredMessage="Other Constitution is required"
        />
      )}
    </>
  );
};

export default TaxDetailsIndia;
