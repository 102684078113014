import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import "../css/common.css";

interface TextAreaProps {
  label: string;
  name: string;
  register: any;
  required?: boolean;
  requiredMessage?: string;
  regexPattern?: RegExp;
  regexMessage?: string;
  minLength?: number;
  maxLength?: number;
  defaultValue?: string;
  error?: string;
  disabled?: boolean;
  placeholder?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  colored?: boolean;
}

const TextArea: React.FC<TextAreaProps> = ({
  label,
  name,
  register,
  required = false,
  requiredMessage = "This field is required",
  minLength,
  maxLength,
  defaultValue,
  error,
  regexPattern,
  regexMessage = "Invalid value for Nature of Activity (special characters are not allowed)",
  disabled = false,
  placeholder,
  onChange,
  colored = false,
}) => {
  return (
    <Form.Group as={Col} className="align-items-center">
      <Row className="align-items-center">
        <Form.Label htmlFor={name} column md={4}>
          <span className="float-end">
            {label}&nbsp;
            <span className="text-danger form-label">
              {required ? "*" : ""}
            </span>
            :
          </span>
        </Form.Label>
        <Col md={8}>
          <Form.Control
            as="textarea"
            className={`custom-input ${error ? "is-invalid" : ""}`}
            style={{ backgroundColor: colored ? "#C2FFC7" : "" }} // Apply color conditionally
            {...register(name, {
              required: required ? requiredMessage : false,
              pattern: {
                value: regexPattern,
                message: regexMessage,
              },
              minLength: {
                value: minLength,
                message: `Minimum length is ${minLength} characters`,
              },
              maxLength: {
                value: maxLength,
                message: `Maximum length is ${maxLength} characters`,
              },
            })}
            disabled={disabled}
            placeholder={placeholder}
            defaultValue={defaultValue}
            onChange={onChange}
          />
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col md={4}></Col>
        <Col md={8}>{error && <p className="m-0 err-msg">{error}</p>}</Col>
      </Row>
    </Form.Group>
  );
};

export default TextArea;
