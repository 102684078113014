import axios from "axios";

export const getLocalBank = (bankId: number, sessionId: string | null) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api/read/one/tradexwbench.customer_settlement_bank/${bankId}`,
      {
        params: {
          filter: `[]`,
          query:
            "{id, is_default_bank,customer_id,bank_name,branch_name,bank_account_name,bank_account_number,re_enter_bank_account_number,ifsc_code,iban,swift_code,account_type,bank_official_name,bank_official_email,phone_country_code,bank_official_phone,bank_official_mobile,undertaking_confirm,bank_address_line_1,bank_address_line_2,bank_address_city,bank_address_district,bank_address_state,bank_address_pincode,bank_address_country_id,customer_settlement_correspondent_bank_ids,currency_id, other_account_type,api_line_1_flag, api_line_2_flag, api_bank_name_flag, api_branch_name_flag, api_account_name_flag, api_city_flag,api_district_flag, api_state_flag, api_country_flag,postal_pincode_api_success}",
        },
      },
      {
        params: {
          o_ses_id: `${sessionId}`,
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => err);
};
