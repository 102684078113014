import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSessionId } from "../../../../../../utils/tokenHandlers";
import { getAuthSignatories } from "../../apis/getAuthSignatories";

export const getAuthSignatoriesAction = createAsyncThunk(
  "getAuthSignatoriesAction",
  async (appId: number) => {
    return getAuthSignatories(appId, getSessionId()).then((response: any) => {
      return response.data;
    });
  }
);
