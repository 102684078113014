import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSessionId } from "../../../../../../utils/tokenHandlers";
import { sendESign } from "../../apis/sendESign";

export const sendESignAction = createAsyncThunk(
  "sendESignAction",
  async ({ appId, signatories }: { appId: number; signatories: any }) => {
    return sendESign(appId, signatories, getSessionId()).then(
      (response: any) => {
        return response.data;
      }
    );
  }
);
