import { Container, Row, Col, Form, Alert, Spinner } from "react-bootstrap";
import { useForm, SubmitHandler } from "react-hook-form";
import { useEffect, useState } from "react";
import Input from "../../../../../components/common/Input";
import DatePicker from "../../../../../components/common/DatePicker";
import Button from "../../../../../components/common/Button";
import {
  AddressDetailsType,
  BusinessDetailsType,
} from "./types/BusinessDetailsType";
import Header from "../../../../../components/common/Header";
import SectionHeading from "../../../../../components/common/SectionHeading";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateAppAction } from "../redux/action/updateAppAction";
import { AppDispatch, RootState } from "../../../../../redux/store";
import ListingInfoDropdown from "../../../../../components/common/ListingInfoDropdown";
import AddressDetails from "../components/AddressDetails";
import { DisplayBox } from "../../../../../components/containers/DisplayBox";
import RegisterWith from "../../../../../components/common/dropdowns/RegisterWith";
import NatureOfBusiness from "../../../../../components/common/dropdowns/NatureOfBusiness";
import BusinessUnitLocation from "../../../../../components/common/dropdowns/BusinessUnitLocation";
import BusinessStatusHolder from "../../../../../components/common/dropdowns/BusinessStatusHolder";
import DefaultBillingAddress from "../../../../../components/common/dropdowns/DefaultBillingAddress";
import DefaultShippingAddress from "../../../../../components/common/dropdowns/DefaultShippingAddress";
import { getAppAction } from "../redux/action/getAppAction";
import {
  getParticipationRole,
  getUserRole,
} from "../../../../../utils/tokenHandlers";
import AlertPopup from "../../../../../components/common/Alert/AlertPopup";
import NavbarVTX from "../../../../../components/common/Navbar";
import {
  ALPHANUMERIC_REGEX,
  CKYC_REGEX,
  LEI_REGEX,
  NATURE_ACTIVITY_REGEX,
  WEBSITE_REGEX,
} from "../../../../constants/regexPatterns";
import CPBusinessDetailsFields from "../components/CPBusinessDetailsFields";
import TextArea from "../../../../../components/common/Textarea";
import { getAddressFromCinAction } from "../redux/action/getAddressFromCinAction";
import { parseAddress } from "../../../../../utils/functions/parseAddress";
import { createAddressAction } from "../redux/action/createAddressAction";
import { convertDateFormat } from "../../../../../utils/functions/convertDateFormat";
import { getAllAddressAction } from "../redux/action/getAllAddressAction";
import { getRegisteredAddress } from "../../../../verificationStage/redux/action/getRegisteredAddress";
import { updateAddressAction } from "../redux/action/updateAddressAction";
import { updateRegAddrCIN } from "../redux/action/updateRegAddrCIN";
import RadioButton from "../../../../../components/common/RadioButton";
import SendBackPdfBtn from "../../../../../components/common/sendBackPdfBtn";
import Dropdown from "../../../../../components/common/dropdowns/Dropdown";
import { getBusinessDetailsFromCIN } from "../apis/getBusinessDetailsFromCIN";
import { getBusinessDetailsFromCINAction } from "../redux/action/getBusinessDetailsFromCINAction";

const OpsBusinessDeatilsPage: React.FC = () => {
  let { appId } = useParams();
  let customerId = Number(appId);
  const navigate = useNavigate();
  // const participationType = getParticipationRole();
  // // console.log(participationType,"hii")
  const userRole = getUserRole();
  const state = useSelector((state: RootState) => state.opsPpIndApp.getApp);
  const isCounterParty = state.data.is_counterparty;
  let stage = state.data.stage;
  let constType = state.data.business_constitution_type;

  const updateState = useSelector(
    (state: RootState) => state.opsPpIndApp.updateApp
  );

  const allAddressSelector = useSelector(
    (state: RootState) => state.opsPpIndApp.getAllAddress.data
  );

  const cinFromPanSelector = useSelector(
    (state: RootState) => state.opsPpIndApp.cinFromPan
  );
  const dispatch = useDispatch<AppDispatch>();

  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  const showErrorAlert =
    updateState.data.result && updateState.data.result.error;

  const errorMessage = showErrorAlert
    ? updateState.data.result.error.data.message
    : "";

  // // console.log(appId);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    reset,
    formState: { errors },
  } = useForm<BusinessDetailsType>({});

  const {
    register: register1,
    handleSubmit: handleSubmit1,
    watch: watch1,
    setValue: setValue1,
    control: control1,
    reset: reset1,
    formState: { errors: errors1 },
  } = useForm<AddressDetailsType>({});

  const [defaultSelectedListing, setDefaultSelectedListing] = useState<
    number[]
  >([]);
  const cinValue = watch("business_cin_no");
  const [IECNumber, setIECNumber] = useState("");
  const [LEINumber, setLEINumber] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [allowListingSelection, setAllowListingSelection] = useState(true);
  const [listingDefaultValue, setListingDefaultValue] = useState<number[]>([]);

  const [cinFetched, setCinFetched] = useState(false);
  const [leiFetched, setLeiFetched] = useState(false);
  const [iecFetched, setIecFetched] = useState(false);
  const [nActFetched, setNActFetched] = useState(false);
  const [websiteFetched, setWebsiteFetched] = useState(false);
  const [regPlaceFetched, setRegPlaceFetched] = useState(false);
  const [regDateFetched, setRegDateFetched] = useState(false);
  useEffect(() => {
    if (cinValue) {
      const firstCharacter = cinValue.charAt(0);
      if (firstCharacter === "U") {
        // console.warn("CIN starts with U");
        setAllowListingSelection(false);
        // setValue("business_listing_info_ids", [962]);
        setListingDefaultValue([962]);
      } else if (firstCharacter === "L") {
        // console.warn("CIN starts with L");
        setAllowListingSelection(true);
        setListingDefaultValue([]);
      }
    }
  }, [cinValue]);

  const handleCinInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    // console.log(newValue);
    setCinFetched(false);

    // Check if manually typed characters hit 21 and trigger the useEffect
    if (newValue.length === 21) {
      // console.log(newValue);
      dispatch(getBusinessDetailsFromCINAction(newValue)).then(
        (businessDetailsFields) => {
          // console.log(businessDetailsFields.payload);
          let fetchedFields =
            businessDetailsFields.payload?.result?.statutoryRegistration;
          const IEC_number = fetchedFields?.iec[0]?.iec;
          const LEI_number = fetchedFields?.lei[0]?.lei;
          // console.log(
          //   fetchedFields?.exchangeInformation?.bseSecurity[0]?.srcWebsites[0]
          // );
          const companyWebsite =
            fetchedFields?.exchangeInformation?.bseSecurity[0]?.srcWebsites[0];
          const natureofActivity = fetchedFields?.mca[0]?.activityGroup_;
          dispatch(
            updateAppAction({
              customerId: customerId,
              updateCustomerData: {
                business_iecno: IEC_number ? IEC_number : "",
                business_legal_entity_identifier: LEI_number ? LEI_number : "",
                business_website: companyWebsite ? companyWebsite : "",
                business_nature_activity: natureofActivity
                  ? natureofActivity
                  : "",
                api_lei_flag: LEI_number ? true : false,
                api_nature_activity_flag: natureofActivity ? true : false,
                api_iecno_flag: IEC_number ? true : false,
              },
            })
          );
          // console.log(
          //   businessDetailsFields.payload?.result?.statutoryRegistration,
          //   "here"
          // );
          // console.log(fetchedFields?.iec[0]?.iec, "IEC");
          // console.log(fetchedFields?.lei[0]?.lei, "LEI");
          // console.log(fetchedFields?.tan[0]?.tan, "tan");
          // return;
        }
      );

      dispatch(
        getAddressFromCinAction({
          cin: newValue,
          customerId: customerId,
        })
      ).then((addressResponse) => {
        try {
          if (addressResponse.payload) {
            // console.log(addressResponse.payload, "---resp");
            let address =
              addressResponse.payload.result.result.Registered_Address;
            let parseAddressDetails = parseAddress(address);
            let addressDetailsData = {
              customer_id: customerId,
              type: "Registered",
              ...parseAddressDetails,
              api_line_1_flag: true,
              api_line_2_flag: true,
              postal_pincode_api_success: true,
              api_city_flag: true,
              api_district_flag: true,
              api_state_flag: true,
              api_country_flag: true,
            };
            // console.warn("parsed addr-->", addressDetailsData);
            dispatch(createAddressAction(addressDetailsData)).then((res) => {
              dispatch(
                updateAppAction({
                  customerId: customerId,
                  updateCustomerData: {
                    business_registration_place:
                      addressResponse.payload.result.result.ROC_Code,
                    business_registration_date: convertDateFormat(
                      addressResponse.payload.result.result
                        .Date_of_Incorporation
                    ),
                    api_business_registration_date_flag: addressResponse.payload
                      .result.result.Date_of_Incorporation
                      ? true
                      : false,
                    api_business_registration_place_flag: addressResponse
                      .payload.result.result.ROC_Code
                      ? true
                      : false,
                  },
                })
              ).then((response: any) => {
                // console.log(response, "address updated");
                if (response.payload.result === true) {
                  // console.warn("true");
                }
              });
            });
            dispatch(
              updateAppAction({
                customerId: customerId,
                updateCustomerData: {
                  is_cin_captured: true,
                  business_cin_no: newValue,
                  api_cin_flag: false,
                  business_registration_place:
                    addressResponse.payload.result.result.ROC_Code,
                  business_registration_date: convertDateFormat(
                    addressResponse.payload.result.result.Date_of_Incorporation
                  ),
                },
              })
            ).then((resp) => {
              dispatch(getAppAction(customerId));
              dispatch(getAllAddressAction(customerId));
            });

            dispatch(getRegisteredAddress(customerId)).then((resp: any) => {
              // console.warn(resp.payload);
              if (resp.payload.data.result.count === 1) {
                // console.warn(resp.payload.data.result.count);
                const addressId = resp.payload.data.result.result[0].id;
                // console.warn(addressId);
                dispatch(
                  updateRegAddrCIN({
                    addressId,
                    addressDetails: addressDetailsData,
                  })
                ).then((response: any) => {
                  // console.log("resp--->", response);
                  dispatch(getAllAddressAction(customerId));
                });
              }
            });
          }
        } catch (err) {
          // console.log("something went wrong", err);
        }
      });
    }
  };

  useEffect(() => {
    // setValue("business_registered_with", "MCA");
    dispatch(getAppAction(customerId));
  }, [dispatch, customerId]);

  // useEffect(() => {
  //   if (cinValue && cinValue.length === 81) {
  //     // console.log("cin value got");
  //     dispatch(
  //       getAddressFromCinAction({
  //         cin: cinValue,
  //         customerId: customerId,
  //       })
  //     ).then((addressResponse) => {
  //       try {
  //         if (addressResponse.payload) {
  //           // console.log(addressResponse.payload, "---resp");
  //           let address =
  //             addressResponse.payload.result.result.Registered_Address;
  //           let parseAddressDetails = parseAddress(address);
  //           let addressDetailsData = {
  //             customer_id: appId,
  //             type: "Registered",
  //             ...parseAddressDetails,
  //           };
  //           // console.warn("parsed addr-->", addressDetailsData);
  //           // dispatch(createAddressAction(addressDetailsData)).then((res) => {
  //           //   dispatch(
  //           //     updateAppAction({
  //           //       customerId: customerId,
  //           //       updateCustomerData: {
  //           //         business_registration_place:
  //           //           addressResponse.payload.result.result.ROC_Code,
  //           //         business_registration_date: convertDateFormat(
  //           //           addressResponse.payload.result.result.Date_of_Incorporation
  //           //         ),
  //           //       },
  //           //     })
  //           //   ).then((response: any) => {
  //           //     // console.log(response, "address updated");
  //           //     if (response.payload.result === true) {
  //           //       // console.warn("true");

  //           //     }
  //           //   });
  //           // });
  //           dispatch(getRegisteredAddress(customerId)).then((resp: any) => {
  //             // console.warn(resp.payload);
  //             if (resp.payload.data.result.count === 1) {
  //               // console.warn(resp.payload.data.result.count);
  //               const addressId = resp.payload.data.result.result[0].id;
  //               // console.warn(addressId);
  //               dispatch(
  //                 updateRegAddrCIN({
  //                   addressId,
  //                   addressDetails: addressDetailsData,
  //                 })
  //               ).then((response: any) => {
  //                 // console.log("resp--->", response);
  //                 dispatch(getAllAddressAction(customerId));
  //               });
  //             }
  //           });
  //         }
  //       } catch (err) {
  //         // console.log("something went wrong", err);
  //       }
  //     });
  //   }
  // }, [cinValue]);

  useEffect(() => {
    // console.log("App Data ----> ", state.data.stage);

    reset({
      business_registered_with: "MCA",
      business_registration_place: state.data.business_registration_place,
      business_listing_info_ids: state.data.business_listing_info_ids,
      business_cin_no: state.data.business_cin_no,
      business_registration_date: state.data.business_registration_date,
      business_commencement_date: state.data.business_commencement_date,
      business_legal_entity_identifier:
        state.data.business_legal_entity_identifier,
      business_website: state.data.business_website,
      business_nature: state.data.business_nature,
      business_iecno: state.data.business_iecno,
      business_nature_activity: state.data.business_nature_activity,
      business_unit_location: state.data.business_unit_location,
      business_statusholder: state.data.business_statusholder,
      business_ckycno: state.data.business_ckycno,
      business_no_of_years_of_relation_with_cp:
        state.data.business_no_of_years_of_relation_with_cp,
      referred_by_vendor_dealer_id: state.data.referred_by_vendor_dealer_id,
      business_is_cp_related_party: state.data.business_is_cp_related_party,
      is_msme: state.data.is_msme === true ? "true" : "false",
    });
    setDefaultSelectedListing(state.data.business_listing_info_ids);
    state.data.api_cin_flag == true
      ? setCinFetched(true)
      : setCinFetched(false);
    state.data.api_lei_flag == true
      ? setLeiFetched(true)
      : setLeiFetched(false);
    state.data.api_nature_activity_flag == true
      ? setNActFetched(true)
      : setNActFetched(false);
    state.data.api_iecno_flag == true
      ? setIecFetched(true)
      : setIecFetched(false);
    state.data.api_website_flag == true
      ? setWebsiteFetched(true)
      : setWebsiteFetched(false);
    state.data.api_business_registration_date_flag == true
      ? setRegDateFetched(true)
      : setRegDateFetched(false);
    state.data.api_business_registration_place_flag == true
      ? setRegPlaceFetched(true)
      : setRegPlaceFetched(false);

    reset1({
      business_billing_address_type_id:
        state.data.business_billing_address_type_id,
      business_shipping_address_type_id:
        state.data.business_shipping_address_type_id,
    });
  }, [state, reset]);

  useEffect(() => {
    setTimeout(() => {
      setValue1(
        "business_billing_address_type_id",
        state.data.business_billing_address_type_id
      );
      setValue1(
        "business_shipping_address_type_id",
        state.data.business_shipping_address_type_id
      );
      setValue("business_statusholder", state.data.business_statusholder);
    }, 500);
  }, [setValue1, state]);

  useEffect(() => {
    if (!allowListingSelection) {
      if (state.data.business_listing_info_ids) {
        setValue("business_listing_info_ids", [962]);
      }
    }
  });

  useEffect(() => {
    // console.log("Inside cinFromPanEffect");
    // console.log("CIN reducer", cinFromPanSelector);
    if (cinFromPanSelector.loading === false && cinFromPanSelector.data) {
      if (
        cinFromPanSelector.data.status === 101 &&
        cinFromPanSelector.data.error == null
      ) {
        // console.log(cinFromPanSelector);
      }
    }
  }, [cinFromPanSelector]);

  // useEffect(() => {
  //   // console.log("GST NUmber", state.data.business_gst);
  //   dispatch(getCinFromPanAction(state.data.business_gst));
  // }, [state]);

  const [selectedValues, setSelectedValues] = useState<number[]>([]);

  const handleSelectedValuesChange = (newSelectedValues: number[]) => {
    setSelectedValues(newSelectedValues);
  };

  const handleBusinessDetailsSubmit: SubmitHandler<
    BusinessDetailsType
  > = async (updateCustomerData) => {
    if (stage === "New / Draft" || stage === "Sent back to correction") {
      setShowSuccessAlert(false);
      if (allowListingSelection) {
        if (selectedValues.length === 0) {
          // If empty, set business_listing_info_ids to state.data.business_listing_info_ids
          updateCustomerData.business_listing_info_ids =
            state.data.business_listing_info_ids;
        } else {
          // If not empty, set business_listing_info_ids to selectedValues
          updateCustomerData.business_listing_info_ids = selectedValues;
        }
      } else {
        updateCustomerData.business_listing_info_ids = [962];
      }
      // console.log(updateCustomerData, "here");
      if (updateCustomerData.is_msme === "true") {
        updateCustomerData.is_msme = true;
      } else {
        updateCustomerData.is_msme = false;
      }
      updateCustomerData.api_cin_flag = cinFetched;
      updateCustomerData.api_lei_flag = leiFetched;
      updateCustomerData.api_nature_activity_flag = nActFetched;
      updateCustomerData.api_iecno_flag = iecFetched;
      updateCustomerData.api_website_flag = websiteFetched;
      updateCustomerData.api_business_registration_date_flag = regDateFetched;
      updateCustomerData.api_business_registration_place_flag = regPlaceFetched;

      // console.log("Business Details", updateCustomerData);
      dispatch(updateAppAction({ customerId, updateCustomerData })).then(
        (response) => {
          if (response.payload.result) {
            setShowSuccessAlert(true);
          }
        }
      );
    } else {
      alert("Cannot edit application at this stage!");
    }
  };

  const handleAddressDetailsSubmit: SubmitHandler<any> = (
    addressBillingDetails
  ) => {
    if (stage === "New / Draft" || stage === "Sent back to correction") {
      // console.log(addressBillingDetails);
      addressBillingDetails.business_billing_address_type_id = Number(
        addressBillingDetails.business_billing_address_type_id
      );
      addressBillingDetails.business_shipping_address_type_id = Number(
        addressBillingDetails.business_shipping_address_type_id
      );
      setShowSpinner(true);

      dispatch(getAppAction(customerId)).then((resp: any) => {
        // // console.log("resp---", resp);
        if (resp.payload.result.business_nature_activity === null) {
          // // console.log("yuppppppppp");
          alert("Please Save Business Details to continue!");
          setShowSpinner(false);
        } else {
          dispatch(
            updateAppAction({
              customerId,
              updateCustomerData: addressBillingDetails,
            })
          ).then((response) => {
            if (response.payload.result.error) {
              alert(response.payload.result.error.data.message);
              setShowSpinner(false);
            } else {
              navigate(`/currency-bank-details/${customerId}`);
            }
          });
        }
      });
    } else {
      alert("Cannot edit application at this stage!");
    }
  };

  return (
    <>
      <NavbarVTX></NavbarVTX>
      <Header />
      {/* <p>{isCounterParty}</p> */}
      <DisplayBox display={showSuccessAlert}>
        <AlertPopup
          variant="success"
          message="Business Details Updated Successfully"
        />
      </DisplayBox>

      <Container>
        <Row>
          <Col md="12">
            <SectionHeading text="BUSINESS DETAILS" />
          </Col>
          <Col className="text-end">
            <SendBackPdfBtn />
          </Col>
        </Row>
        {/* <DevTool control={control} placement="top-left" /> */}
        <Form onSubmit={handleSubmit(handleBusinessDetailsSubmit)}>
          <Row className="justify-content-center">
            <Col sm={6}>
              <RegisterWith
                label="Business Registration"
                name="business_registered_with"
                register={register}
                // required
                // requiredMessage="Business Registration is required"
                error={errors.business_registered_with?.message}
                // defaultValue="MCA"
                disabled
              />
            </Col>
            <Col sm={6}>
              <Input
                label="Place of Incorporation"
                name="business_registration_place"
                register={register}
                required
                // regexPattern={ALPHANUMERIC_REGEX}
                requiredMessage="Registration place is required"
                // disabled={
                //   !(
                //     userRole == "maker" ||
                //     (userRole == "guest" && stage == "New / Draft")
                //   )
                // }
                //defaultValue={state.data.business_registration_place}
                error={errors.business_registration_place?.message}
                placeholder="Enter place of incorporation"
                colored={regPlaceFetched}
                onChange={() => {
                  setRegPlaceFetched(false);
                }}
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm={6} className="d-flex align-items-center">
              <Input
                label="CIN"
                name="business_cin_no"
                register={register}
                required
                requiredMessage="Cin is required"
                // disabled={
                //   !(
                //     userRole == "maker" ||
                //     (userRole == "guest" && stage == "New / Draft")
                //   )
                // }
                //defaultValue={state.data.business_cin_no}
                // disabled={
                //   !(
                //     userRole == "maker" ||
                //     (userRole == "guest" && stage == "New / Draft")
                //   )
                // }
                error={errors.business_cin_no?.message}
                onChange={handleCinInputChange}
                placeholder="Enter CIN"
                colored={cinFetched}
              />
            </Col>
            <Col sm={6}>
              <DatePicker
                label="Date of Incorporation"
                name="business_registration_date"
                register={register}
                required
                isDateOfBirth
                requiredMessage="date is required"
                //defaultValue={state.data.business_registration_date}
                error={errors.business_registration_date?.message}
                // disabled={
                //   !(
                //     userRole == "maker" ||
                //     (userRole == "guest" && stage == "New / Draft")
                //   )
                // }
                colored={regDateFetched}
                handleChange={() => {
                  setRegDateFetched(false);
                }}
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm={6} className="d-flex align-items-center">
              {allowListingSelection ? (
                defaultSelectedListing && (
                  <ListingInfoDropdown
                    selectedValues={defaultSelectedListing}
                    onSelectedValuesChange={handleSelectedValuesChange}
                    name="business_listing_info_ids"
                    label="Listing Information"
                    required
                    register={register}
                    setValue={setValue}
                    watch={watch}
                    //defaultValue={state.data.business_listing_info_ids}
                    error={errors.business_listing_info_ids?.message}
                    // disabled={
                    //   !(
                    //     userRole == "maker" ||
                    //     (userRole == "guest" && stage == "New / Draft")
                    //   )
                    // }
                  />
                )
              ) : (
                // <Input
                //   label="Listing Information"
                //   name="business_listing_info_ids"
                //   register={register}
                //   // required
                //   // requiredMessage="Listing Information is required"
                //   // error={errors.business_listing_info_ids?.message}
                //   placeholder="Unlisted"
                //   defaultValue="Unlisted"
                //   disabled
                // />
                <Dropdown
                  name={"business_listing_info_ids"}
                  label={"Listing Information"}
                  options={[{ label: "Unlisted", value: [962] }]}
                  register={register}
                  // defaultValue={listingDefaultValue}
                  disabled
                />
              )}
            </Col>
            <Col sm={6}>
              <DatePicker
                label="Date of Commencement"
                name="business_commencement_date"
                register={register}
                isDateOfBirth
                // required
                // requiredMessage="date is required"
                //defaultValue={state.data.business_commencement_date}
                error={errors.business_commencement_date?.message}
                // disabled={
                //   !(
                //     userRole == "maker" ||
                //     (userRole == "guest" && stage == "New / Draft")
                //   )
                // }
                // colored={}
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm={6} className="d-flex align-items-center">
              <Input
                label="Legal entity identifier"
                name="business_legal_entity_identifier"
                register={register}
                regexPattern={LEI_REGEX}
                placeholder="Enter LEI"
                // required
                // requiredMessage="lagel entity is required"
                // defaultValue={state.data.business_legal_entity_identifier}
                error={errors.business_legal_entity_identifier?.message}
                // disabled={
                //   !(
                //     userRole == "maker" ||
                //     (userRole == "guest" && stage == "New / Draft")
                //   )
                // }
                colored={leiFetched}
                onChange={() => {
                  setLeiFetched(false);
                }}
              />
            </Col>
            <Col sm={6}>
              <Input
                label="Website"
                name="business_website"
                register={register}
                regexPattern={WEBSITE_REGEX}
                placeholder="Enter website"
                // required
                // requiredMessage="website is required"
                // defaultValue={state.data.business_website}
                error={errors.business_website?.message}
                // disabled={
                //   !(
                //     userRole == "maker" ||
                //     (userRole == "guest" && stage == "New / Draft")
                //   )
                // }
                colored={websiteFetched}
                onChange={() => {
                  setWebsiteFetched(false);
                }}
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm={6} className="d-flex align-items-center">
              <NatureOfBusiness
                label="Nature of Business"
                name="business_nature"
                register={register}
                required
                requiredMessage="Nature of business is required"
                // defaultValue={state.data.business_nature}
                error={errors.business_nature?.message}
                // disabled={
                //   !(
                //     userRole == "maker" ||
                //     (userRole == "guest" && stage == "New / Draft")
                //   )
                // }
              />
            </Col>
            <Col sm={6}>
              <Input
                label="IEC"
                name="business_iecno"
                register={register}
                required
                regexPattern={ALPHANUMERIC_REGEX}
                requiredMessage="IEC is required"
                // defaultValue={state.data.business_iecno}
                error={errors.business_iecno?.message}
                placeholder="Enter IEC number"
                // disabled={
                //   !(
                //     userRole == "maker" ||
                //     (userRole == "guest" && stage == "New / Draft")
                //   )
                // }
                colored={iecFetched}
                onChange={() => {
                  setIecFetched(false);
                }}
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm={6} className="d-flex align-items-center">
              <TextArea
                label="Nature of Activity"
                name="business_nature_activity"
                register={register}
                required
                regexPattern={NATURE_ACTIVITY_REGEX}
                requiredMessage="Nature of activity is required"
                // defaultValue={state.data.business_nature_activity}
                error={errors.business_nature_activity?.message}
                placeholder="Enter nature of activity"
                // disabled={
                //   !(
                //     userRole == "maker" ||
                //     (userRole == "guest" && stage == "New / Draft")
                //   )
                // }
                colored={nActFetched}
                onChange={() => {
                  setNActFetched(false);
                }}
              />
            </Col>
            <Col sm={6}>
              <BusinessUnitLocation
                label="Business Unit Location"
                name="business_unit_location"
                register={register}
                required
                // defaultValue={state.data.business_unit_location}
                error={errors.business_unit_location?.message}
                // disabled={
                //   !(
                //     userRole == "maker" ||
                //     (userRole == "guest" && stage == "New / Draft")
                //   )
                // }
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm={6} className="d-flex align-items-center">
              <Input
                label="CKYC No"
                name="business_ckycno"
                register={register}
                regexPattern={CKYC_REGEX}
                // required
                // requiredMessage="Ckyc is required"
                // defaultValue={state.data.business_ckycno}
                error={errors.business_ckycno?.message}
                placeholder="Enter CKYC"
                // disabled={
                //   !(
                //     userRole == "maker" ||
                //     (userRole == "guest" && stage == "New / Draft")
                //   )
                // }
              />
            </Col>
            <Col sm={6}>
              <DisplayBox
                display={state.data.business_participant_type == "Seller"}
              >
                <BusinessStatusHolder
                  label="Star Export House"
                  name="business_statusholder"
                  register={register}
                  required
                  // defaultValue={state.data.business_statusholder}
                  error={errors.business_statusholder?.message}
                  // disabled={
                  //   state.data.business_participant_type == "Seller"
                  // }
                  // disabled={
                  //   !(
                  //     userRole == "maker" ||
                  //     (userRole == "guest" && stage == "New / Draft")
                  //   )
                  // }
                />
              </DisplayBox>
            </Col>
          </Row>
          <Row md={12}>
            <Col sm={6}>
              <DisplayBox
                display={
                  state.data.business_participant_type === "Seller" &&
                  state.data.is_counterparty === false
                }
              >
                {/* <p>true</p> */}
                <RadioButton
                  label="Is MSME ?"
                  name="is_msme"
                  register={register}
                  options={[
                    { label: "Yes", value: "true" },
                    { label: "No", value: "false" },
                  ]}
                  required
                  requiredMessage="Is MSME selection is required"
                  error={errors.is_msme?.message}
                />
              </DisplayBox>
            </Col>
          </Row>
          {state.data.is_counterparty === true && (
            <CPBusinessDetailsFields register={register} errors={errors} />
          )}
          <DisplayBox display={showErrorAlert}>
            <p>{errorMessage}</p>
          </DisplayBox>
          <Row>
            <Col md={20} className="text-end">
              <Button label="Save Details" type="submit"></Button>
            </Col>
          </Row>
        </Form>
        <AddressDetails />
        <Form onSubmit={handleSubmit1(handleAddressDetailsSubmit)}>
          <Row className="">
            <Col sm={6}>
              {isCounterParty === true ? (
                <DefaultShippingAddress
                  label="Default Billing Address"
                  name="business_billing_address_type_id"
                  register={register1}
                  // required={!isCounterParty}
                  //defaultValue={state.data.business_shipping_address_type_id}
                  error={errors1.business_billing_address_type_id?.message}
                  requiredMessage="Default Billing Address is required"
                />
              ) : (
                <DefaultBillingAddress
                  label="Default Billing Address"
                  name="business_billing_address_type_id"
                  register={register1}
                  required
                  //defaultValue={state.data.business_billing_address_type_id}
                  requiredMessage="Default Billing Address is required"
                  error={errors1.business_billing_address_type_id?.message}
                />
              )}
              {}
            </Col>
            <Col sm={6}>
              <DefaultShippingAddress
                label="Default Shipping Address"
                name="business_shipping_address_type_id"
                register={register1}
                required={!isCounterParty}
                //defaultValue={state.data.business_shipping_address_type_id}
                error={errors1.business_shipping_address_type_id?.message}
                requiredMessage="Default Shipping Address is required"
              />
            </Col>
          </Row>
          {/* <Row className="">
            <Col sm={6}>
              <DefaultShippingAddress
                label="Default Shipping Address"
                name="business_shipping_address_type_id"
                register={register1}
                required
                //defaultValue={state.data.business_shipping_address_type_id}
                //error={errors.business_shipping_address_type_id?.message}
              />
            </Col>
          </Row> */}
          <Row>
            <Col md={20} className="text-end">
              {showSpinner ? (
                <Spinner animation="border" variant="primary" />
              ) : (
                <Button label="Save & Next" type="submit"></Button>
              )}
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
};

export default OpsBusinessDeatilsPage;
