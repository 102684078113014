import { getSessionId } from "../../../../../../utils/tokenHandlers";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { searchAddressByPincode } from "../../apis/searchAddressByPincode";

export const searchAddressByPincodeAction = createAsyncThunk(
  "searchAddressByPincodeAction",
  async (pinCode: string) => {
    return searchAddressByPincode(pinCode, getSessionId()).then(
      (response: any) => {
        return response.data;
      }
    );
  }
);
