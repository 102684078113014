import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import "../css/common.css";
import { MdSearch } from "react-icons/md";

interface InputProps {
  label: string;
  name: string;
  type?: string;
  register: any; // Use proper type for register function
  required?: boolean;
  requiredMessage?: string;
  regexPattern?: RegExp;
  regexMessage?: string;
  min?: number;
  max?: number;
  minLength?: number;
  maxLength?: number;
  defaultValue?: string | number; // Add a default value prop
  error?: any; // Add error prop
  disabled?: boolean;
  placeholder?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setselectedValue?: string;
  link?: {
    text: string;
    onClick: () => void;
    className?: string;
  };
  colored?: boolean; // New prop to change input color
}

const Input: React.FC<InputProps> = ({
  label,
  name,
  type = "text",
  register,
  required = false,
  requiredMessage = "This field is required",
  regexPattern,
  regexMessage = "Invalid Format",
  min,
  max,
  minLength,
  maxLength,
  defaultValue, // Pass the default value to the input field
  error,
  disabled = false,
  placeholder,
  value,
  onChange,
  setselectedValue,
  link,
  colored = false, // Default is false
}) => {
  return (
    <Form.Group as={Col} className="align-items-center">
      <Row className="align-items-center">
        <Form.Label htmlFor={name} column md={4}>
          <span className="float-end">
            {label}&nbsp;
            <span className="text-danger form-label">
              {required ? "*" : ""}
            </span>
            :
          </span>
        </Form.Label>
        <Col md={8}>
          <Form.Control
            type={type}
            className={`custom-input ${error ? "is-invalid" : ""}`}
            style={{ backgroundColor: colored ? "#C2FFC7" : "" }} // Apply color conditionally
            {...register(name, {
              required: required ? requiredMessage : false,
              pattern: {
                value: regexPattern,
                message: regexMessage,
              },
              minLength: {
                value: minLength,
                message: `Minimum length is ${minLength} characters`,
              },
              maxLength: {
                value: maxLength,
                message: `Maximum length is ${maxLength} characters`,
              },
              min: {
                value: min,
                message: `Value must be greater than or equal to ${min}`,
              },
              max: {
                value: max,
                message: `Value must be less than or equal to ${max}`,
              },
              value: defaultValue,
            })}
            disabled={disabled}
            autoComplete="off"
            placeholder={placeholder}
            setselectedValue={setselectedValue}
            onChange={onChange}
          />
          {link && (
            <>
              <Row className="text-end">
                <button
                  style={{
                    color: "#214382",
                    fontSize: "small",
                    textDecoration: "underline",
                    alignItems: "center",
                    gap: "4px",
                  }}
                  type="button"
                  onClick={link.onClick}
                  className={`border-0 bg-transparent cursor-pointer text-start${
                    link.className || ""
                  }`}
                >
                  <MdSearch size={14} /> {/* Search icon added */}
                  {link.text}
                </button>
              </Row>
            </>
          )}
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col md={4}></Col>
        <Col md={8}>{error && <p className="m-0 err-msg">{error}</p>}</Col>
      </Row>
    </Form.Group>
  );
};

export default Input;
