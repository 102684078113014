import React, { useEffect, useState } from "react";
import { Col, Row, Form } from "react-bootstrap";
import { getSessionId } from "../../../utils/tokenHandlers";
import { getBusinessDetailsFromCINAction } from "../../../feature/opsUser/ppApplication/ppIndia/redux/action/getBusinessDetailsFromCINAction";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { getAppAction } from "../../../feature/opsUser/ppApplication/ppIndia/redux/action/getAppAction";
import { useParams } from "react-router-dom";
import { KeyPersonDetailsType } from "../../../feature/opsUser/ppApplication/ppIndia/pages/types/KeyPersonDetailsType";
import { getAllKeyPersonAction } from "../../../feature/opsUser/ppApplication/ppIndia/redux/action/getAllKeyPersonAction";

interface DirectorsDropdownProps {
  name: string;
  label: string;
  register: any;
  required?: boolean;
  requiredMessage?: string;
  handleChange?: () => JSX.Element;
  defaultValue?: string;
  error?: string;
  disabled?: boolean;
  onChange?: (selectedBlock: DirectorsDropdownData | null) => void;
}

interface DirectorsDropdownData {
  name: string;
}

const DirectorsDropdown: React.FC<DirectorsDropdownProps> = ({
  name,
  label,
  register,
  required,
  requiredMessage = "This field is required",
  handleChange,
  defaultValue,
  error,
  disabled,
  onChange,
}) => {
  //   const token = getSessionId();
  const dispatch = useDispatch<AppDispatch>();

  const [DirectorsDropdownValues, setDirectorsDropdownValues] = useState<
    DirectorsDropdownData[]
  >([]);
  let { appId } = useParams();
  let customerId = Number(appId);
  const [loading, setLoading] = useState(true);
  const [fetchError, setFetchError] = useState<string | null>(null);
  const [dataFetched, setDataFetched] = useState(false);
  const state = useSelector((state: RootState) => state.opsPpIndApp.getApp);
  const appGSTIN = state?.data?.business_gst;

  const keyPersonsSelector: KeyPersonDetailsType[] = useSelector(
    (state: RootState) => state.opsPpIndApp.getAllKeyPerson.data
  );

  useEffect(() => {
    // Extract email addresses from keyPersonsSelector and store them in the state
    const extractedEmails = keyPersonsSelector
      .map((person) => person.first_name)
      .filter((person) => person); // Filtering out null or undefined emails
    // setEmails(extractedEmails);
    // console.log(extractedEmails);
  }, [keyPersonsSelector]);

  useEffect(() => {
    dispatch(getAllKeyPersonAction(customerId));
  }, [customerId, dispatch]);

  useEffect(() => {
    dispatch(getAppAction(customerId));
  }, [dispatch, customerId]);
  useEffect(() => {
    if (!dataFetched && appGSTIN) {
      dispatch(getBusinessDetailsFromCINAction(appGSTIN))
        .then((response) => {
          if (response?.payload?.result?.management?.current) {
            const currentManagement =
              response.payload.result.management.current;

            // Check if currentManagement is an array or a single object
            const values = Array.isArray(currentManagement)
              ? currentManagement
              : [currentManagement];
            // console.log("Fetched Values:", values);
            setDirectorsDropdownValues(values);
            setDataFetched(true);
          } else {
            throw new Error("Invalid response structure");
          }
        })
        .catch((error) => {
          console.error("Error fetching management data:", error);
          setFetchError("Failed to fetch management data");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [appGSTIN, dataFetched, dispatch]);

  return (
    <Form.Group as={Col} className="mb-3">
      <Row className="align-items-center">
        <Form.Label column md={4}>
          <span className="float-end">
            {label}&nbsp;
            <span className="text-danger form-label">
              {required ? "*" : ""}
            </span>
            :
          </span>
        </Form.Label>
        <Col md={8}>
          {loading ? (
            <p>Loading...</p>
          ) : fetchError ? (
            <p className="text-danger">{fetchError}</p>
          ) : (
            <Form.Select
              className={`custom-input form-control ${
                error ? "is-invalid" : ""
              }`}
              onChange={(e) => {
                const selectedName = e.target.value;
                const selectedBlock = DirectorsDropdownValues.find(
                  (item) => item.name === selectedName
                );

                if (onChange) {
                  onChange(selectedBlock || null);
                }
              }}
            >
              <option value="">Select Option</option>
              {DirectorsDropdownValues.filter(
                (item) =>
                  !keyPersonsSelector.some(
                    (person) =>
                      item.name
                        .toLowerCase()
                        .includes(person.first_name.toLowerCase()) // Convert both to lowercase to make it case-insensitive
                  )
              ).map((item, index) => (
                <option key={index} value={item.name}>
                  {item.name}
                </option>
              ))}
            </Form.Select>
          )}
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col md={4}></Col>
        <Col md={8}>{error && <p className="m-0 err-msg">{error}</p>}</Col>
      </Row>
    </Form.Group>
  );
};

export default DirectorsDropdown;
