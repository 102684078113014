import React, { useEffect, useState } from "react";
import NavbarVTX from "../../../../../components/common/Navbar";
import Header from "../../../../../components/common/Header";
import Input from "../../../../../components/common/Input";
import { Container, Row, Col, Form, Spinner } from "react-bootstrap";
import { useForm, SubmitHandler } from "react-hook-form";
import CountryDropdown from "../../../../../components/common/CountryDropdown";
import DatePicker from "../../../../../components/common/DatePicker";
import Button from "../../../../../components/common/Button";
import CheckBoxes from "../../../../../components/common/CheckBoxes";
// import "./AddKeyIndividual.css";
import { Table, Modal } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import IndividualDesignation from "../../../../../components/common/dropdowns/IndividualDesignation";
import Gender from "../../../../../components/common/dropdowns/Gender";
import { KeyPersonDetailsType } from "./types/KeyPersonDetailsType";
import KeyPersonType from "../../../../../components/common/KeyPersonType";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../redux/store";
import { createKeyPersonAction } from "../redux/action/createKeyPersonAction";
import { DisplayBox } from "../../../../../components/containers/DisplayBox";
import {
  ADDRESS_REGEX,
  ALPHABETS_REGEX,
  ALPHANUMERIC_REGEX,
  CKYC_REGEX,
  EMAIL_REGEX,
  NON_INDIAN_REGEX,
  OWNERSHIP_REGEX,
  PAN_REGEX,
  PARTNERSHIP_REGEX,
  PHONE_REGEX,
  PINCODE_REGEX,
} from "../../../../constants/regexPatterns";
import SectionHeading from "../../../../../components/common/SectionHeading";
import IsdCode from "../../../../../components/common/dropdowns/IsdCode";
import PhoneInputWithDropdown from "../../../../../components/common/PhoneInput";
import PassportExpiryDatePicker from "../../../../../components/common/PassportExpiryDatePicker";
import SingleCheckBox from "../../../../../components/common/SingleCheckbox";
import { getAllKeyPersonAction } from "../redux/action/getAllKeyPersonAction";
import AlertPopup from "../../../../../components/common/Alert/AlertPopup";
import DirectorsDropdown from "../../../../../components/common/dropdowns/DirectorsDropdown";
import { getAppAction } from "../redux/action/getAppAction";
import { searchAddressByPincodeAction } from "../redux/action/searchAddressByPincodeAction";

const OpsAddKeyPersonDetailsPage: React.FC = () => {
  let { appId } = useParams();
  let customerId = Number(appId);
  type PostOffice = {
    Name: string;
    BranchType: string;
    District: string;
    State: string;
    Pincode: string;
    Block: string;
    Country: string;
  };
  const dispatch = useDispatch<AppDispatch>();

  const keyPersonsSelector: KeyPersonDetailsType[] = useSelector(
    (state: RootState) => state.opsPpIndApp.getAllKeyPerson.data
  );

  const getAppSelector = useSelector(
    (state: RootState) => state.opsPpIndApp.getApp.data
  );

  const constitutionType = getAppSelector.business_constitution_type;

  const [emails, setEmails] = useState<string[]>([]);

  useEffect(() => {
    dispatch(getAllKeyPersonAction(customerId));
  }, [customerId, dispatch]);

  // useEffect(() => {
  //   // Extract email addresses from keyPersonsSelector and store them in the state
  //   const extractedEmails = keyPersonsSelector
  //     .map((person) => person.first_name + person.last_name)
  //     .filter((person) => person); // Filtering out null or undefined emails
  //   setEmails(extractedEmails);
  //   console.log(extractedEmails);
  // }, [keyPersonsSelector]);

  useEffect(() => {
    dispatch(getAppAction(customerId));
  }, [dispatch, customerId]);

  // useEffect(() => {
  //   if (emails) {
  //     // console.warn(emails);
  //   }
  // }, []);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm<KeyPersonDetailsType>({
    shouldUnregister: true,
  });

  const isNationalityIndia = Number(watch("nationality_country_id")) === 448;

  const isCountryIndia = Number(watch("key_person_address_country_id")) === 448;
  const navigate = useNavigate();

  const [selectedKeyPersonType, setSelectedKeyPersonType] = useState<number[]>(
    []
  );
  const [showSpinner, setShowSpinner] = useState(false);
  const [showEmailError, setShowEmailError] = useState(false);
  const dob = watch("date_of_birth");
  const [dobOnchange, setDOB] = useState("");
  const [isMinorDOB, setIsMinorDOB] = useState(false);

  const isBeneficialOwner = selectedKeyPersonType.includes(573);
  const isEntityAdmin = !selectedKeyPersonType.includes(575);
  const entityAdmin = selectedKeyPersonType.includes(575);
  const isMinor = watch("is_minor");
  const isPartner = watch("designation") == "partner";
  const passportExp = watch("passport_expiry_date");
  const designation = watch("designation");
  const isdCode = watch("phone_country_code");
  const phone = watch("phone");
  const email = watch("email");

  const [showPincodeSearchModal, setPincodeSearchShowModal] = useState(false);
  const handlePincodeSearchModalOpen = () => setPincodeSearchShowModal(true);
  const handlePincodeSearchModalClose = () => setPincodeSearchShowModal(false);

  const [showPincodeSearch, setShowPincodeSearch] = useState(false);
  const [pincodeValue, setPincodeValue] = useState("");
  const [addressByPincode, setAddressByPincode] = useState<PostOffice[]>([]);
  const [selectedAddress, setSelectedAddress] = useState<PostOffice | null>(
    null
  );
  const [searchAddrSpinner, setSearchAddrSpinner] = useState(false);
  // const pincode = watch("pincode");

  const [designationFetched, setDesignationFetched] = useState(false);
  const [designationOthersFetched, setDesignationOthersFetched] =
    useState(false);
  const [firstNameFetched, setFirstNameFetched] = useState(false);
  const [lastNameFetched, setLastNameFetched] = useState(false);
  const [dobFetched, setDOBFetched] = useState(false);
  const [genderFetched, setGenderFetched] = useState(false);
  const [motherNameFetched, setMotherNameFetched] = useState(false);
  const [nationalityFetched, setNationalityFetched] = useState(false);
  const [panFetched, setPanFetched] = useState(false);
  const [dinFetched, setDinFetched] = useState(false);
  const [districtFetched, setDistrictFetched] = useState(false);
  const [stateFetched, setStateFetched] = useState(false);
  const [countryFetched, setCountryFetched] = useState(false);
  const [cityFetched, setCityFetched] = useState(false);
  const [emailFetched, setEmailFetched] = useState(false);

  const handlePincodeSearch = async () => {
    setSearchAddrSpinner(true);
    dispatch(searchAddressByPincodeAction(pincodeValue)).then((resp) => {
      const postOffices: PostOffice[] =
        resp.payload?.result?.[0]?.PostOffice || [];
      if (resp.payload?.result?.[0]?.Status === "Success") {
        setAddressByPincode(postOffices);
        handlePincodeSearchModalOpen();
        setSearchAddrSpinner(false);
      } else {
        alert("Could not fetch the address");
        setSearchAddrSpinner(false);
      }
    });
  };

  const handleClosePincodeSearchModal = () => {
    handlePincodeSearchModalClose(); // Close the modal
    setSearchAddrSpinner(false); // Stop the spinner
  };

  // const handlePincodeSearch = () => {};

  const handleKeyPersonDetailsSubmit: SubmitHandler<KeyPersonDetailsType> = (
    keyPersonDetails
  ) => {
    // setShowEmailError(false);
    // if (entityAdmin) {
    //   if (emails.includes(email)) {
    //     // console.error("Error: Email already exists");
    //     setShowEmailError(true);
    //     setTimeout(() => {
    //       setShowEmailError(false);
    //     }, 2000);
    //   }
    //   return;
    // }
    setShowSpinner(true);
    // // console.log("hello");
    keyPersonDetails.customer_id = customerId;
    keyPersonDetails.key_person_type_ids = selectedKeyPersonType;
    keyPersonDetails.receive_otp_by = "email";
    keyPersonDetails.key_person_address_country_id = Number(
      keyPersonDetails.key_person_address_country_id
    );
    keyPersonDetails.nationality_country_id = Number(
      keyPersonDetails.nationality_country_id
    );
    if (!passportExp) {
      keyPersonDetails.passport_expiry_date = false;
    } else {
      keyPersonDetails.passport_expiry_date = passportExp;
    }

    keyPersonDetails.api_designation_flag = designationFetched;
    keyPersonDetails.api_first_name_flag = firstNameFetched;
    keyPersonDetails.api_last_name_flag = lastNameFetched;
    keyPersonDetails.api_date_of_birth_flag = dobFetched;
    keyPersonDetails.api_gender_flag = genderFetched;
    keyPersonDetails.api_mother_name_flag = motherNameFetched;
    keyPersonDetails.nationality_country_id_flag = nationalityFetched;
    keyPersonDetails.api_tax_id_flag = panFetched;
    keyPersonDetails.api_din_flag = dinFetched;
    keyPersonDetails.api_district_flag = districtFetched;
    keyPersonDetails.api_state_flag = stateFetched;
    keyPersonDetails.api_country_flag = countryFetched;
    keyPersonDetails.api_city_flag = cityFetched;
    keyPersonDetails.api_email_flag = emailFetched;
    keyPersonDetails.api_if_others_flag = designationOthersFetched;

    // console.log(keyPersonDetails, "hello");
    if (isdCode === "+91" && phone?.length !== 10) {
      alert("Please enter 10 digit phone number");
      setShowSpinner(false);
    } else {
      dispatch(createKeyPersonAction(keyPersonDetails)).then((response) => {
        try {
          if (response.payload.result) {
            navigate(`/key-person-details/${customerId}`);
            // console.log("response--", response.payload);
            setShowSpinner(false);
          } else {
            alert("Something went wrong, Please contact admin!");
            setShowSpinner(false);
          }
        } catch (err) {
          alert("Something went wrong, Please contact admin!");
          setShowSpinner(false);
        }
      });
    }
  };

  const handleDateChange = (selectedDate: string) => {
    setDOB(selectedDate);
    // console.log(selectedDate);
    const currentDate = new Date();
    const birthDate = new Date(selectedDate);
    const ageDiff = currentDate.getFullYear() - birthDate.getFullYear();
    const isUnder18 =
      ageDiff < 18 ||
      (ageDiff === 18 && currentDate.getMonth() < birthDate.getMonth());

    // console.log("Is under 18:", isUnder18);
    if (isUnder18 === true) {
      setIsMinorDOB(true);
      setValue("is_minor", true);
    } else {
      setIsMinorDOB(false);
      setValue("is_minor", false);
    }
    // Add any additional logic you want to perform on date change
  };

  const [firstN, setFirstN] = useState("");
  const [lastN, setLastN] = useState("");

  const handleDirectorSelection = (selectedBlock: any) => {
    // console.log(selectedBlock);
    // Reformat the date of birth to "YYYY-MM-DD"
    if (selectedBlock) {
      const formattedDate = selectedBlock?.dateOfBirth
        ? selectedBlock.dateOfBirth.split("-").reverse().join("-")
        : "";
      // Reformat the name
      const nameParts = selectedBlock.name.split(" ");
      if (nameParts) {
        const firstName = nameParts.slice(0, -1).join(" ");
        // console.log(firstName);
        setValue("first_name", firstName);

        const lastName = nameParts.slice(-1).join(" ");
        setValue("last_name", lastName);

        setTimeout(() => {
          setValue("first_name", firstName);

          setValue("last_name", lastName);
          setFirstN(firstName);
          setLastN(lastName);
        }, 1000);
      }

      //Reformat the Designation
      if (selectedBlock?.designation) {
        if (selectedBlock.designation == "DIRECTOR") {
          setValue("designation", "director");
        } else if (
          selectedBlock.designation !== "partner" ||
          selectedBlock.designation !== "proprietor" ||
          selectedBlock.designation !== "managing director" ||
          selectedBlock.designation !== "whole time director"
        ) {
          setValue("designation", "others");
          setValue("if_others", selectedBlock.designation);
        }
      }

      if (selectedBlock.gender === "MALE") {
        setValue("gender", "male");
      } else if (selectedBlock.gender === "FEMALE") {
        setValue("gender", "female");
      } else {
        setValue("gender", "");
      }

      setValue("din", selectedBlock.din);
      // setValue("gender", genderValue);
      setValue("mother_name", selectedBlock.fatherName);
      setValue("date_of_birth", formattedDate);
      setValue("tax_id", selectedBlock?.pans[0]);
      setValue("first_name", firstN);
      setValue("last_name", lastN);
      setValue(
        "nationality_country_id",
        selectedBlock.nationality == "India" ? 448 : selectedBlock.nationality
      );
      setValue("email", selectedBlock.email);

      const flagSetters = [
        { setter: setDesignationFetched, value: selectedBlock.designation },
        { setter: setFirstNameFetched, value: selectedBlock.name },
        { setter: setLastNameFetched, value: selectedBlock.name },
        { setter: setDOBFetched, value: selectedBlock.dateOfBirth },
        { setter: setGenderFetched, value: selectedBlock.gender },
        { setter: setMotherNameFetched, value: selectedBlock.fatherName },
        {
          setter: setNationalityFetched,
          value: selectedBlock.nationality == "India",
        },
        {
          setter: setPanFetched,
          value: selectedBlock?.pans[0],
        },
        {
          setter: setDinFetched,
          value: selectedBlock.din,
        },
        {
          setter: setEmailFetched,
          value: selectedBlock.email,
        },
        {
          setter: setDesignationOthersFetched,
          value: selectedBlock.designation,
        },
      ];
      flagSetters.forEach(({ setter, value }) => {
        if (value) setter(true);
      });
    }
  };

  useEffect(() => {
    if (dob) {
      const currentDate = new Date();
      const birthDate = new Date(dob);
      const ageDiff = currentDate.getFullYear() - birthDate.getFullYear();
      const isUnder18 =
        ageDiff < 18 ||
        (ageDiff === 18 && currentDate.getMonth() < birthDate.getMonth());

      // console.log("Is under 18:", isUnder18);
      if (isUnder18 === true) {
        setIsMinorDOB(true);
        setValue("is_minor", true);
      } else if (isUnder18 === false) {
        setIsMinorDOB(false);
      }
    }
  }, [dob, setValue]);

  return (
    <>
      <DisplayBox display={showEmailError}>
        <AlertPopup
          message="An Entity Admin with this Email already exists"
          variant="danger"
        ></AlertPopup>
      </DisplayBox>
      <NavbarVTX />
      <Header />
      <Container>
        <Row>
          <Col md={20} className="myCol">
            <SectionHeading text="ADD KEY PERSON DETAILS" />
          </Col>
        </Row>

        <Form onSubmit={handleSubmit(handleKeyPersonDetailsSubmit)}>
          <Row className="myrow">
            <Col md={5} style={{ marginLeft: "30%" }}>
              <KeyPersonType
                name="key_person_type_ids"
                label=""
                register={register}
                required
                // requiredMessage="Key Person Is required"
                error={errors.key_person_type_ids?.message}
                selectedKeyPersonType={selectedKeyPersonType}
                setSelectedKeyPersonType={setSelectedKeyPersonType}
                setValue={setValue}
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              {constitutionType !== "partnership" &&
                constitutionType !== "proprietorship" && (
                  <DirectorsDropdown
                    name={"DirectorsPartners"}
                    label={"Directors/ Partners"}
                    register={register}
                    onChange={(selectedBlock) => {
                      // console.log("Selected Block in Parent:", selectedBlock); // Check if this works
                      handleDirectorSelection(selectedBlock);
                    }}
                  />
                )}
            </Col>
            {/* <Col md={4}></Col>
            <Col md={4}>
              Address Details (As per the Address proof document)
            </Col> */}
          </Row>
          <Row className="justify-content-center">
            <Col sm={4}>
              <IndividualDesignation
                label="Designation"
                name="designation"
                register={register}
                required
                requiredMessage="Designation is required"
                error={errors.designation?.message}
                colored={designationFetched}
                handleChange={() => {
                  setDesignationFetched(false);
                }}
              />
            </Col>
            <Col sm={4}>
              <CountryDropdown
                label="Nationality"
                name="nationality_country_id"
                register={register}
                required
                // disabled
                requiredMessage="Nationality is required"
                error={errors.nationality_country_id?.message}
                colored={nationalityFetched}
                handleChange={() => {
                  setNationalityFetched(false);
                }}
              />
            </Col>
            <Col sm={4}>
              <Input
                label="Address Line 1"
                regexPattern={ADDRESS_REGEX}
                name="key_person_address_line_1"
                register={register}
                placeholder="Enter Address line 1"
                required
                requiredMessage="Address line 1 is required"
                error={errors.key_person_address_line_1?.message}
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <DisplayBox display={designation === "others"}>
                <Input
                  label={"If Others,"}
                  name={"if_others"}
                  register={register}
                  required={designation === "others"}
                  placeholder="Designation if others"
                  error={errors.if_others?.message}
                  regexPattern={ALPHABETS_REGEX}
                  colored={designationOthersFetched}
                  onChange={() => {
                    setDesignationOthersFetched(false);
                  }}
                />
              </DisplayBox>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col sm={4}>
              <Input
                label="First name"
                name="first_name"
                regexPattern={ALPHABETS_REGEX}
                register={register}
                placeholder="Enter first name"
                required
                requiredMessage="First Name is required"
                error={errors.first_name?.message}
                colored={firstNameFetched}
                onChange={() => {
                  setFirstNameFetched(false);
                }}
              />
            </Col>
            <Col sm={4}>
              <Input
                label="PAN/TAX Id"
                name="tax_id"
                regexPattern={isNationalityIndia ? PAN_REGEX : undefined}
                regexMessage={isNationalityIndia ? "Invalid PAN" : ""}
                register={register}
                placeholder="Enter Pan/ Tax Id"
                required
                requiredMessage="Tax Id is required"
                error={errors.tax_id?.message}
                maxLength={isNationalityIndia ? 10 : 20}
                colored={panFetched}
                onChange={() => {
                  setPanFetched(false);
                }}
              />
            </Col>
            <Col sm={4}>
              <Input
                label="Address Line 2"
                regexPattern={ADDRESS_REGEX}
                name="key_person_address_line_2"
                register={register}
                placeholder="Enter Address line 2"
                required
                requiredMessage="Address line 2 is required"
                error={errors.key_person_address_line_2?.message}
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm={4}>
              <Input
                label="Middle name"
                regexPattern={ALPHABETS_REGEX}
                name="middle_name"
                register={register}
                placeholder="Enter middle name"
                // required
                // requiredMessage="middle name is required"
                error={errors.middle_name?.message}
              />
            </Col>
            <Col sm={4}>
              <Input
                label="Passport No"
                name="passport_number"
                regexPattern={ALPHANUMERIC_REGEX}
                register={register}
                placeholder="Enter passport no"
                // required
                // requiredMessage="passport number is required"
                error={errors.passport_number?.message}
              />
            </Col>
            <Col sm={4}>
              <Input
                label="Pincode"
                name="key_person_address_pincode"
                register={register}
                regexPattern={isCountryIndia ? PINCODE_REGEX : NON_INDIAN_REGEX}
                regexMessage="Please enter correct pincode"
                placeholder="Enter pincode"
                required
                requiredMessage="Pincode is required"
                error={errors.key_person_address_pincode?.message}
                link={{
                  text: "Address Lookup",
                  onClick: () => handlePincodeSearch(),
                  // className: "text-end",
                }}
                onChange={(e) => {
                  setPincodeValue(e.target.value);
                }}
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm={4}>
              <Input
                label="Last name"
                name="last_name"
                register={register}
                regexPattern={ALPHABETS_REGEX}
                placeholder="Enter last name"
                required
                requiredMessage="Last Name is required"
                error={errors.last_name?.message}
                colored={lastNameFetched}
                onChange={() => {
                  setLastNameFetched(false);
                }}
              />
            </Col>
            <Col sm={4}>
              {/* <DatePicker
                label="Passport Expiry"
                name="passport_expiry_date"
                register={register}
                isPassportExpiry={true}
                // required
                // requiredMessage="passport expiry date is required"
                error={errors.passport_expiry_date?.message}
              /> */}
              <PassportExpiryDatePicker
                label="Passport Expiry"
                name="passport_expiry_date"
                register={register}
              />
            </Col>
            <Col sm={4}>
              <Input
                label="District/City"
                name="key_person_address_district"
                register={register}
                regexPattern={ALPHABETS_REGEX}
                placeholder="Enter district/city"
                required
                requiredMessage="District is required"
                error={errors.key_person_address_district?.message}
                colored={districtFetched}
                onChange={() => {
                  setDistrictFetched(false);
                }}
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm={4}>
              {/* <Input
                label="Mother name"
                name="mother_name"
                register={register}
                regexPattern={ALPHABETS_REGEX}
                placeholder="Enter mother name"
                required
                requiredMessage="Mother Name is required"
                error={errors.mother_name?.message}
              /> */}
              <DatePicker
                label="Date of Birth"
                name="date_of_birth"
                register={register}
                isDateOfBirth
                required
                requiredMessage="Date of Birth is required"
                error={errors.date_of_birth?.message}
                onChange={handleDateChange}
                skipCurrentYear
                // isOUDate
                colored={dobFetched}
                handleChange={() => {
                  setDOBFetched(false);
                }}
              />
            </Col>
            <Col sm={4}>
              <Input
                label="Employee Id"
                name="employee_id"
                regexPattern={ALPHANUMERIC_REGEX}
                register={register}
                placeholder="Enter employee id"
                // required
                // requiredMessage="employee id is required"
                error={errors.employee_id?.message}
              />
            </Col>
            <Col sm={4}>
              <Input
                label="State/County"
                name="key_person_address_state"
                register={register}
                regexPattern={ALPHABETS_REGEX}
                placeholder="Enter state/country"
                required
                requiredMessage="State is required"
                error={errors.key_person_address_state?.message}
                colored={stateFetched}
                onChange={() => {
                  setStateFetched(false);
                }}
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm={4}>
              <Gender
                label="Gender"
                name="gender"
                register={register}
                // required
                // requiredMessage="gender is required"
                error={errors.gender?.message}
                colored={genderFetched}
                handleChange={() => {
                  setGenderFetched(false);
                }}
              />
            </Col>
            <Col sm={4}>
              <PhoneInputWithDropdown
                label={entityAdmin ? "Cellphone" : "Contact no/ Landline"}
                name="phone"
                register={register}
                isdCodeName={"phone_country_code"}
                isdCodeRegister={register}
                placeholder="Enter your Contact no"
                error={errors.phone?.message}
                required
                regexPattern={PHONE_REGEX}
                // inputDefaultValue={`${localStorage.getItem("guestMobile")}`}
              />
            </Col>
            <Col sm={4}>
              <CountryDropdown
                label="Country"
                name="key_person_address_country_id"
                register={register}
                required
                requiredMessage="Country is required"
                error={errors.key_person_address_country_id?.message}
                colored={countryFetched}
                handleChange={() => {
                  setCountryFetched(false);
                }}
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm={4}>
              {/* <DatePicker
                label="Date of Birth"
                name="date_of_birth"
                register={register}
                isDateOfBirth
                required
                requiredMessage="Date of Birth is required"
                error={errors.date_of_birth?.message}
                onChange={handleDateChange}
              /> */}
              <Input
                label="Mother/ Father/ Spouse name"
                name="mother_name"
                register={register}
                regexPattern={ALPHABETS_REGEX}
                placeholder="Enter Mother/ Father/ Spouse name"
                required
                requiredMessage="Mother/ Father/ Spouse name is required"
                error={errors.mother_name?.message}
                colored={motherNameFetched}
                onChange={() => {
                  setMotherNameFetched(false);
                }}
              />
            </Col>
            <Col sm={4}>
              <Input
                label="CKYC No"
                name="ckycno"
                regexPattern={CKYC_REGEX}
                register={register}
                placeholder="Enter ckyc"
                // required
                // requiredMessage="ckycno is required"
                error={errors.ckycno?.message}
              />
            </Col>
            <Col sm={4}>
              <Input
                label="City/Town/Village"
                name="key_person_address_city"
                register={register}
                regexPattern={ALPHABETS_REGEX}
                placeholder="Enter city/town/village"
                required
                requiredMessage="City is required"
                error={errors.key_person_address_city?.message}
                colored={cityFetched}
                onChange={() => {
                  setCityFetched(false);
                }}
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm={4}>
              <Input
                label="Ownership %"
                name="ownership_percentage"
                register={register}
                placeholder="Enter ownership %"
                required={isBeneficialOwner}
                regexPattern={OWNERSHIP_REGEX}
                regexMessage="Value must be a number less than 100"
                // requiredMessage="ownership % is required"
                error={errors.ownership_percentage?.message}
              />
            </Col>
            <Col sm={4}>
              <CountryDropdown
                label="Tax residency"
                name="tax_residency_country_id"
                register={register}
                //placeholder="Enter tax residency"
                required
                requiredMessage="Tax Residency is required"
                error={errors.tax_residency_country_id?.message}
              />
            </Col>
            <Col sm={4}>
              <Input
                label="Email"
                name="email"
                register={register}
                regexPattern={EMAIL_REGEX}
                placeholder="Enter email"
                required
                requiredMessage="Email is required"
                error={errors.email?.message}
                colored={emailFetched}
                onChange={() => {
                  setEmailFetched(false);
                }}
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm={4}>
              <Input
                label="DIN/DPIN"
                name="din"
                regexPattern={ALPHANUMERIC_REGEX}
                register={register}
                placeholder="Enter DIN/DPIN"
                // required
                // requiredMessage="din is required"
                error={errors.din?.message}
                colored={dinFetched}
                onChange={() => {
                  setDinFetched(false);
                }}
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm={4}>
              <DisplayBox display={isBeneficialOwner || isMinorDOB}>
                {/* <CheckBoxes
                  label="Is minor  ?"
                  name="is_minor"
                  register={register}
                  //required
                  error={errors.is_minor?.message}
                /> */}
                <SingleCheckBox
                  name={"is_minor"}
                  label={"Is Minor ?"}
                  register={register}
                  required={isMinorDOB}
                />
              </DisplayBox>
            </Col>
            <Col sm={4}>
              <DisplayBox display={isMinor}>
                <Input
                  label="Guardian Name"
                  name="guardian_name"
                  regexPattern={ALPHABETS_REGEX}
                  register={register}
                  placeholder="Enter guardian name"
                  required
                  requiredMessage="Guardian Name is required"
                  error={errors.guardian_name?.message}
                />
              </DisplayBox>
            </Col>

            <Col sm={4}>
              <DisplayBox display={isMinor}>
                <Input
                  label="Guardian Relationship"
                  name="guardian_relation"
                  regexPattern={ALPHABETS_REGEX}
                  register={register}
                  placeholder="Enter guardian relationship"
                  required
                  requiredMessage="Guardian Relation is required"
                  error={errors.guardian_relation?.message}
                />
              </DisplayBox>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col sm={4}>
              <DisplayBox display={isEntityAdmin}>
                {/* <CheckBoxes
                  label="Is Politically exposed person  ?"
                  name="is_politically_exposed"
                  register={register}
                  //required
                  // error={errors.is_politically_exposed?.message}
                /> */}
                <SingleCheckBox
                  name={"is_politically_exposed"}
                  label={"Is Politically exposed person ?"}
                  register={register}
                />
              </DisplayBox>
            </Col>
            <Col sm={4}>
              <DisplayBox display={isPartner}>
                <Input
                  label="Partnership(%)"
                  name="partnership_percentage"
                  register={register}
                  placeholder="Partnarship(%)"
                  regexPattern={PARTNERSHIP_REGEX}
                  regexMessage="Value must be a number less than 100"
                  error={errors.partnership_percentage?.message}
                />
              </DisplayBox>
            </Col>
            <Col sm={4}></Col>
          </Row>
          <Row>
            <Col md={20} className="text-end">
              {showSpinner ? (
                <Spinner animation="border" variant="primary" />
              ) : (
                <Button
                  label="Save Details"
                  type="submit"
                  // onClick={handleSubmit(handleKeyPersonDetailsSubmit)}
                ></Button>
              )}
            </Col>
          </Row>
        </Form>
      </Container>

      <Modal
        show={showPincodeSearchModal}
        onHide={handleClosePincodeSearchModal}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <SectionHeading text="SELECT ADDRESS " />
            {/* {pincodeValue} */}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {addressByPincode.length > 0 ? (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Select</th>
                  <th>Address</th>
                  {/* <th>Branch Type</th> */}
                  <th>District</th>
                  <th>State</th>
                  <th>Pincode</th>
                </tr>
              </thead>
              <tbody>
                {addressByPincode.map((postOffice, index) => (
                  <tr key={index}>
                    <td>
                      <input
                        type="radio"
                        name="selectedAddress"
                        value={postOffice.Name}
                        onChange={() => setSelectedAddress(postOffice)}
                      />
                    </td>
                    <td>
                      {postOffice.Name},{" "}
                      {postOffice.Block ? postOffice.Block : ""},{" "}
                      {postOffice.Name}
                    </td>
                    {/* <td>{postOffice.BranchType}</td> */}
                    <td>{postOffice.District}</td>
                    <td>{postOffice.State}</td>
                    <td>{postOffice.Pincode}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>No addresses found</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handlePincodeSearchModalClose}>
      Close
    </Button> */}
          {/* <Button variant="primary" disabled={!selectedAddress} onClick={() => console.log("Selected Address:", selectedAddress)}>
      Confirm Address
    </Button> */}
          <Button
            label={"Confirm Address"}
            onClick={() => {
              // console.log("Selected Address:", selectedAddress?.Name);
              if (selectedAddress) {
                setValue("key_person_address_city", selectedAddress.Name);
                setValue(
                  "key_person_address_district",
                  selectedAddress.District
                );
                setValue("key_person_address_state", selectedAddress.State);
                setValue(
                  "key_person_address_country_id",
                  selectedAddress.Country == "India" ? 448 : 0
                );

                const flagSetters = [
                  { setter: setCityFetched, value: selectedAddress.Name },
                  {
                    setter: setDistrictFetched,
                    value: selectedAddress.District,
                  },
                  { setter: setStateFetched, value: selectedAddress.State },
                  { setter: setCountryFetched, value: true },
                ];

                flagSetters.forEach(({ setter, value }) => {
                  if (value) setter(true);
                });

                handlePincodeSearchModalClose();
              }
            }}
          ></Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default OpsAddKeyPersonDetailsPage;
