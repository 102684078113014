import axios from "axios";

export const searchAddressByPincode = (
  pinCode: string,
  sessionId: string | null
) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}business/fetch/postalcode/${pinCode}`,
      {
        params: {
          //   pincode: `${pinCode}`,
        },
      },
      {
        params: {
          o_ses_id: `${sessionId}`,
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => err);
};
