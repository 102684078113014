import {
  Col,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import Input from "../../../../../components/common/Input";
import CountryDropdown from "../../../../../components/common/CountryDropdown";
import BankAccountType from "../../../../../components/common/dropdowns/BankAccountType";
import { BankDetailsType } from "./types/BankDetailsType";
import LocalBankCurrencies from "../../../../../components/common/dropdowns/LocalBankCurrencies";
import Button from "../../../../../components/common/Button";
import CorrBankAccountDetails from "../../../../guestUser/common/components/BankDetails/CorrBankAccountDetails";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../redux/store";
import { createLocalBankAction } from "../redux/action/createLocalBankAction";
import { useEffect, useState } from "react";
import NavbarVTX from "../../../../../components/common/Navbar";
import Header from "../../../../../components/common/Header";
import SectionHeading from "../../../../../components/common/SectionHeading";
import {
  ADDRESS_REGEX,
  ALPHABETS_REGEX,
  ALPHANUMERIC_REGEX,
  EMAIL_REGEX,
  PHONE_REGEX,
  PINCODE_REGEX,
  SWIFTCODE_REGEX,
  NON_INDIAN_REGEX,
} from "../../../../constants/regexPatterns";
import { DisplayBox } from "../../../../../components/containers/DisplayBox";
import AlertPopup from "../../../../../components/common/Alert/AlertPopup";
import { getAllCorrBankAction } from "../redux/action/getAllCorrBankAction";
import PhoneInputWithDropdown from "../../../../../components/common/PhoneInput";
import BankAccountTypeWOEEFC from "../../../../../components/common/dropdowns/BankAccountTypeWOEEFC";
import BankAccountTypeWOthers from "../../../../../components/common/dropdowns/BankAccountTypeWOthers";
import { searchAddressByPincodeAction } from "../redux/action/searchAddressByPincodeAction";
import { searchIFSCAction } from "../redux/action/searchIFSCAction";
import { searchBankAction } from "../redux/action/searchBankAction";
import { parseAddress } from "../../../../../utils/functions/parseAddress";
import { parseBankAddress } from "../../../../../utils/functions/parseBankAddress";

const OpsAddLocalBankPage: React.FC = () => {
  let { appId } = useParams();
  let customerId = Number(appId);
  const navigate = useNavigate();

  const dispatch = useDispatch<AppDispatch>();

  const getAppSelector = useSelector(
    (state: RootState) => state.opsPpIndApp.getApp
  );
  let role = getAppSelector.data.business_participant_type;
  let is_counterparty;

  // // console.log(getAppSelector.data.is_counterparty, "countery party true");
  const createLocalBankSelector = useSelector(
    (state: RootState) => state.opsPpIndApp.createLocalBank
  );

  const isLocalBankActiveSelector = useSelector(
    (state: RootState) => state.opsPpIndApp.isLocalBankFormActive.isActive
  );
  const {
    register,
    handleSubmit,
    watch,
    setError,
    setValue,
    formState: { errors },
  } = useForm<BankDetailsType>({ shouldUnregister: true });

  type PostOffice = {
    Name: string;
    BranchType: string;
    District: string;
    State: string;
    Pincode: string;
    Block: string;
    Country: string;
  };

  const [localBankId, setLocalBankId] = useState(0);
  const [corrBanks, setCorrBanks] = useState([]);
  const [showLocalBankSuccess, setShowLocalBankSuccess] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [btnText, setBtnText] = useState("Save Details");
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [bankAccNo, setBankAccNo] = useState("");
  const [reBankAccNo, setReBankAccNo] = useState("");
  const [declarationValue, setDeclarationValue] = useState("");
  const [declarationError, setDeclarationError] = useState(false);
  const localCurrency = watch("currency_id");
  const [hideEEFC, setHideEEFC] = useState(false);
  const [ifscCode, setIfscCode] = useState("");
  const country = watch("bank_address_country_id");
  const isCountryIndia = Number(watch("bank_address_country_id")) === 448;

  //colored fields
  const [bankNameFetched, setBankNameFetched] = useState(false);
  const [branchNameFetched, setBranchNameFetched] = useState(false);
  const [addLine1Fetched, setAddLine1Fetched] = useState(false);
  const [addLine2Fetched, setAddLine2Fetched] = useState(false);
  const [pincodeFetched, setPincodeFetched] = useState(false);
  const [cityFetched, setCityFetched] = useState(false);
  const [districtFetched, setDistrictFetched] = useState(false);
  const [stateFetched, setStateFetched] = useState(false);
  const [countryFetched, setCountryFetched] = useState(false);
  const [nameBankAccFetched, setNameBankAccFetched] = useState(false);

  useEffect(() => {
    if (!isLocalBankActiveSelector) {
      navigate(`/currency-bank-details/${customerId}`);
    }
  }, []);

  useEffect(() => {
    if (localCurrency == 12) {
      // console.log("true");
      setHideEEFC(true);
    } else {
      // console.log("false");
      setHideEEFC(false);
    }
  }, [localCurrency]);

  useEffect(() => {
    setLocalBankId(0);
    // console.warn("here--->", corrBanks);
    // console.warn(localBankId);
    if (localBankId) {
      dispatch(getAllCorrBankAction(localBankId));
    }
  }, [dispatch, localBankId]);

  useEffect(() => {
    // console.log(declarationValue, "check");
  }, []);

  const accountNumber = watch("bank_account_number");
  const confirmAccountNumber = watch("re_enter_bank_account_number");
  const bankAccountType = watch("account_type");

  const handleIfscCodeChange = (e: any) => {
    const value = e.target.value.toUpperCase();
    setIfscCode(value);
    setValue("ifsc_code", value);
  };

  const handleIFSCSearch = () => {
    dispatch(searchIFSCAction(ifscCode)).then((resp) => {
      // console.log(resp.payload);
      if (resp.payload?.result?.result) {
        const ifscDetails = resp.payload.result.result;
        setValue("bank_name", ifscDetails.bank);
        setValue("branch_name", ifscDetails.branch);

        // console.log(ifscDetails, "HERE");
        const address = parseBankAddress(ifscDetails.address);
        setValue("bank_address_line_1", address.line_1);
        setValue("bank_address_district", address.district);
        setValue("bank_address_city", address.city);
        setValue("bank_address_state", address.state);
        setValue("bank_address_line_2", address.line_2);
        setValue(
          "bank_address_pincode",
          Number(address.pincode.replace(/\.$/, ""))
        );
        setValue("bank_address_country_id", 448);
        setPincodeValue(address.pincode.replace(/\.$/, ""));

        //coloring the text boxes:
        const flagSetters = [
          { setter: setBankNameFetched, value: ifscDetails.bank },
          { setter: setBranchNameFetched, value: ifscDetails.branch },
          { setter: setAddLine1Fetched, value: address.line_1 },
          { setter: setAddLine2Fetched, value: address.line_2 },
          { setter: setPincodeFetched, value: address.pincode },
          { setter: setCityFetched, value: address.city },
          { setter: setDistrictFetched, value: address.district },
          { setter: setStateFetched, value: ifscDetails.state },
          { setter: setCountryFetched, value: true },
        ];

        // Update fetched states dynamically
        flagSetters.forEach(({ setter, value }) => {
          if (value) setter(true);
        });
      } else {
        alert("Could not find the IFSC details");
      }
    });
  };

  const handleSwiftCodeChange = (e: any) => {
    const value = e.target.value.toUpperCase();
    setValue("swift_code", value);
  };

  const [showPincodeSearchModal, setPincodeSearchShowModal] = useState(false);
  const handlePincodeSearchModalOpen = () => setPincodeSearchShowModal(true);
  const handlePincodeSearchModalClose = () => setPincodeSearchShowModal(false);

  const [showPincodeSearch, setShowPincodeSearch] = useState(false);
  const [pincodeValue, setPincodeValue] = useState("");
  const [addressByPincode, setAddressByPincode] = useState<PostOffice[]>([]);
  const [selectedAddress, setSelectedAddress] = useState<PostOffice | null>(
    null
  );
  const [searchAddrSpinner, setSearchAddrSpinner] = useState(false);
  // const pincode = watch("pincode");
  const handleSearchPincode = async () => {
    setSearchAddrSpinner(true);
    dispatch(searchAddressByPincodeAction(pincodeValue)).then((resp) => {
      const postOffices: PostOffice[] =
        resp.payload?.result?.[0]?.PostOffice || [];
      if (resp.payload?.result?.[0]?.Status === "Success") {
        setAddressByPincode(postOffices);
        handlePincodeSearchModalOpen();
        setSearchAddrSpinner(false);
      } else {
        alert("Could not fetch the address");
        setSearchAddrSpinner(false);
      }
    });
  };

  const handleClosePincodeSearchModal = () => {
    handlePincodeSearchModalClose(); // Close the modal
    setSearchAddrSpinner(false); // Stop the spinner
  };

  const handleSearchBank = () => {
    // console.log(accountNumber);
    if (bankAccNo !== reBankAccNo) {
      alert("Bank Account Number does not match");
      return;
    }
    if (ifscCode && bankAccNo) {
      dispatch(searchBankAction({ ifscCode, bankAccNo })).then((resp) => {
        if (resp.payload?.result?.result) {
          // console.log(resp.payload);
          const data = resp.payload?.result?.result?.data?.source[0]?.data;
          setValue("bank_account_name", data.accountName);
          if (data.accountName) {
            setNameBankAccFetched(true);
          }
        } else {
          alert("Could not get Bank Details");
        }
      });
      // }
      // else {
      //   alert("Bank account number does not match");
      // }
    } else {
      alert("Please enter IFSC and Bank Account Number");
    }
  };

  const handleBankDetailsSubmit: SubmitHandler<BankDetailsType> = (data) => {
    // console.log(declarationValue, ":here");

    if (accountNumber !== confirmAccountNumber) {
      setError("re_enter_bank_account_number", {
        type: "manual",
        message: "Account Number do not match",
      });
      return;
    }

    if (role !== "Financier") {
      if (declarationValue === "") {
        setDeclarationError(true);
        // // console.log("booming");

        return;
      } else {
        setDeclarationError(false);
      }
    }

    setShowSpinner(true);

    const bankDetails = {
      ...data,
      customer_id: customerId,
      undertaking_confirm: declarationValue,
      bank_address_pincode: Number(data.bank_address_pincode),
      bank_address_country_id: Number(data.bank_address_country_id),
      currency_id: Number(data.currency_id),

      api_line_1_flag: addLine1Fetched ? true : false,
      api_line_2_flag: addLine2Fetched ? true : false,
      api_bank_name_flag: bankNameFetched ? true : false,
      api_branch_name_flag: branchNameFetched ? true : false,
      api_account_name_flag: nameBankAccFetched ? true : false,
      api_city_flag: cityFetched ? true : false,
      api_district_flag: districtFetched ? true : false,
      api_state_flag: stateFetched ? true : false,
      api_country_flag: countryFetched ? true : false,
      postal_pincode_api_success: pincodeFetched ? true : false,
    };

    // console.log(bankDetails);

    dispatch(createLocalBankAction({ bankDetails, corrBanks })).then(
      (response: any) => {
        if (response.payload?.result?.error) {
          alert(response.payload.result.error.data.message);
          setShowSpinner(false);
          setBtnDisabled(false);
        } else {
          setShowLocalBankSuccess(true);
          setShowSpinner(false);
          setBtnText("Bank Added!");
          setBtnDisabled(true);
        }
      }
    );
  };

  return (
    <>
      <Container>
        <NavbarVTX />
        <Header />
        <Row>
          <Row>
            <SectionHeading text="ADD LOCAL BANK ACCOUNT" />
          </Row>
          <DisplayBox display={showLocalBankSuccess}>
            <AlertPopup
              variant="success"
              message="LOCAL BANK Added Successfully!"
            />
          </DisplayBox>
          <Row>
            <Row>
              <Col md={20} className="bank-account-col">
                <SectionHeading text="LOCAL BANK DETAILS" />
              </Col>
              <p
                style={{
                  color: "red",
                }}
                className="text-center"
              >
                (Enter the working capital bank account details (CC/OD/EEFC). In
                absence of working capital facilities, enter current account
                details)
              </p>
            </Row>

            <Form
              style={{ width: "119%" }}
              onSubmit={handleSubmit(handleBankDetailsSubmit)}
              // disabled={btnDisabled}
            >
              <Row style={{ marginTop: "30px" }}>
                <Col sm={6} className="d-flex align-items-center">
                  <LocalBankCurrencies
                    label="Local bank currency"
                    name="currency_id"
                    register={register}
                    required
                    requiredMessage="Currency is required"
                    error={errors.currency_id?.message}
                    disabled={btnDisabled}
                  />
                </Col>
              </Row>

              <Row>
                <Col sm={6} className="d-flex align-items-center">
                  <Input
                    label="IFSC/Sort Code/ABA"
                    name="ifsc_code"
                    register={register}
                    // regexPattern={ALPHABETS_REGEX}
                    placeholder="Enter IFSC Code of the working capital Bank branch"
                    required
                    requiredMessage="IFSC/Sort/ABA is required"
                    error={errors.ifsc_code?.message}
                    disabled={btnDisabled}
                    onChange={handleIfscCodeChange}
                    link={{
                      text: "IFSC Lookup",
                      onClick: () => handleIFSCSearch(),
                      // className: "text-end",
                    }}
                    // onChange={(e) => {
                    //   setPincodeValue(e.target.value);
                    // }}
                  />
                  {/* <p></p> */}
                </Col>

                <Col sm={6}>
                  {getAppSelector.data.is_counterparty === true && (
                    <p style={{ fontSize: "smaller", color: "red" }}>
                      Following fields are not applicable for Counter Party
                      Applications
                    </p>
                  )}
                  <Input
                    label="Address line 1"
                    name="bank_address_line_1"
                    register={register}
                    regexPattern={ADDRESS_REGEX}
                    placeholder="Enter address line 1"
                    required
                    requiredMessage="Address line 1 is required"
                    error={errors.bank_address_line_1?.message}
                    disabled={
                      getAppSelector.data.is_counterparty === true ||
                      btnDisabled
                    }
                    colored={addLine1Fetched}
                    onChange={() => {
                      setAddLine1Fetched(false);
                    }}
                  />
                </Col>
              </Row>
              <Row style={{ marginTop: "1px" }}>
                <Col sm={6} className="d-flex align-items-center">
                  <Input
                    label="Bank account no"
                    type="password"
                    name="bank_account_number"
                    register={register}
                    placeholder="Enter bank account no"
                    required
                    requiredMessage="Account No/ IBAN is required"
                    error={errors.bank_account_number?.message}
                    disabled={btnDisabled}
                    onChange={(e) => {
                      setBankAccNo(e.target.value);
                      setNameBankAccFetched(false);
                    }}
                  />
                </Col>

                <Col sm={6}>
                  <Input
                    label="Address line 2"
                    name="bank_address_line_2"
                    register={register}
                    // regexPattern={ADDRESS_REGEX}
                    placeholder="Enter address line 2"
                    // required
                    // requiredMessage="Email is required"
                    error={errors.bank_address_line_2?.message}
                    disabled={
                      getAppSelector.data.is_counterparty === true ||
                      btnDisabled
                    }
                    colored={addLine2Fetched}
                    onChange={() => {
                      setAddLine2Fetched(false);
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={6} className="d-flex align-items-center">
                  <Input
                    label="Re-enter Bank account no"
                    name="re_enter_bank_account_number"
                    // type="password"
                    register={register}
                    placeholder="Enter re-enter bank account no"
                    required
                    requiredMessage="Bank account number/IBAN is required"
                    error={errors.re_enter_bank_account_number?.message}
                    disabled={btnDisabled}
                    link={{
                      text: "Find Name",
                      onClick: () => handleSearchBank(),
                    }}
                    onChange={(e) => {
                      setReBankAccNo(e.target.value);
                      setNameBankAccFetched(false);
                    }}
                  />
                </Col>

                <Col sm={6} className="d-flex align-items-center">
                  <Input
                    label="Pin Code/ Postal Code/ Zip"
                    name="bank_address_pincode"
                    register={register}
                    placeholder="Enter pincode/zipcode"
                    regexPattern={
                      isCountryIndia ? PINCODE_REGEX : NON_INDIAN_REGEX
                    }
                    // required
                    // requiredMessage="email is required"
                    error={errors.bank_address_pincode?.message}
                    disabled={
                      getAppSelector.data.is_counterparty === true ||
                      btnDisabled
                    }
                    onChange={(e) => {
                      setPincodeValue(e.target.value);
                      setPincodeFetched(false);
                    }}
                    link={{
                      text: "Address Lookup",
                      onClick: () => handleSearchPincode(),
                    }}
                    colored={pincodeFetched}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={6} className="d-flex align-items-center">
                  <Input
                    label="Bank name"
                    name="bank_name"
                    register={register}
                    placeholder="Enter bank name"
                    required
                    regexPattern={ALPHABETS_REGEX}
                    requiredMessage="Bank Name is required"
                    error={errors.bank_name?.message}
                    disabled={btnDisabled}
                    colored={bankNameFetched}
                    onChange={() => {
                      setBankNameFetched(false);
                    }}
                  />
                </Col>

                {/* <Col sm={6} className="text-end">
                  <>
                    {searchAddrSpinner ? (
                      <Spinner animation="border" variant="primary"></Spinner>
                    ) : (
                      <Button
                        label={"Search Address by Pincode"}
                        variant="outline-primary"
                        onClick={() => {
                          // openPincodeSearchModal()
                          handleSearchPincode();
                        }}
                      ></Button>
                    )}
                  </>
                </Col> */}
              </Row>
              <Row>
                <Col sm={6} className="d-flex align-items-center">
                  <Input
                    label="Branch name"
                    name="branch_name"
                    regexPattern={ALPHABETS_REGEX}
                    register={register}
                    placeholder="Enter branch name"
                    required
                    requiredMessage="Branch name is required"
                    error={errors.branch_name?.message}
                    disabled={btnDisabled}
                    colored={branchNameFetched}
                    onChange={() => {
                      setBranchNameFetched(false);
                    }}
                  />
                </Col>

                <Col sm={6} className="d-flex align-items-center">
                  <Input
                    label="City/Town/Locality"
                    name="bank_address_city"
                    register={register}
                    regexPattern={ALPHABETS_REGEX}
                    // required
                    placeholder="Enter City"
                    // requiredMessage="city is required"
                    error={errors.city?.message}
                    disabled={
                      getAppSelector.data.is_counterparty === true ||
                      btnDisabled
                    }
                    colored={cityFetched}
                    onChange={() => {
                      setCityFetched(false);
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={6} className="d-flex align-items-center">
                  <Input
                    label="Name as per bank account"
                    name="bank_account_name"
                    register={register}
                    regexPattern={ALPHABETS_REGEX}
                    placeholder="Enter name as per bank account"
                    disabled={btnDisabled}
                    // required
                    // requiredMessage="Bank account name is required"
                    // error={errors.bank_account_name?.message}
                    colored={nameBankAccFetched}
                    onChange={() => {
                      setNameBankAccFetched(false);
                    }}
                  />
                </Col>

                <Col sm={6} className="d-flex align-items-center">
                  <Input
                    label="District/City Area"
                    name="bank_address_district"
                    register={register}
                    regexPattern={ALPHABETS_REGEX}
                    placeholder="Enter district"
                    // required
                    // requiredMessage="city is required"
                    error={errors.bank_address_district?.message}
                    disabled={
                      getAppSelector.data.is_counterparty === true ||
                      btnDisabled
                    }
                    colored={districtFetched}
                    onChange={() => {
                      setDistrictFetched(false);
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={6} className="d-flex align-items-center">
                  {/* <Input
                    label="IFSC/Sort Code/ABA"
                    name="ifsc_code"
                    register={register}
                    // regexPattern={ALPHABETS_REGEX}
                    placeholder="Enter IFSC/Sort code/ABA"
                    required
                    requiredMessage="IFSC/Sort/ABA is required"
                    error={errors.ifsc_code?.message}
                    disabled={btnDisabled}
                    onChange={handleIfscCodeChange}
                  /> */}
                  <Input
                    label="Swift code /BIC"
                    name="swift_code"
                    register={register}
                    placeholder="Enter swift code"
                    required
                    // regexPattern={SWIFTCODE_REGEX}
                    requiredMessage="Swift Code is required"
                    error={errors.swift_code?.message}
                    disabled={btnDisabled}
                    onChange={handleSwiftCodeChange}
                  />
                </Col>

                <Col sm={6} className="d-flex align-items-center">
                  <Input
                    label="State/County"
                    name="bank_address_state"
                    register={register}
                    placeholder="Enter state"
                    regexPattern={ALPHABETS_REGEX}
                    // required
                    // requiredMessage="state is required"
                    error={errors.bank_address_state?.message}
                    disabled={
                      getAppSelector.data.is_counterparty === true ||
                      btnDisabled
                    }
                    colored={stateFetched}
                    onChange={() => {
                      setStateFetched(false);
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={6} className="d-flex align-items-center">
                  {role === "Financier" ? (
                    <BankAccountTypeWOthers
                      label="Account Type"
                      name="account_type"
                      register={register}
                      required
                      requiredMessage="Account type is required"
                      error={errors.account_type?.message}
                      disabled={btnDisabled}
                    />
                  ) : hideEEFC ? (
                    <BankAccountTypeWOEEFC
                      label="Account Type"
                      name="account_type"
                      register={register}
                      required
                      requiredMessage="Account type is required"
                      error={errors.account_type?.message}
                      disabled={btnDisabled}
                    />
                  ) : (
                    <BankAccountType
                      label="Account Type"
                      name="account_type"
                      register={register}
                      required
                      requiredMessage="Account type is required"
                      error={errors.account_type?.message}
                      disabled={btnDisabled}
                    />
                  )}
                </Col>
                {bankAccountType === "Others" && (
                  <Input
                    label={"If Others,"}
                    name={"other_account_type"}
                    register={register}
                    placeholder="Please Specify Account Type"
                    required
                    error={errors.other_account_type?.message}
                    disabled={btnDisabled}
                  />
                )}
                <Col>
                  <CountryDropdown
                    label="Country"
                    name="bank_address_country_id"
                    register={register}
                    // required
                    // requiredMessage="pincode is required"
                    error={errors.bank_address_country_id?.message}
                    disabled={
                      getAppSelector.data.is_counterparty === true ||
                      btnDisabled
                    }
                    colored={countryFetched}
                    handleChange={() => {
                      setCountryFetched(false);
                    }}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={20} className="bank-account-col">
                  <SectionHeading text="BANK OFFICIALS DETAILS" />
                </Col>
              </Row>
              <Row style={{ marginTop: "30px" }}>
                {getAppSelector.data.is_counterparty === true && (
                  <p style={{ fontSize: "smaller", color: "red" }}>
                    Following fields are not applicable for Counter Party
                    Applications
                  </p>
                )}
                <Col sm={6} className="d-flex align-items-center">
                  <Input
                    label="Official Name"
                    name="bank_official_name"
                    required={
                      getAppSelector.data.is_counterparty !== true ||
                      role !== "Financier"
                    }
                    register={register}
                    regexPattern={ALPHABETS_REGEX}
                    placeholder="Enter official name"
                    error={errors.bank_official_name?.message}
                    disabled={
                      getAppSelector.data.is_counterparty === true ||
                      btnDisabled
                    }
                    // colored
                  />
                </Col>
                <Col sm={6} className="d-flex align-items-center">
                  {/* <Input
                    label="Official Phone"
                    name="bank_official_phone"
                    register={register}
                    regexPattern={PHONE_REGEX}
                    placeholder="Enter official phone"
                    error={errors.bank_official_phone?.message}
                    disabled={
                      getAppSelector.data.is_counterparty === true
                        ? true
                        : false
                    }
                  /> */}
                  <PhoneInputWithDropdown
                    label="Official Phone"
                    name="bank_official_phone"
                    required={
                      getAppSelector.data.is_counterparty !== true ||
                      role !== "Financier"
                    }
                    register={register}
                    regexPattern={PHONE_REGEX}
                    isdCodeName={"phone_country_code"}
                    isdCodeRegister={register}
                    placeholder="Enter official phone"
                    error={errors.bank_official_phone?.message}
                    disabled={
                      getAppSelector.data.is_counterparty === true ||
                      btnDisabled
                    }
                    // required
                    // inputDefaultValue={`${localStorage.getItem("guestMobile")}`}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={6} className="d-flex align-items-center">
                  <Input
                    label="Official Email"
                    name="bank_official_email"
                    required={
                      getAppSelector.data.is_counterparty !== true ||
                      role !== "Financier"
                    }
                    register={register}
                    regexPattern={EMAIL_REGEX}
                    placeholder="Enter official email"
                    error={errors.bank_official_email?.message}
                    disabled={
                      getAppSelector.data.is_counterparty === true ||
                      btnDisabled
                    }
                  />
                </Col>
                <Col sm={6} className="d-flex align-items-center">
                  {/* <Input
                    label="Official Mobile"
                    name="bank_official_mobile"
                    register={register}
                    regexPattern={PHONE_REGEX}
                    placeholder="Enter official mobile"
                    error={errors.bank_official_mobile?.message}
                    disabled={
                      getAppSelector.data.is_counterparty === true
                        ? true
                        : false
                    }
                  /> */}
                  <PhoneInputWithDropdown
                    label="Official Mobile"
                    name="bank_official_mobile"
                    register={register}
                    regexPattern={PHONE_REGEX}
                    isdCodeName={"phone_country_code"}
                    isdCodeRegister={register}
                    placeholder="Enter official mobile"
                    error={errors.bank_official_mobile?.message}
                    disabled={
                      getAppSelector.data.is_counterparty === true ||
                      btnDisabled
                    }
                    // required
                    // inputDefaultValue={`${localStorage.getItem("guestMobile")}`}
                  />
                </Col>
              </Row>
              {role !== "Financier" && (
                <>
                  <Col
                    sm={12}
                    style={{
                      fontSize: "86%",
                      color: "#214382",
                      marginTop: "3%",
                    }}
                  >
                    <Form.Group
                      controlId="undertaking_confirm"
                      // {...register("undertaking_confirm", {
                      //   required: "Please Select",
                      // })}
                    >
                      <Form.Check
                        disabled={btnDisabled}
                        type="radio"
                        label="We undertake and confirm that the above-mentioned details are of
                  our Working capital account, or "
                        value="Yes"
                        name="undertaking_confirm"
                        onChange={(e) => setDeclarationValue(e.target.value)}
                      />
                      <Form.Check
                        disabled={btnDisabled}
                        type="radio"
                        label="We undertake and confirm that we do not avail any working
                      capital facility from any Bank, NBFC, or other financial
                      institutions. The above details are of our operative account for
                      carrying out day to day operations of our firm/ company."
                        value="No"
                        name="undertaking_confirm"
                        onChange={(e) => setDeclarationValue(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  {declarationError && (
                    <p className="m-2" style={{ color: "#d64161" }}>
                      Please accept the Declaration
                    </p>
                  )}
                </>
              )}

              <Row>
                <Col md={20} className="text-end">
                  {showSpinner ? (
                    <Spinner animation="border" variant="primary" />
                  ) : (
                    <Button
                      label={btnText}
                      type="submit"
                      disabled={btnDisabled}
                    ></Button>
                  )}
                </Col>
              </Row>
            </Form>
            <CorrBankAccountDetails
              setCorrBanks={setCorrBanks}
              corrBanks={corrBanks}
            />
          </Row>
          <Row>
            <Col md={20} className="text-end">
              <Button
                label="Save Details"
                type="submit"
                onClick={() => {
                  navigate(`/currency-bank-details/${customerId}`);
                }}
              ></Button>
            </Col>
          </Row>
        </Row>
      </Container>

      <Modal
        show={showPincodeSearchModal}
        onHide={handleClosePincodeSearchModal}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <SectionHeading text="SELECT ADDRESS " />
            {/* {pincodeValue} */}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {addressByPincode.length > 0 ? (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Select</th>
                  <th>Address</th>
                  {/* <th>Branch Type</th> */}
                  <th>District</th>
                  <th>State</th>
                  <th>Pincode</th>
                </tr>
              </thead>
              <tbody>
                {addressByPincode.map((postOffice, index) => (
                  <tr key={index}>
                    <td>
                      <input
                        type="radio"
                        name="selectedAddress"
                        value={postOffice.Name}
                        onChange={() => setSelectedAddress(postOffice)}
                      />
                    </td>
                    <td>
                      {postOffice.Name},{" "}
                      {postOffice.Block ? postOffice.Block : ""},{" "}
                      {postOffice.Name}
                    </td>
                    {/* <td>{postOffice.BranchType}</td> */}
                    <td>{postOffice.District}</td>
                    <td>{postOffice.State}</td>
                    <td>{postOffice.Pincode}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>No addresses found</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handlePincodeSearchModalClose}>
      Close
    </Button> */}
          {/* <Button variant="primary" disabled={!selectedAddress} onClick={() => console.log("Selected Address:", selectedAddress)}>
      Confirm Address
    </Button> */}
          <Button
            label={"Confirm Address"}
            onClick={() => {
              // console.log("Selected Address:", selectedAddress?.Name);
              if (selectedAddress) {
                // setValue("city", selectedAddress.Name);
                // setValue("district", selectedAddress.District);
                // setValue("state", selectedAddress.State);
                // setValue(
                //   "country_id",
                //   selectedAddress.Country == "India" ? 448 : null
                // );
                setValue("bank_address_city", selectedAddress.Name);
                setValue("bank_address_district", selectedAddress.District);
                setValue(
                  "bank_address_country_id",
                  selectedAddress.Country == "India" ? 448 : "null"
                );
                setValue("bank_address_state", selectedAddress.State);

                const flagSetters = [
                  { setter: setCityFetched, value: selectedAddress.Name },
                  {
                    setter: setDistrictFetched,
                    value: selectedAddress.District,
                  },
                  { setter: setStateFetched, value: selectedAddress.State },
                ];

                flagSetters.forEach(({ setter, value }) => {
                  if (value) setter(true);
                });

                setAddLine1Fetched(false);
                setAddLine2Fetched(false);
                setPincodeFetched(false);

                handlePincodeSearchModalClose();
              }
            }}
          ></Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default OpsAddLocalBankPage;
