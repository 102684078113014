import { getSessionId } from "../../../../../../utils/tokenHandlers";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { searchBank } from "../../apis/searchBank";
export const searchBankAction = createAsyncThunk(
  "searchBankAction",
  async ({ ifscCode, bankAccNo }: { ifscCode: string; bankAccNo: string }) => {
    return searchBank(ifscCode, bankAccNo, getSessionId()).then(
      (response: any) => {
        return response.data;
      }
    );
  }
);
