import axios from "axios";

export const sendESign = (
  appId: number,
  signatories: any,
  sessionId: string | null
) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}send/sign/application`,
      {
        params: {
          //   filter: "[]",
          app_id: appId,
          key_person_id: signatories,
        },
      },
      {
        params: {
          o_ses_id: `${sessionId}`,
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => err);
};
