import React from "react";
import { Accordion } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { AppDispatch } from "../../../redux/store";
import { getLocalBank } from "../redux/action/getLocalBank";
import VerLocalBank from "./verLocalBank";

const BankAccountsVerification: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { appId } = useParams();
  const customerId = Number(appId);

  const handleLocalBankClick = async () => {
    try {
      const result = await dispatch(getLocalBank(customerId));
      if (result.payload) {
        // console.log("in main file-->", result.payload);
      } else {
        // console.warn("Something went wrong!");
      }
    } catch (error) {
      // console.error("An error occured", error);
    }
  };

  return (
    <>
      <Accordion>
        <Accordion.Item eventKey="B1">
          <Accordion.Header onClick={handleLocalBankClick}>
            LOCAL BANKs
          </Accordion.Header>
          <Accordion.Body>
            <VerLocalBank />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
};

export default BankAccountsVerification;
