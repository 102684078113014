import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { AppDispatch } from "../../../redux/store";
import { loginGuestUser } from "../redux/slice/guestLoginSlice";
import { loginOTPVerify } from "../redux/slice/guestLoginVerify";
import { doLogin, getSessionId } from "../../../utils/tokenHandlers";
import { Row, Col, Form, Spinner, Modal } from "react-bootstrap";
import { useForm, SubmitHandler } from "react-hook-form";
import { MdCheck, MdEdit } from "react-icons/md";
import { GuestLoginType } from "../redux/types/GuestLoginType";
import { BsArrowRepeat } from "react-icons/bs";
import Input from "../../../components/common/Input";
import SectionHeading from "../../../components/common/SectionHeading";
import "./GuestLogin/GuestLogin.css";
import Button from "../../../components/common/Button";
import {
  EMAIL_REGEX,
  NAME_REGEX,
  PHONE_REGEX,
} from "../../constants/regexPatterns";
import IsdCode from "../../../components/common/dropdowns/IsdCode";
import ISDCodes from "../../../components/common/dropdowns/ISDCodes";
import PhoneInput from "../../../components/common/PhoneInput";
import PhoneInputWithDropdown from "../../../components/common/PhoneInput";
import { GuestLoginAPI } from "../apis/guestLoginAPI";
import { opsLoginAction } from "../redux/action/opsLoginAction";
import { LogOut } from "../apis/logout";
import { DisplayBox } from "../../../components/containers/DisplayBox";
import AlertPopup from "../../../components/common/Alert/AlertPopup";
import GuestLoginCellPhone from "../../../components/common/guestLoginCellPhone";
import { logoutConCurrLoginAction } from "../redux/action/logoutConCurrLoginAction";
import { generateTokenAction } from "../redux/action/generateTokenAction";

const GuestUserLogin: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const formData = location.state;
  const dispatch = useDispatch<AppDispatch>();
  const [showSpinner, setShowSpinner] = useState(false);
  const [showChkSpinner, setShowChkSpinner] = useState(false);
  const [checkIconDisabled, setCheckIconDisabled] = useState(false);
  const [captchaInput, setCaptchaInput] = useState("");
  const [captchaValid, setCaptchaValid] = useState(true);
  const [showValidEmailAlert, setShowValidEmailAlert] = useState(false);
  const [showTNCAlert, setShowTNCAlert] = useState(false);
  const [otpAlert, setOTPAlert] = useState(false);
  // T&C Modal
  const [showModal, setShowModal] = useState(false);
  const handleModalOpen = () => setShowModal(true);
  const handleModalClose = () => setShowModal(false);
  const [emailValue, setEmailValue] = useState("");
  const [nameValue, setNameValue] = useState("");
  const [otpValue, setOTPValue] = useState("");

  // T&C checkbox
  const [isChecked, setIsChecked] = useState(false);

  const [clickCount, setClickCount] = useState(0);
  const [showCooldown, setShowCooldown] = useState(false);
  const [cooldownTimer, setCooldownTimer] = useState(0);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const [showLoginModal, setShowLoginModal] = useState(false);
  const handleLoginModalOpen = () => setShowLoginModal(true);
  const handleLoginModalClose = () => setShowLoginModal(false);
  const [displayLogoutAlert, setDisplayLogoutAlert] = useState(false);
  const [conCurrLoginUid, setConCurrLoginUid] = useState<number>(0);
  // const [token, setToken] = useState("");

  // const [captchaInput, setCaptchaInput] = useState("");

  const handleCheckboxChange = (event: {
    target: { checked: boolean | ((prevState: boolean) => boolean) };
  }) => {
    setIsChecked(event.target.checked);
    if (event.target.checked) {
      //  // console.log("Checkbox is checked");
      const checked = true;
    } else {
      //  // console.log("Checkbox is unchecked");
    }
  };

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    watch,
    setValue,
  } = useForm<GuestLoginType>();

  const watchedFields = {
    guest_email: emailValue,
    guest_user_mobile: watch("guest_user_mobile"),
    guest_user_name: nameValue,
    guest_user_otp: otpValue,
    captchaInput: watch("captchaInput"),
    customer_contact_mobile_country_code: watch(
      "customer_contact_mobile_country_code"
    ),
  };

  const username = watch("guest_email");
  const password = watch("guest_user_otp");

  useEffect(() => {
    if (formData) {
      setValue("guest_user_name", nameValue);
      setValue("guest_user_mobile", formData.guest_user_mobile);
      setValue(
        "customer_contact_mobile_country_code",
        formData.customer_contact_mobile_country_code
      );

      setValue("guest_email", formData.guest_email);
      setTimeout(() => {
        setValue(
          "customer_contact_mobile_country_code",
          formData.customer_contact_mobile_country_code
        );
      }, 500);
    }
  }, []);

  const onSubmitPersonalDetails: SubmitHandler<GuestLoginType> = async (
    data
  ) => {
    setOTPAlert(false);
    if (!isChecked) {
      setShowTNCAlert(true);
      return;
    }
    if (
      watchedFields.customer_contact_mobile_country_code === "+91" &&
      watchedFields.guest_user_mobile.length < 10
    ) {
      alert("Please enter 10 digit number");
    } else {
      // // console.log("data--->", data);

      //  // console.warn(localStorage.getItem("guestISDCode"));
      localStorage.setItem("guestEmail", watchedFields.guest_email);
      localStorage.setItem("guestMobile", watchedFields.guest_user_mobile);
      localStorage.setItem("guestName", watchedFields.guest_user_name);
      localStorage.setItem(
        "guestISDCode",
        watchedFields.customer_contact_mobile_country_code
      );

      setShowSpinner(true);
      //// console.log("Personal Details:", data);
      if (!otpValue) {
        // alert("Click on check-mark to get the OTP");
        setOTPAlert(true);
        setShowSpinner(false);
        return; // Return early if the email is not entered
      } else {
        try {
          if (captchaInput !== captcha) {
            setShowSpinner(false);
            // console.log("invalid captcha", captcha);
            setCaptchaValid(false);
            setError("captchaInput", {
              type: "manual",
              message: "Invalid Captcha, Try Again",
            });
            handleRefreshCaptcha();
            setValue("captchaInput", "");
            return;
            // console.log('invalid captcha', captcha);
          } else {
            setShowSpinner(true);
            dispatch(
              opsLoginAction({ username: username, password: password })
            ).then((data) => {
              // console.log("LOGIN RESPONSE", data);
              if (data.payload?.result?.data?.message === "already_logged_in") {
                // console.log(data.payload?.result?.uid);
                setConCurrLoginUid(data.payload?.result?.uid);
                handleLoginModalOpen();
                setShowSpinner(false);
              } else {
                if (data.payload?.result?.code == 401) {
                  setError("guest_user_otp", {
                    type: "manual",
                    message: "Invalid OTP",
                  });
                  setShowSpinner(false);
                  setValue("captchaInput", "");
                  handleRefreshCaptcha();
                  return;
                } else if (data.payload?.result?.code == 423) {
                  setError("captchaInput", {
                    type: "manual",
                    message:
                      "Your account has been disabled please contact your administration!",
                  });
                  setShowSpinner(false);
                  setValue("captchaInput", "");
                  handleRefreshCaptcha();
                  return;
                } else if (data.payload?.result?.code == 429) {
                  setError("captchaInput", {
                    type: "manual",
                    message:
                      "Max login attempts reached. Please wait for 5 minutes to login again.",
                  });
                  setShowSpinner(false);
                  setValue("captchaInput", "");
                  handleRefreshCaptcha();
                } else if (
                  data.payload?.result?.message === "Odoo Server Error"
                ) {
                  setError("captchaInput", {
                    type: "manual",
                    message: `${data.payload?.result?.data?.message} Please try again.`,
                  });
                  setShowSpinner(false);
                  setValue("captchaInput", "");
                  handleRefreshCaptcha();
                }
                doLogin(data.payload, () => {
                  //  // console.log("login Success");
                  navigate("/guest/create-application", {
                    state: {
                      guest_user_name: watchedFields.guest_user_name,
                      guest_user_mobile: watchedFields.guest_user_mobile,
                      customer_contact_mobile_country_code:
                        watchedFields.customer_contact_mobile_country_code,
                      guest_email: watchedFields.guest_email,
                    },
                  });
                  setShowSpinner(false);
                });
              }
            });
          }
          // const result = await dispatch(loginOTPVerify(watchedFields));
          // if (result.payload) {
          //   // Store session_id and username separately in local storage
          //   // localStorage.setItem("session_id", session_id);
          //   // localStorage.setItem("username", username);
          //   // console.log(result.payload, "LOGIN RESP");
          //   if (result.payload?.result?.data?.message === "already_logged_in") {
          //     setConCurrLoginUid(result.payload?.result?.uid);
          //     handleLoginModalOpen();
          //     setShowSpinner(false);
          //   } else {
          //     doLogin(result.payload, () => {
          //       // console.log(result.payload);
          //       if (result.payload?.result?.session_id) {
          //         navigate("/guest/create-application", {
          //           state: {
          //             guest_user_name: watchedFields.guest_user_name,
          //             guest_user_mobile: watchedFields.guest_user_mobile,
          //             customer_contact_mobile_country_code:
          //               watchedFields.customer_contact_mobile_country_code,
          //             guest_email: watchedFields.guest_email,
          //           },
          //         });
          //         setShowSpinner(false);
          //         // console.warn("id==>", getSessionId());
          //       } else {
          //         alert("Please enter correct OTP");
          //         setShowSpinner(false);
          //         handleRefreshCaptcha();
          //         setValue("captchaInput", "");
          //       }
          //     });

          //     // Call the doLogin function here to set up authentication

          //     // Navigate to the desired location

          //     // Log the stored data
          //     // console.log(localStorage.getItem("session_id"));
          //     // console.log(localStorage.getItem("username"));
          //   }
          // } else {
          //   // console.warn("in else block");
          //   setShowSpinner(false);
          // }
        } catch (error) {
          // console.error("An error occurred", error);
          setShowSpinner(false);
        }
      }
    }
  };

  const logoutConCurrLogin = async (userId: number) => {
    // console.log(userId);
    setDisplayLogoutAlert(false);
    dispatch(logoutConCurrLoginAction(userId)).then((resp) => {
      // console.log(resp);
      if (resp.payload?.data?.result?.message === "logout") {
        setValue("captchaInput", "");
        handleRefreshCaptcha();
        handleLoginModalClose();
        setDisplayLogoutAlert(true);
      }
    });
  };

  const [checkIconClicked, setCheckIconClicked] = useState(false);
  const [emailFieldDisabled, setEmailFieldDisabled] = useState(false);
  const [showOTPField, setShowOTPField] = useState(false);
  const [timer, setTimer] = useState(59);
  const [showOTP, setShowOTP] = useState(false);
  const [OTPfromResult, setOTPfromResult] = useState("");

  const handleEditIconClick = async () => {
    if (emailFieldDisabled) {
      setTimer(59);
      setShowOTPField(false);
      setCheckIconClicked(false);
      setCheckIconDisabled(false);
      setEmailFieldDisabled(false);
    }
  };
  const handleCheckIconClick = async () => {
    const isValidEmail = EMAIL_REGEX.test(emailValue);
    setShowValidEmailAlert(false);
    if (isValidEmail) {
      if (!emailValue) {
        // alert("Please enter your Email & Accept the T&Cs");
        setShowTNCAlert(true);
        return; // Return early if the email is not entered
      } else {
        setShowTNCAlert(false);
        setShowChkSpinner(true);
        setClickCount((prevCount) => prevCount + 1);
        // console.log(clickCount);
        if (clickCount < 4) {
          try {
            dispatch(generateTokenAction()).then(async (resp) => {
              // console.log(resp, "token");
              // setToken(resp.payload?.result?.token);
              // return;
              const watchedFieldsWithToken = {
                ...watchedFields,
                token: resp.payload?.result?.token,
              };

              if (watchedFieldsWithToken) {
                // console.log(watchedFieldsWithToken, "Watched");
                const result = await dispatch(
                  loginGuestUser(watchedFieldsWithToken)
                );
                // console.log(result, "result");

                if (result.payload) {
                  // // console.warn("Payload response->", result.payload);
                  // get otp and msg
                  const { otp, message } = result.payload.result;

                  // Store OTP and message in local storage
                  localStorage.setItem("otp", otp);
                  localStorage.setItem("message", message);
                  setOTPfromResult(result.payload?.result?.OTP);

                  if (result.payload.result.error) {
                    alert(
                      "Something went wrong! Please " +
                        result.payload.result.message
                    );
                    setCheckIconClicked(false);
                    setEmailFieldDisabled(false);
                    setShowOTPField(false);
                    setCheckIconDisabled(false);
                    setShowChkSpinner(false);

                    return;
                  }

                  setCheckIconClicked(true);
                  setEmailFieldDisabled(true);
                  setShowOTPField(true);
                  setTimer(59);
                  setCheckIconDisabled(true);
                  setShowChkSpinner(false);
                  setShowOTP(true);

                  setTimeout(() => {
                    setCheckIconDisabled(false);
                    setEmailFieldDisabled(false);
                    setCheckIconClicked(false);
                    setShowOTPField(false);
                    setShowOTP(false);
                  }, 60000);
                } else {
                  // // console.warn("in else block");
                }
              }
            });
          } catch (error) {
            // // console.error("An error occured hello", error);
          }
        }
      }
    } else {
      // alert("Please enter valid Email");
      setShowValidEmailAlert(true);
    }
  };

  useEffect(() => {
    if (clickCount > 4) {
      // setError("guest_user_otp", {
      //   type: "manual",
      //   message: `Maximum attempts reached. Try again after ${formatTime(
      //     cooldownTimer
      //   )}`,
      // });
      setShowChkSpinner(false);
      setCooldownTimer(180); // Reset the cooldown timer for the next attempt
    }
  }, [clickCount]);

  useEffect(() => {
    let interval: any;

    if (cooldownTimer > 0) {
      interval = setInterval(() => {
        setCooldownTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    if (cooldownTimer === 0) {
      setClickCount(0); // Reset the click count for the next attempt
    }

    return () => {
      clearInterval(interval);
    };
  }, [cooldownTimer]);

  const formatTime = (timeInSeconds: any) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(seconds).padStart(2, "0");
    return `${formattedMinutes}:${formattedSeconds}`;
  };
  useEffect(() => {
    setTimeout(() => {
      setShowValidEmailAlert(false);
      setShowTNCAlert(false);
    }, 2000);
  }, [showValidEmailAlert, showTNCAlert]);

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;

    if (timer > 0 && showOTPField) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [timer, showOTPField]);

  useEffect(() => {
    // Remove values from local storage when the component mounts
    localStorage.removeItem("guestName");
    localStorage.removeItem("guestEmail");
    localStorage.removeItem("guestMobile");
  }, []);

  const [personalDetailsTimer, setPersonalDetailsTimer] = useState(59);

  useEffect(() => {
    let personalDetailsInterval: NodeJS.Timeout | undefined;

    if (personalDetailsTimer > 0 && showOTPField) {
      personalDetailsInterval = setInterval(() => {
        setPersonalDetailsTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => {
      if (personalDetailsInterval) clearInterval(personalDetailsInterval);
    };
  }, [personalDetailsTimer, showOTPField]);

  const [captcha, setCaptcha] = useState(generateCaptcha());

  function generateCaptcha() {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let result = "";
    const charactersLength = characters.length;
    for (let i = 0; i < 6; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const handleRefreshCaptcha = () => {
    setCaptcha(generateCaptcha());
    setCaptchaInput(""); // Clear the input when refreshing captcha
    setCaptchaValid(true); // Reset captcha validity
  };
  const [gotSessionId, setGotSessionId] = useState(false);

  // useEffect(() => {
  //   dispatch(
  //     opsLoginAction({ username: "odooapi@vayana.com", password: "Vayana@123" })
  //   ).then((data) => {
  //     // // console.log("LOGIN RESPONSE", data);
  //     doLogin(data.payload, () => {
  //       // // console.log("login Success");
  //       // navigate("/ops/dashboard");
  //       setGotSessionId(true);
  //     });
  //   });
  // }, [dispatch]);

  return (
    <>
      {/* <Navbar /> */}

      <Form>
        <DisplayBox display={displayLogoutAlert}>
          <AlertPopup
            message="User loggedout from other session"
            variant="success"
          ></AlertPopup>
        </DisplayBox>
        <DisplayBox display={showValidEmailAlert}>
          <AlertPopup message="Enter Valid Email" variant="danger" />
        </DisplayBox>
        <DisplayBox display={showTNCAlert}>
          <AlertPopup message="Please accept the T&C" variant="warning" />
        </DisplayBox>
        <DisplayBox display={otpAlert}>
          <AlertPopup
            message="Click on check-mark to get the OTP"
            variant="warning"
          />
        </DisplayBox>

        <Row>
          <Col md={10}>
            <SectionHeading text="YOUR PERSONAL DETAILS" />
          </Col>
        </Row>
        <Row>
          <Col md={10}>
            <Input
              label="Name"
              name="guest_user_name"
              register={register}
              required
              requiredMessage="Name is required"
              error={errors.guest_user_name?.message}
              placeholder="Enter your name"
              // defaultValue={`${localStorage.getItem("guestName") || ""}`}
              regexPattern={NAME_REGEX}
              onChange={(e) => setNameValue(e.target.value)}
            />
          </Col>
        </Row>
        {/* <Row>
          <Col md={7}>
            {
              // <Input
              //   label="Cell Phone"
              //   type="text"
              //   name="guest_user_mobile"
              //   register={register}
              //   required
              //   requiredMessage="Cellphone is required"
              //   error={errors.guest_user_mobile?.message}
              //   placeholder="Enter your Cellphone"
              //   minLength={10}
              //   regexPattern={PHONE_REGEX}
              //   defaultValue={`${localStorage.getItem("guestMobile") || ""}`}
              // />
            }
            <ISDCodes name={"cell"} register={register} label="Phone" />
            {/*  <IsdCode
                label="Cellphone"
                type="text"
                name="customer_contact_mobile"
                register={register}
                required
                regexPattern={PHONE_REGEX}
                requiredMessage="Mobile is required"
                minLength={10}
                error={errors.guest_user_mobile?.message}
                defaultValue={`${localStorage.getItem("guestMobile") || ""}`}
                placeholder="Enter your Cellphone"
              /> */}
        {/* </Col>
          <Col md={5}>
            <PhoneInput label={""} name={"f"} register={register} />
          </Col>
        </Row> */}
        <Row>
          <Col md={10}>
            <GuestLoginCellPhone
              label="CellPhone"
              name="guest_user_mobile"
              regexPattern={PHONE_REGEX}
              register={register}
              maxLength={10}
              isdCodeName={"customer_contact_mobile_country_code"}
              isdCodeRegister={register}
              placeholder="Enter your Cellphone"
              error={errors.guest_user_mobile?.message}
              required
              // inputDefaultValue={`${
              //   localStorage.getItem("guestMobile") || ""
              // }`}
            />
          </Col>
        </Row>

        <Row>
          <Col md={10}>
            <Input
              label="Email"
              type="email"
              name="guest_email"
              register={register}
              required
              requiredMessage="Email is required"
              regexPattern={EMAIL_REGEX}
              regexMessage="Invalid email format"
              // onChange={handleEmailValidation}
              disabled={emailFieldDisabled || clickCount > 4}
              error={errors.guest_email?.message}
              placeholder="Enter your email"
              // defaultValue={`${localStorage.getItem("guestEmail") || ""}`}
              onChange={(e) => setEmailValue(e.target.value)}
            />
          </Col>

          <Col
            md={2}
            style={{
              display: "flex",
              alignItems: "start",
              marginTop: "10px",
              marginLeft: "-40px",
            }}
          >
            {showChkSpinner ? (
              <Spinner animation="border" variant="primary" size="sm" />
            ) : (
              <MdCheck
                style={{
                  fontSize: "22px",
                  cursor: "pointer",
                  border: checkIconClicked ? "2px solid green" : "#214382",
                  borderRadius: "50%",
                  backgroundColor: checkIconClicked ? "green" : "#214382",
                  color: checkIconClicked ? "black" : "white",
                  pointerEvents:
                    checkIconDisabled || clickCount > 4 ? "none" : "auto", // Disable pointer events if the icon is disabled
                }}
                onClick={handleCheckIconClick}
                title="Get the OTP on Email"
              />
            )}

            {/* {emailFieldDisabled && (
             
            )} */}
            <MdEdit
              onClick={handleEditIconClick}
              style={{
                fontSize: "20px",
                marginLeft: "15px",
                cursor: "pointer",
              }}
              title="Edit Email"
            />

            {/* <MdCheck
              style={{
                fontSize: "22px",
                cursor: "pointer",
                border: checkIconClicked ? "2px solid green" : "none",
                borderRadius: "50%",
                pointerEvents: checkIconDisabled ? "none" : "auto", // Disable pointer events if the icon is disabled
              }}
              onClick={handleCheckIconClick}
            /> */}
          </Col>
        </Row>
        {/* {showOTP && (
          <Row>
            <Col md={10}>
              <div
                className="captcha-text"
                style={{ pointerEvents: "none", userSelect: "none" }}
              >
                {OTPfromResult}
              </div>
            </Col>
          </Row>
        )} */}
        {
          <Row>
            <Col md={10}>
              {/* Add offset to align with other fields */}
              <Input
                label="OTP"
                name="guest_user_otp"
                register={register}
                required
                requiredMessage="OTP is required"
                error={errors.guest_user_otp?.message}
                placeholder="Enter OTP received on Email"
                onChange={(e) => setOTPValue(e.target.value)}
                disabled={clickCount > 4}
              />
            </Col>
          </Row>
        }
        {clickCount > 4 && (
          <>
            <Row>
              <Col
                className="text-center"
                style={{
                  color: "red",
                }}
              >
                {`Maximum OTP attempts reached. Try again after ${formatTime(
                  cooldownTimer
                )}`}
              </Col>
            </Row>
          </>
        )}

        {showOTPField && timer > 0 && (
          <Row>
            <Col md={10} className="text-end">
              <p
                style={{
                  fontSize: "14px",
                  margin: "0", // Reset margin
                  padding: "0", // Reset padding
                }}
              >
                Resend OTP in {timer < 10 ? `00:0${timer}` : `00:${timer}`}{" "}
                mins.
              </p>
            </Col>
          </Row>
        )}
        <Row>
          <Col md={10}>
            <div
              className="captcha-text"
              style={{ pointerEvents: "none", userSelect: "none" }}
            >
              {captcha}
            </div>
          </Col>
          <Col md={2} className="refresh-icon" style={{ marginLeft: "44%" }}>
            <span onClick={handleRefreshCaptcha}>
              <BsArrowRepeat />
            </span>
          </Col>
        </Row>
        <Row>
          <Col md={10}>
            <Input
              label="Captcha"
              name="captchaInput"
              // onChange={(e) => handleCaptchaChange(e)}
              register={register}
              placeholder="Enter the characters shown above"
              error={errors.captchaInput?.message}
              required
              onChange={(e) => {
                setCaptchaInput(e.target.value);
                // handleCaptchaChange(); // Call your function on captcha change
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12} className="d-flex align-items-center mt-2">
            <input
              type="checkbox"
              checked={isChecked}
              onChange={handleCheckboxChange}
              disabled
              style={{
                width: "20px",
                height: "20px",
                marginRight: "8px",
                accentColor: "#007bff", // Customizes the checkbox color
                cursor: "not-allowed", // Indicates it's disabled
              }}
            />
            <span>
              I've read and agreed to all the{" "}
              <a href="#" onClick={handleModalOpen} style={{ color: "blue" }}>
                Terms and Conditions
              </a>
            </span>
          </Col>
        </Row>
        <Row className="text-end m-3" md={12}>
          <Col md={6} className="text-start">
            {/* <Button
              type="button"
              // className="rounded-pill"
              variant="outline-primary"
              label={"Login as Ops User?"}
              onClick={() => {
                navigate("/");
              }}
            /> */}
          </Col>
          <Col md={6} className="text-center">
            {showSpinner ? (
              <Spinner animation="border" variant="primary" />
            ) : (
              <Button
                label="Submit"
                type="submit"
                //disabled={!captchaValid}

                onClick={handleSubmit(onSubmitPersonalDetails)}
              />
            )}
          </Col>
        </Row>
      </Form>
      <Modal
        show={showModal}
        onHide={handleModalClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>Terms and Conditions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* 1. I/We understand and thereby authorise VTX to use information
          provided in the application form, to fetch entity and individuals’
          details from third-party service providers. VTX intend to use the
          information obtained from any third-party service providers solely for
          aiding in onboarding process on VTX’s platform for the purpose of
          availing VTX’s ITFS services. <br />
          2. I/We agree and accept to VTX’s Privacy Policy and all other Terms
          and Conditions as applicable. <br />
          3. I/We have no objection to VTX/its representatives making necessary
          enquiries/verifications through credit information agencies or
          carrying out visits to any of the unit/location/branch/office of the
          applicant entity, while considering my/our application for
          registration on VTX. I/We undertake to furnish all other information
          that may be required by VTX in connection with my/our application for
          registration. <br />
          4. I/We hereby authorise VTX, its authorised agents and
          representatives to disclose, share, remit in any form, mode, or manner
          all /any of the information provided by me/us.
          <br />
          5. I/We hereby undertake that I have necessary authority to provide
          details of the entity for which this application is being submitted
          for onboarding on VTX */}
          {/* As per doc shared on  13 DEC 2024 over email*/}
          <ul>
            <li>
              I/We confirm that I have the authority and capacity under law to
              agree to these Terms and that I/we understand these Terms. <br />
            </li>
            <li>
              I/We understand and authorize Vayana (IFSC) Private Limited
              (“VTX”) to use the information provided in this application form,
              including entity and individual details, to obtain additional data
              from authorized third-party service providers. VTX shall use this
              information solely to facilitate the onboarding process. <br />
            </li>
            <li>
              I/We acknowledge and agree to abide by VTX’s Privacy Policy, Terms
              of Use, and other applicable terms and conditions, as updated from
              time to time.
            </li>
            <li>
              I/We consent to VTX or its authorized representatives conducting
              necessary enquiries or verifications through credit information
              agencies or other means. I/We also authorize visits to the
              unit/location/branch/office of the applicant entity as part of the
              registration process and undertake to provide any additional
              information required.
            </li>
            <li>
              I/We authorize VTX, its agents, and representatives to disclose,
              share, or transmit in any manner the information provided by me/us
              to comply with applicable legal, regulatory, or operational
              requirements.{" "}
            </li>
            <li>
              I/We affirm that I/we have been duly authorized by the management
              of the applicant entity to submit this application and provide the
              required details on behalf of the entity and its authorized
              individuals.{" "}
            </li>{" "}
            <li>
              I/We agree that the onboarding process is subject to the
              guidelines of the International Financial Services Centres
              Authority (IFSCA), including but not limited to KYC, AML, and CFT
              norms. <br />
            </li>
            <li>
              I/We hereby provide explicit consent for the collection, use,
              processing, storage, and sharing of personal and sensitive data,
              as provided in this application form, for the purposes of
              onboarding, verification, regulatory compliance, and facilitating
              the services offered by VTX. I/We understand that the data will be
              processed lawfully, fairly, and transparently, and that I/we have
              the right to withdraw this consent at any time, subject to
              applicable laws and the consequences thereof.{" "}
            </li>
            <li>
              I/We affirm that all information provided in this application is
              accurate, complete, and up- to-date. I/We acknowledge that any
              misrepresentation or omission may result in the rejection of the
              application or termination of services.{" "}
            </li>
            <li>
              {" "}
              I/We acknowledge that the login credentials (ID and password)
              provided upon successful onboarding are for the exclusive use of
              the authorized individuals of the applicant entity. The entity is
              responsible for safeguarding these credentials and ensuring their
              proper usage.{" "}
            </li>
            <li>
              {" "}
              I/We understand that VTX shall not be held liable for any loss or
              damage arising from unauthorized access to or misuse of the portal
              or credentials, provided such loss or damage is not attributable
              to negligence or misconduct on VTX’s part.
            </li>{" "}
            I/We agree that the information provided will be retained by VTX as
            required by applicable laws and regulations and used only for lawful
            purposes, including audits and regulatory reporting. <br />
            <li>
              {" "}
              VTX reserves the right to refuse or terminate the onboarding
              process or access to its platform in case of non-compliance with
              applicable policies, incomplete or inaccurate information, or any
              other valid reason.{" "}
            </li>{" "}
            I/We consent to receiving communications, notices, and updates from
            VTX through electronic means, including emails, SMS, or portal
            notifications, for purposes related to onboarding and service usage.{" "}
            <li>
              {" "}
              I/We agree that VTX has the right to refuse services to any person
              or entity.{" "}
            </li>{" "}
            The access to VTX’ platform may be suspended or withdrawn if any
            amounts are outstanding to VTX or for any other reasons set out in
            the agreement between us and VTX.{" "}
            <li>
              {" "}
              I/We agree to provide all documents and information requested by
              VTX.{" "}
            </li>{" "}
            <li>
              I/We agree that VTX will retain the information and documents
              provided by us for such time as prescribed under applicable laws
              or as necessary to provide services to us.{" "}
            </li>
            <li>
              I/We will not, host, display, upload, modify, publish, transmit,
              update or share any information that: (a) to which the I/we do not
              have any right to; (b) is harmful, harassing, blasphemous,
              defamatory, obscene, hateful, pornographic, paedophilia,
              libellous, invasive of another's privacy, racially or ethnically
              objectionable, unlawful in any manner, etc; (c) harms minors in
              any way; (d) infringes any patent, trademark, copyright or other
              proprietary rights; (e) violates any law; (f) impersonates another
              person; (h) contains software viruses or any other computer code,
              files or programs designed to interrupt, destroy or limit the
              functionality of any computer resource.
            </li>
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            label="I Accept"
            onClick={() => {
              setIsChecked(true);
              handleModalClose();
            }}
          ></Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showLoginModal}
        onHide={handleLoginModalClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Account Already Logged In</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          It looks like this account is already logged in on another tab or
          browser.
          <br />
          Would you like to log out from the other session?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-primary"
            label="Close"
            onClick={handleModalClose}
          ></Button>
          <Button
            variant="primary"
            label="Yes, Logout"
            onClick={() => {
              logoutConCurrLogin(conCurrLoginUid);
            }}
          ></Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default GuestUserLogin;
